import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Formik, Form, FormikHelpers, Field } from 'formik';
import API from '/src/middleware/API';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import Logger from '/src/services/logger';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import WarnBeforeExit from '/src/components/utility/WarnBeforeExit';
import { useParams } from 'react-router-dom';
import { UnverifiedInvestor } from '/../libs/shared-types/src/types/model/UnverifiedInvestor';
import { AdminGetUnverifiedInvestorById } from '/../libs/shared-types/src/constants/ApiRoutes';
import FormCard, { FormSection } from '/src/components/FormCard';
import emptyInvestor from '/src/components/InvestorForm/EmptyInvestor';
import INVESTOR_FIELD_MAPPING from '/src/components/InvestorForm/InvestorFormFieldMapping';
import Toast from '/src/components/notifications/Toast';
import { cleanInvestorData } from '/src/components/InvestorForm/InvestorFormValuesCleaner';
import { createOrUpdateUnverifiedInvestor } from '../../../services/Admin/UnverifiedInvestor';
import AUTH_FIELD_MAPPING from '/src/components/Authentication/AuthFormFieldMap';
import AuthFieldNames from '/src/enums/AuthFieldNames';
import { ToastConfiguration } from '/src/interfaces/ToastConfiguration';
import { Breadcrumbs } from '../../AdminRoutes';
import { Disclosure } from '@headlessui/react';
import { checkRangeMaxFieldOptional } from '/src/components/InvestorForm/Fields/CheckRangeMaxField';
import { checkRangeMinFieldOptional } from '/src/components/InvestorForm/Fields/CheckRangeMinField';
import { leadsRoundsFieldOptional } from '/src/components/InvestorForm/Fields/LeadsRoundsField';
import { formatDatePickerInput } from '/src/util/formatting/dates';

const sections: FormSection[] = [
  {
    title: 'About',
    fields: [
      AUTH_FIELD_MAPPING[AuthFieldNames.FirstName],
      AUTH_FIELD_MAPPING[AuthFieldNames.LastName],
      AUTH_FIELD_MAPPING[AuthFieldNames.Email],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.ProfilePic],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Type],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Firm],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Role],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.FirmJoinedOn],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InvAuthority],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Location],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.ExpInvestingYears],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.ProfessionalAccreditations],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.UniversityAffiliations],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.HighestEducationLevel],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Nationalities],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.IntroBio],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Pronouns],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.ExpFunctional],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.ExpIndustry],
    ],
  },
  {
    title: 'Investment Thesis',
    fields: [
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InvStages],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InvStagesFollowOn],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InvProductStage],
      checkRangeMaxFieldOptional,
      checkRangeMinFieldOptional,
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InvProductCategories],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InvIndustries],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.AntiInvIndustries],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InvBusinessModels],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InvGeographies],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InvImpacts],
      leadsRoundsFieldOptional,
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.TakesBoardSeats],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.HasInitialOwnershipTarget],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InitialOwnershipTargetMin],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InitialOwnershipTargetMax],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.YearlyAvgInvCount],
    ],
  },
  {
    title: 'Contact Preferences & Social',
    fields: [
      INVESTOR_FIELD_MAPPING[
        InvestorFieldNames.IsIncludedInInvestorDbForStartups
      ],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.IsOpenToColdInbound],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.PreferenceContact],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.PreferenceFollowUp],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Website],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.LinkedIn],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Twitter],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.AngelList],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Medium],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Substack],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.OtherUrl],
    ],
  },
];

export const UNVERIFIED_INVESTOR_VALIDATION = sections
  .map((section) => section.fields)
  .flat()
  .map((field) => field.validation)
  .reduce((merged, schema) => merged.concat(schema));

function UnverifiedInvestorForm(): JSX.Element {
  const { unverifiedInvestorId } = useParams();
  const [toastConfig, setToastConfig] = useState<ToastConfiguration>();
  const [isLoading, setIsLoading] = useState(false);
  const [submitCount, setSubmitCount] = useState(0);
  const [initialValues, setInitialValues] = useState<any>({});
  const [validation, setValidation] =
    useState<yup.ObjectSchema<any, yup.AnyObject, any, any>>();

  function getInitialTouchedFields() {
    const initialTouched: any = {};
    Object.keys(initialValues).forEach((k) => {
      initialTouched[k] = true;
    });
    return initialTouched;
  }

  async function fetchInvestor() {
    if (unverifiedInvestorId === undefined) {
      const investor = {
        firstName: '',
        lastName: '',
        ...emptyInvestor,
        profilePicMustValidate: false,
      };
      setInitialValues(investor);
      return;
    }

    setIsLoading(true);
    try {
      const data = await API.get<UnverifiedInvestor>(
        AdminGetUnverifiedInvestorById.buildEndpoint(unverifiedInvestorId),
      );
      const investor = {
        ...emptyInvestor,
        ...data,
        [InvestorFieldNames.FirmJoinedOn]: data.firmJoinedOn
          ? formatDatePickerInput(data.firmJoinedOn)
          : '',
        profilePicMustValidate: false,
      };
      setInitialValues(investor);
    } catch (error: any) {
      Logger.error(error);
      setToastConfig({
        title: 'Could not fetch unverified investor!',
        message: error.message,
        isError: true,
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function onSubmit(
    values: any,
    { setSubmitting }: FormikHelpers<any>,
  ): Promise<void> {
    setSubmitting(true);
    // YOU MUST CHECK IF THE DATA BEING SUBMITTED IS VALID
    // For example, certain fields are mutually exclusive
    const cleanValues = cleanInvestorData(values);

    try {
      const result = await createOrUpdateUnverifiedInvestor(
        cleanValues,
        unverifiedInvestorId,
      );

      setToastConfig({
        title: 'Success!',
        message: result,
        isError: false,
      });
      setTimeout(() => setToastConfig(undefined), 3000);
    } catch (error: any) {
      Logger.error(error);
      setToastConfig({
        title: 'Could not create or update unverified investor!',
        message: error.message,
        isError: true,
      });
    } finally {
      // After submitting, refresh the form
      setSubmitCount(submitCount + 1);
      setSubmitting(false);
    }
  }

  useEffect(() => {
    fetchInvestor();
    setValidation(UNVERIFIED_INVESTOR_VALIDATION);
  }, [submitCount]);

  if (isLoading && submitCount === 0) {
    return <PageLoadingSpinner />;
  }

  return (
    <Formik
      initialValues={initialValues}
      initialTouched={getInitialTouchedFields()}
      enableReinitialize
      validateOnMount
      validationSchema={validation}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ values, errors, resetForm, isSubmitting, dirty }) => (
        <Form className="flex flex-col bg-gray-100 p-4 sm:max-w-3xl">
          <Breadcrumbs />
          <WarnBeforeExit isEnabled={dirty} />
          <header className="sticky top-0 z-20 bg-gray-100 pb-2 dark:bg-transparent dark:backdrop-blur">
            <div className="relative flex max-w-full items-center justify-between">
              <h3 className="page-title">
                {unverifiedInvestorId ? 'Update' : 'Create'} Unverified Investor
              </h3>
              <div className="space-x-3">
                <button
                  type="button"
                  onClick={() => resetForm()}
                  className="app-button--neutral"
                >
                  Reset
                </button>
                <button
                  type="submit"
                  className="app-button--primary"
                  disabled={isSubmitting}
                >
                  Save
                  {isSubmitting && (
                    <div className="ml-2">
                      <LoadingSpinner color="white" />
                    </div>
                  )}
                </button>
              </div>
            </div>
            {unverifiedInvestorId && <pre>_Id: {unverifiedInvestorId}</pre>}
          </header>

          <section>
            {sections.map((section) => (
              <FormCard
                key={section.title}
                title={section.title}
                fields={section.fields}
              />
            ))}
          </section>

          <aside className="absolute right-10 top-10 hidden flex-col space-y-2 text-xs lg:flex">
            {/* Debug Formik Values */}
            <Disclosure>
              <Disclosure.Button className="app-button--neutral">
                <pre>Formik Values:</pre>
              </Disclosure.Button>
              <Disclosure.Panel>
                <pre className="mb-6">{JSON.stringify(values, null, 2)}</pre>
              </Disclosure.Panel>
            </Disclosure>

            <Disclosure>
              <Disclosure.Button className="app-button--neutral">
                <pre>Formik File Upload:</pre>
              </Disclosure.Button>
              <Disclosure.Panel>
                <pre className="mb-6">
                  {values.profilePic
                    ? JSON.stringify(
                        {
                          fileName: values.profilePic.name,
                          type: values.profilePic.type,
                          size: `${values.profilePic.size} bytes`,
                        },
                        null,
                        2,
                      )
                    : null}
                </pre>
              </Disclosure.Panel>
            </Disclosure>

            <Disclosure>
              <Disclosure.Button className="app-button--neutral">
                <pre>Formik Errors:</pre>
              </Disclosure.Button>
              <Disclosure.Panel>
                <pre className="mb-6">{JSON.stringify(errors, null, 2)}</pre>
              </Disclosure.Panel>
            </Disclosure>
          </aside>

          <div className="z-20">
            <Toast
              isShown={toastConfig !== undefined}
              onClose={() => setToastConfig(undefined)}
              title={toastConfig?.title ?? ''}
              isError={toastConfig?.isError}
              text={toastConfig?.message ?? ''}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default UnverifiedInvestorForm;
