import { UnverifiedInvestor } from '../types/model/UnverifiedInvestor';
import { InvestorFragmentView } from '../types/view/InvestorFragmentView';
import { PersonView } from '../types/view/PersonView';
import { InvestorDataType } from './InvestorDataType';
import { PersonDataType } from './PersonDataType';

export type ConnectionDataType = InvestorDataType | PersonDataType;

/**
 * Retrieves the investorDataType from an investor, otherwise PersonDataType.Person
 *
 * This function checks if the provided data is of type `InvestorFragmentView`
 * or `PersonView`. If the object contains the property `investorDataType`, it will
 * return that value. If not, it defaults to `PersonDataType.Person`.
 *
 * @param {InvestorFragmentView | PersonView} data - The data object from which to retrieve the data type.
 * @returns {string} - The data type, either from `investorDataType` or the default `PersonDataType.Person`.
 *
 * @example
 * const investorData = { investorDataType: InvestorDataType.Unverified , ... };
 * const personData = { ... };
 *
 * const investorType = getDataType(investorData); // returns InvestorDataType.Unverified
 * const personType = getDataType(personData); // returns PersonDataType.Person
 */
export const getConnectionDataType = (
  data: InvestorFragmentView | PersonView | UnverifiedInvestor
): ConnectionDataType => {
  const dataType =
    'investorDataType' in data ? data.investorDataType : PersonDataType.Person;
  return dataType;
};
