import { FormikValues } from 'formik';
import InvestorFieldNames, {
  INVESTOR_URL_FIELDS,
} from '/src/enums/InvestorFieldNames';
import { updateUrlPrefix } from '/src/util/forms';
import { INV_IND_ANY_INDUSTRY } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import InvestorTypes from '/../libs/shared-types/src/constants/InvestorTypes';
import { isBlank } from '/src/util/formatting/strings';

/**
 * Some combinations of values are not allowed. This function removes the invalid data from the formikValues.
 * @param formValues
 * @returns A valid set formikValues for an Investor
 */
export function cleanInvestorData(formValues: FormikValues): any {
  let cleanValues: FormikValues = {
    ...formValues,

    // If Firm is NOT set, remove firmJoinedOn
    [InvestorFieldNames.FirmJoinedOn]: isBlank(
      formValues[InvestorFieldNames.Firm],
    )
      ? null
      : formValues[InvestorFieldNames.FirmJoinedOn],

    // If Angel, remove invAuthority
    [InvestorFieldNames.InvAuthority]:
      formValues[InvestorFieldNames.Type] === InvestorTypes.Angel
        ? null
        : formValues[InvestorFieldNames.InvAuthority],

    // If NOT open to cold inbound, remove preferenceContact
    [InvestorFieldNames.PreferenceContact]: formValues[
      InvestorFieldNames.IsOpenToColdInbound
    ]
      ? formValues[InvestorFieldNames.PreferenceContact]
      : null,

    // If NOT open to cold inbound, remove preferenceFollowUp
    [InvestorFieldNames.PreferenceFollowUp]: formValues[
      InvestorFieldNames.IsOpenToColdInbound
    ]
      ? formValues[InvestorFieldNames.PreferenceFollowUp]
      : null,

    // If InvIndustries is ANY_INDUSTRY, remove AntiInvIndustries
    [InvestorFieldNames.AntiInvIndustries]: formValues[
      InvestorFieldNames.InvIndustries
    ].includes(INV_IND_ANY_INDUSTRY)
      ? null
      : formValues[InvestorFieldNames.AntiInvIndustries],

    // If NOT HasInitialOwnershipTarget, remove InitialOwnershipTargetMin
    [InvestorFieldNames.InitialOwnershipTargetMin]: formValues[
      InvestorFieldNames.HasInitialOwnershipTarget
    ]
      ? formValues[InvestorFieldNames.InitialOwnershipTargetMin]
      : null,

    // If NOT HasInitialOwnershipTarget, remove InitialOwnershipTargetMax
    [InvestorFieldNames.InitialOwnershipTargetMax]: formValues[
      InvestorFieldNames.HasInitialOwnershipTarget
    ]
      ? formValues[InvestorFieldNames.InitialOwnershipTargetMax]
      : null,
  };

  cleanValues = updateUrlPrefix(cleanValues, INVESTOR_URL_FIELDS);

  return cleanValues;
}
