import React, { useState } from 'react';
import * as yup from 'yup';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import API from '/src/middleware/API';
import Alert from '/src/components/notifications/Alert';
import { IntroPath } from '/../libs/shared-types/src/types/model/IntroPath';
import { removeUnsetOptionalValues, updateUrlPrefix } from '/src/util/forms';
import CustomSelect from '/src/components/inputs/CustomSelect';
import DebugRender from '/src/components/utility/DebugRender';
import { Disclosure } from '@headlessui/react';
import { enumToList } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';
import { INTRO_PATH_RELATIONSHIP_TYPE_OPTIONS } from '/../libs/shared-types/src/constants/IntroPathRelationshipType';
import { RELATIONSHIP_STRENGTH_OPTIONS } from '/../libs/shared-types/src/constants/Strength';
import { FormatOptionLabelMeta } from 'react-select';
import {
  StartupAddOrUpdateIntroPath,
  StartupIntroPathSearchPeopleAndInvestors,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import { TooltipSelectOption } from '/../libs/shared-types/src/types/SelectOptions';
import FormatOptionWithTooltipLabel from '/src/components/inputs/ReactSelectAdditions/formatOptionWithTooltipLabel';
import { INVESTOR_URL_FIELDS } from '/src/enums/InvestorFieldNames';
import { IntroPathDirection } from '/../libs/shared-types/src/constants/IntroPathDirection';
import CustomSelectAsync from '../../../components/inputs/CustomSelectAsync';
import {
  ConnectionDataType,
  getConnectionDataType,
} from '/../libs/shared-types/src/constants/ConnectionDataType';
import {
  ComparableInvestorFragmentView,
  InvestorFragmentView,
} from '/../libs/shared-types/src/types/view/InvestorFragmentView';
import {
  ComparablePersonView,
  PersonView,
} from '/../libs/shared-types/src/types/view/PersonView';
import { PaginatedCollectionResponse } from '/../libs/shared-types/src/types/view/APIResponse';
import { generateTag } from '/src/util/generateTagCloud';
import { IntroPathView } from '../../../types/view/IntroPathView';
import { UnverifiedInvestor } from '../../../types/model/UnverifiedInvestor';
import { useQueryClient } from '@tanstack/react-query';
import { INVESTOR_LIST_BY_ID_KEY } from '/src/hooks/useGetInvestorListById';
import { PersonDataType } from '/../libs/shared-types/src/constants/PersonDataType';
import {
  INTRO_PATH_STATUS_OPTIONS,
  IntroPathStatusOption,
} from '/src/components/introPath/IntroPathStatusSelect';
import { getIntroPathEndConnection } from '/src/util/introPath';

enum FieldNames {
  MainConnectionIdentifier = 'mainConnectionIdentifier',
  ConnectionIdentifier = 'connectionIdentifier',
  IntroPathDirection = 'introPathDirection',
  Status = 'status',
  TargetRelationshipStrength = 'targetRelationshipStrength',
  TargetRelationshipType = 'targetRelationshipType',
}

const emptyIntroPath = {
  [FieldNames.MainConnectionIdentifier]: '',
  [FieldNames.ConnectionIdentifier]: '',
  [FieldNames.IntroPathDirection]: '',
  [FieldNames.Status]: '',
  [FieldNames.TargetRelationshipStrength]: '',
  [FieldNames.TargetRelationshipType]: '',
};

const validationSchemaWithMainConnection = yup.object({
  [FieldNames.MainConnectionIdentifier]: yup.object().required('Required'),
  [FieldNames.ConnectionIdentifier]: yup.object().required('Required'),
  [FieldNames.IntroPathDirection]: yup.string().required('Required'),
  [FieldNames.Status]: yup.string().required('Required'),
  [FieldNames.TargetRelationshipStrength]: yup.number().required('Required'),
  [FieldNames.TargetRelationshipType]: yup.string().required('Required'),
});

const validationSchema = yup.object({
  [FieldNames.ConnectionIdentifier]: yup.object().required('Required'),
  [FieldNames.IntroPathDirection]: yup.string().required('Required'),
  [FieldNames.Status]: yup.string().required('Required'),
  [FieldNames.TargetRelationshipStrength]: yup.number().required('Required'),
  [FieldNames.TargetRelationshipType]: yup.string().required('Required'),
});
type AddUpdateIntroPathForm = yup.InferType<typeof validationSchema> & {
  connectionIdentifier: {
    _id: string;
    value: string;
    details: InvestorFragmentView | PersonView | UnverifiedInvestor;
  };
  mainConnectionIdentifier?: {
    _id: string;
    value: string;
    details: InvestorFragmentView | PersonView | UnverifiedInvestor;
  };
};

const SelectConnectionField = ({
  name,
  label,
  isDisabled,
  fetchOptions,
  connection,
}: {
  name: string;
  label: string;
  isDisabled: boolean;
  fetchOptions: (inputValue: string) => Promise<any[]>;
  connection: any;
}) => (
  <Field
    className="custom-select"
    component={CustomSelectAsync}
    label={label}
    secondaryLabel="You can search connections by name, firm, or email"
    closeMenuOnSelect
    name={name}
    isDisabled={isDisabled}
    defaultValue={
      connection
        ? {
            value: connection._id,
            label: connection.firstName + ' ' + connection.lastName,
            details: connection,
          }
        : undefined
    }
    fetchOptions={fetchOptions}
    formatOptionLabel={(
      option: {
        label: string;
        value: string;
        details: InvestorFragmentView | PersonView;
      },
      formatOptionLabelMeta: FormatOptionLabelMeta<any>,
    ) => (
      <div className="flex items-center space-x-3">
        <div>{option.label}</div>
        {formatOptionLabelMeta.context === 'menu' && (
          <div className="flex items-center space-x-2">
            {generateTag(option.details.type, true)}
            {option.details.firm && (
              <div className="rounded-full bg-gray-100 px-2 py-1 text-sm font-medium text-gray-600">
                {option.details.firm}
              </div>
            )}
          </div>
        )}
      </div>
    )}
  />
);

interface AddOrUpdateIntroPathDialogProps {
  currentId?: string;
  currentDataType?: ConnectionDataType;
  currentName?: string;
  introPath?: IntroPathView;
  direction?: IntroPathDirection;
  onCancel: () => void;
  onSave: (introPath: IntroPath) => void;
}

function AddOrUpdateIntroPathDialog({
  currentId,
  currentDataType,
  currentName,
  introPath,
  direction,
  onCancel,
  onSave,
}: AddOrUpdateIntroPathDialogProps): JSX.Element {
  const connection =
    introPath && direction
      ? getIntroPathEndConnection(introPath, direction)
      : undefined;
  const isMainConnectionUnavailable = !(
    currentId &&
    currentDataType &&
    currentName
  );

  const [initialValues, setInitialValues] = useState<any>({
    ...emptyIntroPath,
    _id: introPath?._id,

    introPathDirection: isMainConnectionUnavailable
      ? IntroPathDirection.FacilitatedBy
      : direction,
    status: introPath?.status,
    targetRelationshipStrength: introPath?.targetRelationshipStrength
      ? String(introPath.targetRelationshipStrength)
      : undefined,
    targetRelationshipType: introPath?.targetRelationshipType,
    connectionIdentifier: connection
      ? {
          value: connection._id,
          label: connection.firstName + ' ' + connection.lastName,
          details: connection,
        }
      : undefined,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const isCreatingNewIntroPath = !introPath?._id;
  const queryClient = useQueryClient();

  function invalidateTargetLists() {
    queryClient.invalidateQueries({ queryKey: [INVESTOR_LIST_BY_ID_KEY] });
  }

  async function onSubmit(
    values: AddUpdateIntroPathForm,
    { setSubmitting }: FormikHelpers<any>,
  ) {
    setSubmitting(true);
    try {
      const cleanValues: AddUpdateIntroPathForm = removeUnsetOptionalValues(
        updateUrlPrefix(values, INVESTOR_URL_FIELDS),
      );

      const body = {
        // if _id is provided it will update the intro path
        _id: introPath ? introPath._id : undefined,
        sourceConnectionId:
          cleanValues.introPathDirection === IntroPathDirection.FacilitatedBy
            ? currentId
            : cleanValues.connectionIdentifier.details._id,
        sourceConnectionDataType:
          cleanValues.introPathDirection === IntroPathDirection.FacilitatedBy
            ? currentDataType
            : getConnectionDataType(cleanValues.connectionIdentifier.details),
        status: cleanValues.status,
        targetConnectionId:
          cleanValues.introPathDirection === IntroPathDirection.FacilitatedBy
            ? cleanValues.connectionIdentifier.details._id
            : currentId,
        targetConnectionDataType:
          cleanValues.introPathDirection === IntroPathDirection.FacilitatedBy
            ? getConnectionDataType(cleanValues.connectionIdentifier.details)
            : currentDataType,
        targetRelationshipStrength: Number(
          cleanValues.targetRelationshipStrength,
        ),
        targetRelationshipType: cleanValues.targetRelationshipType,
      };
      //
      if (
        isMainConnectionUnavailable &&
        cleanValues?.mainConnectionIdentifier
      ) {
        if (
          cleanValues.introPathDirection === IntroPathDirection.FacilitatedBy
        ) {
          body.sourceConnectionId =
            cleanValues.mainConnectionIdentifier?.details._id;
          body.sourceConnectionDataType = getConnectionDataType(
            cleanValues.mainConnectionIdentifier?.details,
          );
        } else {
          body.targetConnectionId =
            cleanValues.mainConnectionIdentifier?.details._id;
          body.targetConnectionDataType = getConnectionDataType(
            cleanValues.mainConnectionIdentifier?.details,
          );
        }
      }

      const introPathResponse = await API.post<IntroPath>(
        StartupAddOrUpdateIntroPath.buildEndpoint(),
        body,
      );
      invalidateTargetLists();
      onSave(introPathResponse);
    } catch (error: unknown) {
      if (error instanceof Error) {
        if (
          error.message ===
          'IntroPath validation failed: sourceConnectionId: sourceConnectionId and targetConnectionId must be different'
        ) {
          setErrorMessage('Source and target connection must be different');
        } else setErrorMessage(error.message);
      }
    } finally {
      setSubmitting(false);
    }
  }

  const fetchPeopleInvestorsOptions = async (inputValue: string) => {
    const url = StartupIntroPathSearchPeopleAndInvestors.buildEndpoint(
      undefined,
      {
        filter: inputValue,
      },
    );

    const data =
      await API.get<
        PaginatedCollectionResponse<
          InvestorFragmentView | PersonView,
          ComparableInvestorFragmentView | ComparablePersonView
        >
      >(url);

    const options = data.results
      .map((item) => ({
        label: item.name,
        value: item._id,
        details: item,
      }))
      .filter((item) => item.value !== currentId);
    return options;
  };

  return (
    <div className="w-screen bg-white p-4 sm:max-w-2xl sm:p-7">
      <Formik
        initialValues={initialValues}
        validationSchema={
          isMainConnectionUnavailable
            ? validationSchemaWithMainConnection
            : validationSchema
        }
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ values, dirty, isSubmitting, isValid }) => (
          <Form>
            <header className="relative mb-6 items-center justify-between sm:flex sm:flex-row">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {isCreatingNewIntroPath ? 'Create' : 'Edit'} Intro Path{' '}
                {!isMainConnectionUnavailable ? (
                  <>
                    {values.introPathDirection ===
                    IntroPathDirection.FacilitatedBy
                      ? 'from'
                      : values.introPathDirection === IntroPathDirection.Toward
                        ? 'to'
                        : 'for'}{' '}
                    {currentName}
                  </>
                ) : null}
                <p className="text-sm font-normal text-gray-600">
                  Map intro paths to find who can best connect you to target
                  investors
                </p>
              </h3>

              <div className="my-4 flex flex-col space-y-3 sm:my-0 sm:ml-4 sm:flex-row sm:space-x-3 sm:space-y-0">
                <button
                  type="button"
                  onClick={() => onCancel()}
                  className="app-button--neutral justify-center truncate"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="app-button--green justify-center truncate"
                  disabled={!isValid || !dirty || isSubmitting}
                >
                  Save
                  {isSubmitting && (
                    <div className="ml-2">
                      <LoadingSpinner color="white" />
                    </div>
                  )}
                </button>
              </div>
            </header>

            <section className="">
              {!isMainConnectionUnavailable && (
                <Field
                  className="custom-select"
                  component={CustomSelect}
                  label={`Is this an intro to or from ${currentName}?`}
                  closeMenuOnSelect
                  name={FieldNames.IntroPathDirection}
                  options={enumToList(IntroPathDirection).map((x) => ({
                    value: x,
                    label:
                      IntroPathDirection.FacilitatedBy === x
                        ? `From ${currentName} to someone else`
                        : `To ${currentName} via someone else`,
                  }))}
                  isDisabled={!isCreatingNewIntroPath}
                />
              )}

              {isMainConnectionUnavailable && (
                <SelectConnectionField
                  name={FieldNames.MainConnectionIdentifier}
                  label={`Select the intro ${values.introPathDirection === IntroPathDirection.Toward ? 'target' : 'source'}`}
                  isDisabled={!isCreatingNewIntroPath}
                  fetchOptions={fetchPeopleInvestorsOptions}
                  connection={connection}
                />
              )}

              {values.introPathDirection && (
                <>
                  <SelectConnectionField
                    name={FieldNames.ConnectionIdentifier}
                    label={`Select the intro ${values.introPathDirection === IntroPathDirection.FacilitatedBy ? 'target' : 'source'}`}
                    isDisabled={!isCreatingNewIntroPath}
                    fetchOptions={fetchPeopleInvestorsOptions}
                    connection={connection}
                  />

                  <Field
                    className="custom-select"
                    component={CustomSelect}
                    label="Intro relationship type"
                    secondaryLabel={`How does the connection know the target ${currentDataType === PersonDataType.Person ? 'person' : 'investor'}?`}
                    closeMenuOnSelect
                    name={FieldNames.TargetRelationshipType}
                    options={INTRO_PATH_RELATIONSHIP_TYPE_OPTIONS}
                    formatOptionLabel={(
                      option: TooltipSelectOption,
                      formatOptionLabelMeta: FormatOptionLabelMeta<any>,
                    ) => (
                      <FormatOptionWithTooltipLabel
                        option={option}
                        formatOptionLabelMeta={formatOptionLabelMeta}
                      />
                    )}
                  />

                  <Field
                    className="custom-select"
                    component={CustomSelect}
                    label="Intro relationship strength"
                    secondaryLabel={`How strong is the relationship between the connection and the target ${currentDataType === PersonDataType.Person ? 'person' : 'investor'}?`}
                    isClearable
                    closeMenuOnSelect
                    name={FieldNames.TargetRelationshipStrength}
                    options={RELATIONSHIP_STRENGTH_OPTIONS}
                    formatOptionLabel={(
                      option: TooltipSelectOption,
                      formatOptionLabelMeta: FormatOptionLabelMeta<any>,
                    ) => (
                      <FormatOptionWithTooltipLabel
                        option={option}
                        formatOptionLabelMeta={formatOptionLabelMeta}
                      />
                    )}
                  />

                  <Field
                    className="custom-select"
                    component={CustomSelect}
                    label="Intro status"
                    closeMenuOnSelect
                    name={FieldNames.Status}
                    options={INTRO_PATH_STATUS_OPTIONS}
                    formatOptionLabel={(option: IntroPathStatusOption) => (
                      <div className="flex items-center space-x-3">
                        <div className="inline-flex items-center rounded-full bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
                          {option.icon}
                        </div>
                        <div>{option.label}</div>
                      </div>
                    )}
                  />
                </>
              )}
            </section>

            <div className="my-4">
              <Alert
                color="red"
                alertType="Error"
                content={errorMessage}
                isShown={errorMessage !== ''}
                onClose={() => setErrorMessage('')}
              />
            </div>

            <DebugRender>
              <Disclosure>
                <Disclosure.Button className="app-button--neutral">
                  <pre>Formik Values:</pre>
                </Disclosure.Button>
                <Disclosure.Panel>
                  <pre className="mb-24">{JSON.stringify(values, null, 2)}</pre>
                </Disclosure.Panel>
              </Disclosure>
            </DebugRender>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddOrUpdateIntroPathDialog;
