import React from 'react';

interface ConnectedOnLinkedInBadgeProps {
  isConnectedOnLinkedIn: boolean | undefined;
}

const ConnectedOnLinkedInBadge: React.FC<ConnectedOnLinkedInBadgeProps> = ({
  isConnectedOnLinkedIn,
}) => {
  if (isConnectedOnLinkedIn === undefined || !isConnectedOnLinkedIn) {
    return null;
  }

  return (
    <span className="tooltip-wrapper ml-2">
      <span className={`tooltip tooltip-right ml-2 w-24 normal-case`}>
        You are connected on LinkedIn
      </span>
      <span className="text-gray-700">· 1st</span>
    </span>
  );
};

export default ConnectedOnLinkedInBadge;
