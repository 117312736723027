import React, { ReactNode } from 'react';
import { BaseMapper } from '/src/util/mapper';
import ContactCell from '/src/components/table/cells/InvestorCellName';
import { InvestorDiscoveryView } from '../../../types/view/InvestorDiscoveryView';
import TagCell from '/src/components/table/cells/TagCell';
import CellCheckSize from '/src/components/table/cells/InvestorCellCheckSize';
import CellFirmAndRole from '/src/components/table/cells/InvestorCellFirmRole';
import InvestorTypes from '../../../../../libs/shared-types/src/constants/InvestorTypes';
import { CellMultiAction } from '/src/components/table/cells/InvestorCellInvestorAction';
import { InvestorDataType } from '../../../constants/InvestorDataType';

import {
  QueueListIcon,
  ArrowLongUpIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/20/solid';
import FitScoreTagCell from '/src/components/table/cells/FitScoreTagCell';
import { HiddenFitScore } from '/src/util/generateTagCloud';

export enum INVESTOR_ACTION {
  REFRESH,
  ADD_TO_PIPELINE,
  ADD_TO_TARGET_LIST,
}

export type InvestorActionProps =
  | {
      type: INVESTOR_ACTION.REFRESH;
      payload?: undefined;
    }
  | {
      type: INVESTOR_ACTION.ADD_TO_PIPELINE;
      payload: {
        investorId: string;
        investorDataType: InvestorDataType;
      };
    }
  | {
      type: INVESTOR_ACTION.ADD_TO_TARGET_LIST;
      payload: {
        investorId: string;
        firstName: string;
        lastName: string;
      };
    };

export interface TargetInvestor extends Record<string, ReactNode> {
  id: string;
  name: ReactNode;
  type: ReactNode;
  firm: ReactNode;
  invAuthority: ReactNode;
  fitScore: ReactNode;
  checkRangeMin: ReactNode;
  leadsRounds: ReactNode;
  isOpenToColdInbound: ReactNode;
  action: ReactNode;
  className?: string;
}

export class InvestorMapper extends BaseMapper<
  InvestorDiscoveryView,
  TargetInvestor
> {
  private _onAction?: ({ type, payload }: InvestorActionProps) => void;
  private _hasFitScoreAccess: boolean;
  constructor(
    hasFitScoreAccess: boolean,
    onAction?: ({ type, payload }: InvestorActionProps) => void,
  ) {
    super();
    this._onAction = onAction;
    this._hasFitScoreAccess = hasFitScoreAccess;
  }
  mapTo(investor: InvestorDiscoveryView) {
    return {
      id: investor._id,
      name: (
        <ContactCell
          name={investor.name}
          subtitle={investor.location}
          image={investor.profilePicKey}
          enableCopy={false}
        />
      ),
      type: (
        <TagCell
          tag={investor.type}
          type="comparison"
          tagStyles={{
            [InvestorTypes.Angel]: 'bg-teal-100 text-teal-800',
            [InvestorTypes.FamilyOffice]: 'bg-cyan-100 text-cyan-800',
            [InvestorTypes.Syndicator]: 'bg-orange-100 text-orange-800',
            [InvestorTypes.VC]: 'bg-blue-100 text-blue-800',
            [InvestorTypes.CorporateVC]: 'bg-indigo-100 text-indigo-800',
            [InvestorTypes.Accelerator]: ' bg-pink-100 text-pink-800',
          }}
        />
      ),
      firm: <CellFirmAndRole firm={investor.firm} role={investor.role} />,
      invAuthority: <TagCell tag={investor.invAuthority} type="comparison" />,
      fitScore: this._hasFitScoreAccess ? (
        <FitScoreTagCell fitScore={investor.fitScore} />
      ) : (
        <HiddenFitScore />
      ),
      checkRangeMin: (
        <CellCheckSize
          checkRangeMax={investor.checkRangeMax}
          checkRangeMin={investor.checkRangeMin}
        />
      ),
      leadsRounds: <TagCell tag={investor.leadsRounds} type="comparison" />,
      isOpenToColdInbound: (
        <TagCell
          tag={investor.isOpenToColdInbound ? 'Yes' : 'No'}
          type="comparison"
        />
      ),
      action: this._onAction ? (
        <CellMultiAction<InvestorActionProps>
          label="investor-mapper"
          actions={[
            {
              title: 'Add to pipeline',
              icon: (
                <CurrencyDollarIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              ),
              action: {
                type: INVESTOR_ACTION.ADD_TO_PIPELINE,
                payload: {
                  investorId: investor._id,
                  investorDataType: investor.investorDataType,
                },
              },
            },
            {
              title: 'Add to target list',
              icon: (
                <div className="relative mr-2">
                  <QueueListIcon
                    className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  <ArrowLongUpIcon
                    className="absolute -bottom-0.5 -right-1 h-3.5 w-3.5 bg-white"
                    aria-hidden="true"
                  />
                </div>
              ),
              action: {
                type: INVESTOR_ACTION.ADD_TO_TARGET_LIST,
                payload: {
                  investorId: investor._id,
                  firstName: investor.firstName,
                  lastName: investor.lastName,
                },
              },
            },
          ]}
          onAction={(action: InvestorActionProps) => this._onAction?.(action)}
        />
      ) : (
        <></>
      ),
    };
  }
}
