import React from 'react';
import Tooltip from '../utility/Tooltip';
import {
  ChangeStats,
  ChangeStatType,
} from '/../libs/shared-types/src/types/view/ChangeStats';
import { cn } from '/src/util/cn';
import {
  ArrowDownIcon,
  ArrowsUpDownIcon,
  ArrowUpIcon,
} from '@heroicons/react/20/solid';
import { formatMsToDays } from '/src/util/formatting/dates';

interface BasicStatisticProps {
  label: string;
  statistic: number | string;
  tooltip?: string | JSX.Element;
  suffix?: string;
  changeStat?: ChangeStats;
}

function BasicStatistic({
  label,
  statistic,
  tooltip,
  suffix,
  changeStat,
}: BasicStatisticProps): JSX.Element {
  return (
    <div className="rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:p-6">
        <dt className="flex flex-row justify-between text-sm font-medium text-gray-500">
          <span>{label}</span>
          {tooltip && (
            <Tooltip width="w-64" position="left" tooltipText={tooltip} />
          )}
        </dt>
        <dd className="mt-1 flex flex-wrap items-baseline text-3xl font-semibold text-gray-900">
          {statistic}
          {suffix && (
            <span className="ml-2 text-xl font-normal text-gray-800">
              {suffix}
            </span>
          )}
          {changeStat && (
            <p
              className={cn(
                changeStat.type === ChangeStatType.Increase
                  ? 'text-green-600'
                  : changeStat.type === ChangeStatType.Decrease
                    ? 'text-red-600'
                    : 'text-amber-600',
                'ml-2 flex items-baseline text-base font-semibold',
              )}
            >
              {changeStat.type === ChangeStatType.Increase && (
                <ArrowUpIcon
                  aria-hidden="true"
                  className="h-5 w-5 flex-shrink-0 self-center text-green-600"
                />
              )}
              {changeStat.type === ChangeStatType.Decrease && (
                <ArrowDownIcon
                  aria-hidden="true"
                  className="h-5 w-5 flex-shrink-0 self-center text-red-600"
                />
              )}
              {changeStat.type === ChangeStatType.Stable && (
                <ArrowsUpDownIcon
                  aria-hidden="true"
                  className="h-5 w-5 flex-shrink-0 self-center text-amber-600"
                />
              )}

              {changeStat.change}
              <span className="ml-2">
                (past {formatMsToDays(changeStat.periodMs)})
              </span>
            </p>
          )}
        </dd>
      </div>
    </div>
  );
}

export default BasicStatistic;
