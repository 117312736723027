import React, { ReactNode } from 'react';
import { BaseMapper } from '/src/util/mapper';
import TagCell from '/src/components/table/cells/TagCell';
import CellFirmAndRole from '/src/components/table/cells/InvestorCellFirmRole';
import CellIntroduction from '/src/components/table/cells/InvestorCellName';
import {
  ConnectionDataType,
  getConnectionDataType,
} from '/../libs/shared-types/src/constants/ConnectionDataType';
import { IntroPathView } from '/../libs/shared-types/src/types/view/IntroPathView';
import StrengthTagCell from '../../../components/table/cells/StrengthTagCell';
import { IntroPathDirection } from '/../libs/shared-types/src/constants/IntroPathDirection';
import IntroPathByConnectionCellAction from '../../../components/table/cells/IntroPathByConnectionCellAction';
import FitScoreTagCell from '/src/components/table/cells/FitScoreTagCell';
import IntroPathSelect from '/src/components/introPath/IntroPathStatusSelect';
import { IntroPathStatus } from '../../../constants/IntroPathStatus';
import {
  getIntroPathEndConnection,
  getConnectionPrefixByDirection,
  getIntroPathStartConnection,
} from '/src/util/introPath';

export enum INTRO_PATH_BY_CONNECTION_ACTION {
  // To edit an intro path, the intro path pass in the payload will be sent in a request
  EDIT_INTRO_PATH_WITHOUT_FORM,
  // To edit an intro path, it will open a form to edit several values
  EDIT_INTRO_PATH,
  DELETE_INTRO_PATH,
}

export type IntroPathByConnectionActionProps =
  | {
      type: INTRO_PATH_BY_CONNECTION_ACTION.EDIT_INTRO_PATH_WITHOUT_FORM;
      payload: {
        currentId: string;
        currentDataType: ConnectionDataType;
        introPath: IntroPathView;
      };
    }
  | {
      type: INTRO_PATH_BY_CONNECTION_ACTION.EDIT_INTRO_PATH;
      payload: {
        currentId: string;
        currentDataType: ConnectionDataType;
        currentName: string;
        introPath?: IntroPathView;
      };
    }
  | {
      type: INTRO_PATH_BY_CONNECTION_ACTION.DELETE_INTRO_PATH;
      payload: {
        currentId: string;
        currentDataType: ConnectionDataType;
        introPath?: IntroPathView;
      };
    };

export interface IntroPathRow extends Record<string, ReactNode> {
  id: string;
  targetIntroRelationshipType: ReactNode;
  targetIntroRelationshipStrength: ReactNode;
  status: ReactNode;
  impactScore: ReactNode;
  action: ReactNode;
  [key: string]: ReactNode;
}

export class IntroPathByConnectionMapper extends BaseMapper<
  IntroPathView,
  IntroPathRow
> {
  private _onAction: ({
    type,
    payload,
  }: IntroPathByConnectionActionProps) => void;
  private _direction;
  constructor(
    direction: IntroPathDirection,
    onAction: ({ type, payload }: IntroPathByConnectionActionProps) => void,
  ) {
    super();
    this._onAction = onAction;
    this._direction = direction;
  }

  mapTo(introPath: IntroPathView) {
    const oppositeConnection = getIntroPathStartConnection(
      introPath,
      this._direction,
    );
    const connection = getIntroPathEndConnection(introPath, this._direction);
    const connectionPrefix = getConnectionPrefixByDirection(this._direction);

    const handleUpdateIntroPathStatus = (status: IntroPathStatus) => {
      this._onAction({
        type: INTRO_PATH_BY_CONNECTION_ACTION.EDIT_INTRO_PATH_WITHOUT_FORM,
        payload: {
          currentDataType: getConnectionDataType(oppositeConnection),
          currentId: oppositeConnection._id,
          introPath: {
            ...introPath,
            status,
          },
        },
      });
    };

    return {
      id: introPath._id,

      [`${connectionPrefix}Name`]: (
        <CellIntroduction
          name={`${connection?.firstName} ${connection?.lastName}`}
          subtitle={connection?.location}
          isConnectedOnLinkedIn={
            connection?.relationship?.isConnectedOnLinkedIn
          }
        />
      ),

      [`${connectionPrefix}StartupRelationshipType`]: (
        <TagCell tag={connection?.relationship?.type} type="comparison" />
      ),

      [`${connectionPrefix}StartupRelationshipStrength`]: (
        <StrengthTagCell strength={connection?.relationship?.strength} />
      ),

      [`${connectionPrefix}Firm`]: (
        <CellFirmAndRole firm={connection?.firm} role={connection?.role} />
      ),

      targetIntroRelationshipType: (
        <TagCell tag={introPath.targetRelationshipType} type="comparison" />
      ),

      targetIntroRelationshipStrength: (
        <StrengthTagCell strength={introPath.targetRelationshipStrength} />
      ),

      status: (
        <IntroPathSelect
          value={introPath.status}
          onSelect={handleUpdateIntroPathStatus}
        />
      ),

      impactScore: <FitScoreTagCell fitScore={introPath.impactScore} />,

      action: (
        <IntroPathByConnectionCellAction
          introPath={introPath}
          connection={oppositeConnection}
          onAction={this._onAction}
        />
      ),
    };
  }
}
