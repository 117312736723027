import React from 'react';
import { getPluralSuffix } from '/src/util/formatting/numbers';
import { generateTag, generateTagCloud } from '/src/util/generateTagCloud';
import { PublicInvestor } from '/../libs/shared-types/src/types/view/investor/PublicInvestor';
import {
  dayDiff,
  formatDate,
  howLongBetween,
} from '/src/util/formatting/dates';
import DateString from '/src/components/utility/DateString';

interface InvestorDetailAboutCardProps {
  investor: PublicInvestor;
}

function InvestorDetailAboutCard({
  investor,
}: InvestorDetailAboutCardProps): JSX.Element {
  return (
    <section aria-labelledby="startup-general-information-title">
      <div className="rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">About</h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Investor Type
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {generateTag(investor.type)}
              </dd>
            </div>

            {investor.isAccredited !== undefined && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Accreditation Status
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {investor.isAccredited
                    ? generateTag('Accredited', true)
                    : generateTag('Not Accredited', true)}
                </dd>
              </div>
            )}

            {investor.firmJoinedOn && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  At Firm Since
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {formatDate(investor.firmJoinedOn)}
                  &nbsp;({howLongBetween(investor.firmJoinedOn)})
                </dd>
              </div>
            )}

            {investor.invAuthority && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Investment Authority
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {generateTag(investor.invAuthority)}
                </dd>
              </div>
            )}

            {investor.expInvestingYears && investor.expInvestingYears > 0 && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Investing Experience
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {`${investor.expInvestingYears} year${getPluralSuffix(
                    investor.expInvestingYears,
                  )}`}
                </dd>
              </div>
            )}

            {investor.professionalAccreditations &&
              investor.professionalAccreditations.length > 0 && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Professional Accreditations
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {generateTagCloud(investor.professionalAccreditations)}
                  </dd>
                </div>
              )}

            {investor.taxState && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Tax State</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {investor.taxState}
                </dd>
              </div>
            )}

            {investor.highestEducationLevel && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Highest Education Level
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {investor.highestEducationLevel}
                </dd>
              </div>
            )}

            {investor.universityAffiliations &&
              investor.universityAffiliations.length > 0 && (
                <div className="sm:col-span-2">
                  <dt className="text-sm font-medium text-gray-500">
                    University Affiliations
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {generateTagCloud(investor.universityAffiliations)}
                  </dd>
                </div>
              )}

            {investor.introBio && (
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Intro Bio</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {investor.introBio}
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>
    </section>
  );
}

export default InvestorDetailAboutCard;
