import React, { ReactNode } from 'react';
import { InvestorListItemView } from '../../../types/view/InvestorListView';
import { BaseMapper } from '/src/util/mapper';
import { TARGET_LIST_DETAILS_ACTION } from './InvestorListDetailRoute';
import InvestorCellPriority from '/src/components/table/cells/InvestorCellPriority';
import StrengthTagCell from '../../../components/table/cells/StrengthTagCell';
import InvestorCellIsIntroNeeded from '/src/components/table/cells/InvestorCellIsIntroNeeded';
import InvestorCellTargetDetailAction from '/src/components/table/cells/InvestorCellInvestorAction';
import CellIntroduction from '/src/components/table/cells/InvestorCellName';
import { SocialHandleName } from '/src/components/SocialHandles';
import CellFirmAndRole from '/src/components/table/cells/InvestorCellFirmRole';
import CellCheckSize from '/src/components/table/cells/InvestorCellCheckSize';
import TagCell from '/src/components/table/cells/TagCell';
import InvestorTypes from '../../../../../libs/shared-types/src/constants/InvestorTypes';
import FitScoreTagCell from '/src/components/table/cells/FitScoreTagCell';

export interface TargetInvestor extends Record<string, ReactNode> {
  id: string;
  name: ReactNode;
  type: ReactNode;
  firm: ReactNode;
  fitScore: ReactNode;
  checkRangeMin: ReactNode;
  leadsRounds: ReactNode;
  priority: ReactNode;
  relationshipStrength: ReactNode;
  isIntroNeeded: ReactNode;
  action: ReactNode;
}

export class TargetInvestorMapper extends BaseMapper<
  InvestorListItemView,
  TargetInvestor
> {
  private _listId: string;
  private _onAction?: ({
    type,
    payload,
  }: {
    type: TARGET_LIST_DETAILS_ACTION;
    payload?: any;
  }) => void;
  private isReadOnly: boolean;

  constructor(
    listId: string,
    onAction?: ({
      type,
      payload,
    }: {
      type: TARGET_LIST_DETAILS_ACTION;
      payload?: any;
    }) => void,
    isReadOnly?: boolean,
  ) {
    super();
    this._listId = listId;
    this._onAction = onAction;
    this.isReadOnly = isReadOnly ?? false;
  }
  mapTo(investor: InvestorListItemView) {
    return {
      id: investor.investorId,
      name: (
        <CellIntroduction
          name={investor.name}
          email={investor.email}
          image={investor.picKey}
          socialUrls={[
            {
              name: SocialHandleName.LinkedIn,
              url: investor.linkedIn,
            },
            {
              name: SocialHandleName.Other,
              url: investor.website,
            },
          ]}
          isConnectedOnLinkedIn={investor.relationship?.isConnectedOnLinkedIn}
        />
      ),
      type: (
        <TagCell
          tag={investor.type}
          type="comparison"
          tagStyles={{
            [InvestorTypes.Angel]: 'bg-teal-100 text-teal-800',
            [InvestorTypes.FamilyOffice]: 'bg-cyan-100 text-cyan-800',
            [InvestorTypes.Syndicator]: 'bg-orange-100 text-orange-800',
            [InvestorTypes.VC]: 'bg-blue-100 text-blue-800',
            [InvestorTypes.CorporateVC]: 'bg-indigo-100 text-indigo-800',
            [InvestorTypes.Accelerator]: ' bg-pink-100 text-pink-800',
          }}
        />
      ),
      firm: <CellFirmAndRole firm={investor.firm} role={investor.role} />,
      fitScore: <FitScoreTagCell fitScore={investor.fitScore} />,
      checkRangeMin: (
        <CellCheckSize
          checkRangeMax={investor.checkRangeMax}
          checkRangeMin={investor.checkRangeMin}
        />
      ),
      leadsRounds: <TagCell tag={investor.leadsRounds} type="comparison" />,
      priority: (
        <InvestorCellPriority
          investor={investor}
          listId={this._listId}
          isReadOnly={this.isReadOnly}
        />
      ),
      relationshipStrength: (
        <StrengthTagCell strength={investor.relationship?.strength} />
      ),
      isIntroNeeded: <InvestorCellIsIntroNeeded investor={investor} />,
      targetIntroPathsCount: (
        <TagCell tag={investor.targetIntroPathsCount} type="comparison" />
      ),
      action: this._onAction ? (
        <InvestorCellTargetDetailAction
          investor={investor}
          listId={this._listId}
          onAction={this._onAction}
        />
      ) : (
        <></>
      ),
    };
  }
}
