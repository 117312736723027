import { FormikValues } from 'formik';
import {
  AdminCreateOrUpdateUnverifiedInvestor,
  AdminUpdateUnverifiedInvestorById,
  CognitoUploadPublic,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import API from '/src/middleware/API';
import { uploadFile } from '/src/util/FileHelpers';
import { removeUnsetOptionalValues } from '/src/util/forms';
import { UnverifiedInvestor } from '/../libs/shared-types/src/types/model/UnverifiedInvestor';
import { convertDatePickerInputToUtc } from '/src/util/formatting/dates';
import { isBlank } from '/src/util/formatting/strings';

export async function createOrUpdateUnverifiedInvestor(
  formValues: FormikValues,
  unverifiedInvestorId: string | undefined,
): Promise<string> {
  const updatedData = removeUnsetOptionalValues(formValues);

  const newProfilePicKey = await uploadFile(
    updatedData[InvestorFieldNames.ProfilePic],
    CognitoUploadPublic.buildEndpoint(),
  );
  if (newProfilePicKey) {
    updatedData[InvestorFieldNames.ProfilePicKey] = newProfilePicKey;
  }

  // Build the Investor object that must be posted
  const investorProfile: UnverifiedInvestor = {
    ...updatedData,
    firmJoinedOn: isBlank(updatedData.firmJoinedOn)
      ? undefined
      : convertDatePickerInputToUtc(new Date(updatedData.firmJoinedOn)),
  };

  try {
    if (unverifiedInvestorId) {
      await API.put(
        AdminUpdateUnverifiedInvestorById.buildEndpoint(unverifiedInvestorId),
        investorProfile,
      );
      return `Unverified investor updated by Id: ${unverifiedInvestorId}`;
    }

    const resultMessage = await API.post(
      AdminCreateOrUpdateUnverifiedInvestor.buildEndpoint(),
      investorProfile,
    );
    return resultMessage;
  } catch (error: any) {
    throw Error(error.message);
  }
}
