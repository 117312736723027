import React from 'react';

import {
  displayMoneyRange,
  displayPercentageRange,
} from '/src/util/formatting/numbers';
import { generateTag, generateTagCloud } from '/src/util/generateTagCloud';
import { PublicInvestor } from '/../libs/shared-types/src/types/view/investor/PublicInvestor';
import { INV_IND_ANY_INDUSTRY } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';

interface InvestorDetailThesisCardProps {
  investor: PublicInvestor;
}

function InvestorDetailThesisCard({
  investor,
}: InvestorDetailThesisCardProps): JSX.Element {
  return (
    <section aria-labelledby="startup-general-information-title">
      <div className="rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Investment Thesis
          </h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Primary Investment Stages
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {generateTagCloud(investor.invStages)}
              </dd>
            </div>

            {investor.invStagesFollowOn && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Follow-on Investment Stages
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {generateTagCloud(investor.invStagesFollowOn)}
                </dd>
              </div>
            )}

            {investor.invProductStage && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Earliest Product Stage
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {generateTag(investor.invProductStage)}
                </dd>
              </div>
            )}

            {investor.checkRangeMax && investor.checkRangeMin && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Investment Check Size
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {displayMoneyRange(
                    investor.checkRangeMin,
                    investor.checkRangeMax,
                  )}
                </dd>
              </div>
            )}

            {investor.hasInitialOwnershipTarget !== undefined && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Initial Ownership Target
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {investor.hasInitialOwnershipTarget &&
                  investor.initialOwnershipTargetMin !== undefined &&
                  investor.initialOwnershipTargetMax !== undefined
                    ? displayPercentageRange(
                        investor.initialOwnershipTargetMin,
                        investor.initialOwnershipTargetMax,
                      )
                    : 'None'}
                </dd>
              </div>
            )}

            {investor.yearlyAvgInvCount && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Investments per Year
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {investor.yearlyAvgInvCount}
                </dd>
              </div>
            )}

            {investor.leadsRounds && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Leading Rounds
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {investor.leadsRounds}
                </dd>
              </div>
            )}

            {investor.takesBoardSeats && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Taking Board Seats
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {investor.takesBoardSeats}
                </dd>
              </div>
            )}

            {investor.invProductCategories && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Investment Product Categories
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {generateTagCloud(investor.invProductCategories)}
                </dd>
              </div>
            )}

            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">
                Investment Industries
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {generateTagCloud(investor.invIndustries)}
              </dd>
            </div>

            {!investor.invIndustries.includes(INV_IND_ANY_INDUSTRY) &&
              investor.antiInvIndustries && (
                <div className="sm:col-span-2">
                  <dt className="text-sm font-medium text-gray-500">
                    Never Invests In
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {generateTagCloud(investor.antiInvIndustries)}
                  </dd>
                </div>
              )}

            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">
                Investment Business Models
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {generateTagCloud(investor.invBusinessModels)}
              </dd>
            </div>

            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">
                Investment Geographies
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {generateTagCloud(investor.invGeographies)}
              </dd>
            </div>

            {investor.invTypes && (
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Investment Types
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {generateTagCloud(investor.invTypes)}
                </dd>
              </div>
            )}

            {investor.invImpacts && investor.invImpacts.length > 0 && (
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Impact & Team Diversity Focus
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {generateTagCloud(investor.invImpacts)}
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>
    </section>
  );
}

export default InvestorDetailThesisCard;
