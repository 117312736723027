import React, { ReactNode } from 'react';
import { BaseMapper } from '/src/util/mapper';
import TagCell from '/src/components/table/cells/TagCell';
import CellFirmAndRole from '/src/components/table/cells/InvestorCellFirmRole';
import CellIntroduction from '/src/components/table/cells/InvestorCellName';
import { ConnectionDataType } from '/../libs/shared-types/src/constants/ConnectionDataType';
import {
  ComparableIntroPathView,
  IntroPathView,
} from '/../libs/shared-types/src/types/view/IntroPathView';
import StrengthTagCell from '../../../components/table/cells/StrengthTagCell';
import FitScoreTagCell from '/src/components/table/cells/FitScoreTagCell';
import IntroPathSelect from '/src/components/introPath/IntroPathStatusSelect';
import { IntroPathStatus } from '../../../constants/IntroPathStatus';
import IntroPathCellAction from '/src/components/table/cells/IntroPathCellAction';
import { SocialHandleName } from '/src/components/SocialHandles';

export enum INTRO_PATH_ACTION {
  // To edit an intro path, the intro path pass in the payload will be sent in a request
  EDIT_INTRO_PATH_WITHOUT_FORM,
  // To edit an intro path, it will open a form to edit several values
  EDIT_INTRO_PATH,
  DELETE_INTRO_PATH,
}

export type IntroPathActionProps =
  | {
      type: INTRO_PATH_ACTION.EDIT_INTRO_PATH;
      payload: {
        introPath: IntroPathView;
      };
    }
  | {
      type: INTRO_PATH_ACTION.EDIT_INTRO_PATH_WITHOUT_FORM;
      payload: {
        introPath: IntroPathView;
      };
    }
  | {
      type: INTRO_PATH_ACTION.DELETE_INTRO_PATH;
      payload: {
        introPath: IntroPathView;
      };
    };

export interface IntroPathRow extends Record<string, ReactNode> {
  id: string;
  sourceName: ReactNode;
  sourceStartupRelationshipType: ReactNode;
  targetName: ReactNode;
  targetFirm: ReactNode;
  targetIntroRelationshipType: ReactNode;
  targetIntroRelationshipStrength: ReactNode;
  status: ReactNode;
  impactScore: ReactNode;
}

export class IntroPathMapper extends BaseMapper<IntroPathView, IntroPathRow> {
  private _onAction: ({ type, payload }: IntroPathActionProps) => void;
  constructor(onAction: ({ type, payload }: IntroPathActionProps) => void) {
    super();
    this._onAction = onAction;
  }

  mapTo(introPath: IntroPathView) {
    const handleUpdateIntroPathStatus = (status: IntroPathStatus) => {
      this._onAction({
        type: INTRO_PATH_ACTION.EDIT_INTRO_PATH_WITHOUT_FORM,
        payload: {
          introPath: {
            ...introPath,
            status,
          },
        },
      });
    };

    return {
      id: introPath._id,

      sourceName: (
        <CellIntroduction
          name={`${introPath.sourceConnection.firstName} ${introPath.sourceConnection.lastName}`}
          email={introPath.sourceConnection.email}
          socialUrls={[
            {
              name: SocialHandleName.LinkedIn,
              url: introPath.sourceConnection.linkedIn,
            },
          ]}
          isConnectedOnLinkedIn={
            introPath.sourceConnection.relationship?.isConnectedOnLinkedIn
          }
        />
      ),

      sourceStartupRelationshipType: (
        <TagCell
          tag={introPath.sourceConnection.relationship?.type}
          type="comparison"
        />
      ),

      targetName: (
        <CellIntroduction
          name={`${introPath.targetConnection.firstName} ${introPath.targetConnection.lastName}`}
          email={introPath.targetConnection.email}
          socialUrls={[
            {
              name: SocialHandleName.LinkedIn,
              url: introPath.targetConnection.linkedIn,
            },
          ]}
          isConnectedOnLinkedIn={
            introPath.targetConnection.relationship?.isConnectedOnLinkedIn
          }
        />
      ),

      targetFirm: (
        <CellFirmAndRole
          firm={introPath.targetConnection.firm}
          role={introPath.targetConnection.role}
        />
      ),

      targetIntroRelationshipType: (
        <TagCell tag={introPath.targetRelationshipType} type="comparison" />
      ),

      targetIntroRelationshipStrength: (
        <StrengthTagCell strength={introPath.targetRelationshipStrength} />
      ),

      status: (
        <IntroPathSelect
          value={introPath.status}
          onSelect={handleUpdateIntroPathStatus}
        />
      ),

      impactScore: <FitScoreTagCell fitScore={introPath.impactScore} />,

      action: (
        <IntroPathCellAction introPath={introPath} onAction={this._onAction} />
      ),
    };
  }
}
