import React, { ReactNode } from 'react';
import { BaseMapper } from '/src/util/mapper';
import ContactCell from '/src/components/table/cells/InvestorCellName';
import { PersonView } from '../../../types/view/PersonView';
import CellFirmAndRole from '/src/components/table/cells/InvestorCellFirmRole';
import { CellMultiAction } from '/src/components/table/cells/InvestorCellInvestorAction';
import {
  PencilSquareIcon,
  TrashIcon,
  UsersIcon,
} from '@heroicons/react/20/solid';
import SocialHandles, { SocialHandleName } from '/src/components/SocialHandles';
import CellEmail from '/src/components/table/cells/InvestorCellEmail';
import DateString from '/src/components/utility/DateString';
import CellOptionalText from '../../../components/table/cells/CellOptionalText';
import { Founder } from '../../../types/model/Founder';
import StrengthTagCell from '../../../components/table/cells/StrengthTagCell';
import TagCell from '/src/components/table/cells/TagCell';

export enum PERSON_ACTION {
  REFRESH,
  EDIT_RELATIONSHIP,
  DELETE_RELATIONSHIP,
  UPDATE_STARTUP_RELATIONSHIP,
}

export type PersonActionProps =
  | {
      type: PERSON_ACTION.REFRESH;
      payload?: undefined;
    }
  | {
      type: PERSON_ACTION.EDIT_RELATIONSHIP | PERSON_ACTION.DELETE_RELATIONSHIP;
      payload: { person: PersonView };
    }
  | {
      type: PERSON_ACTION.UPDATE_STARTUP_RELATIONSHIP;
      payload: { person: PersonView };
    };

export interface TargetPerson extends Record<string, ReactNode> {
  id: string;
  name: ReactNode;
  firm: ReactNode;
  email: ReactNode;
  links: ReactNode;
  updatedOn: ReactNode;
  action: ReactNode;
  className?: string;
}

const getFounderName = (connectedFounderId?: string, founders?: Founder[]) => {
  const connectedFounder = founders?.find(
    (founder) => connectedFounderId === founder._id,
  );
  if (connectedFounder?.firstName || connectedFounder?.lastName) {
    return `${connectedFounder.firstName} ${connectedFounder.lastName}`;
  }
  return null;
};

export class PersonMapper extends BaseMapper<PersonView, TargetPerson> {
  private _onAction?: ({ type, payload }: PersonActionProps) => void;
  constructor(onAction?: ({ type, payload }: PersonActionProps) => void) {
    super();
    this._onAction = onAction;
  }
  mapTo(person: PersonView) {
    return {
      id: person._id,
      name: (
        <ContactCell
          name={person.name}
          subtitle={person.location}
          image={''}
          enableCopy={false}
          isConnectedOnLinkedIn={person?.relationship?.isConnectedOnLinkedIn}
        />
      ),
      firm: <CellFirmAndRole firm={person.firm} role={person.role} />,
      email: <CellEmail email={person.email} />,
      links: (
        <SocialHandles
          socialUrls={[
            {
              name: SocialHandleName.LinkedIn,
              url: person.linkedIn,
            },
            { name: SocialHandleName.Other, url: person.website },
          ]}
        />
      ),
      relationshipType: (
        <TagCell tag={person.relationship?.type} type="comparison" />
      ),
      connectedFounder: (
        <CellOptionalText
          text={getFounderName(
            person.relationship?.connectedFounderId,
            person.relationship?.startupFounders,
          )}
        />
      ),
      relationshipStrength: (
        <StrengthTagCell strength={person.relationship?.strength} />
      ),
      updatedOn: (
        <div className="app-table-row-text">
          <DateString date={person.updatedOn} tooltipPosition="bottom" />
        </div>
      ),
      action: this._onAction ? (
        <CellMultiAction<PersonActionProps>
          label="person-mapper"
          actions={[
            {
              title: 'Edit',
              icon: (
                <PencilSquareIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              ),
              action: {
                type: PERSON_ACTION.EDIT_RELATIONSHIP,
                payload: {
                  person: person,
                },
              },
            },
            {
              title: 'Update Relationship',
              icon: (
                <UsersIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              ),
              action: {
                type: PERSON_ACTION.UPDATE_STARTUP_RELATIONSHIP,
                payload: {
                  person: person,
                },
              },
            },
            {
              title: 'Delete',
              icon: (
                <TrashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              ),
              action: {
                type: PERSON_ACTION.DELETE_RELATIONSHIP,
                payload: {
                  person: person,
                },
              },
            },
          ]}
          onAction={(action: PersonActionProps) => this._onAction?.(action)}
        />
      ) : (
        <></>
      ),
    };
  }
}
