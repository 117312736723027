export enum StartupActivationTasks {
  CompleteNewUserFirstLogInTour = '6527521f59d9a497d73c9809',
  OpenRound = '62e6dce19be13c12bda6645a',
  PreviewCompanyOnePager = '652ab68d141c25ff954ed943',
  FirstDealViewSession = '652abd0e0cc089d116ee0ded',
  ViewInvestorDb = '65468ac3879026bca5e1b560',
  FirstTargetList = '6724362ba536819b1508f965',
  FiveUniqueDealViewSessions = '62e6dd86f8db14103c30de4b',
  ReferStartup = '62e6dd7ec16840683791985f',
}
