enum InvestorFieldNames {
  AngelList = 'angelList',
  AntiInvIndustries = 'antiInvIndustries',
  CheckRangeMax = 'checkRangeMax',
  CheckRangeMin = 'checkRangeMin',
  Email = 'email',
  ExpFunctional = 'expFunctional',
  ExpIndustry = 'expIndustry',
  ExpInvestingYears = 'expInvestingYears',
  Firm = 'firm',
  FirmJoinedOn = 'firmJoinedOn',
  HasInitialOwnershipTarget = 'hasInitialOwnershipTarget',
  HighestEducationLevel = 'highestEducationLevel',
  InitialOwnershipTargetMax = 'initialOwnershipTargetMax',
  InitialOwnershipTargetMin = 'initialOwnershipTargetMin',
  IntroBio = 'introBio',
  InvAuthority = 'invAuthority',
  InvBusinessModels = 'invBusinessModels',
  InvGeographies = 'invGeographies',
  InvImpacts = 'invImpacts',
  InvIndustries = 'invIndustries',
  InvProductCategories = 'invProductCategories',
  InvProductStage = 'invProductStage',
  InvStages = 'invStages',
  InvStagesFollowOn = 'invStagesFollowOn',
  InvTypes = 'invTypes',
  IsAccredited = 'isAccredited',
  IsIncludedInInvestorDbForStartups = 'isIncludedInInvestorDbForStartups',
  IsOpenToColdInbound = 'isOpenToColdInbound',
  LeadsRounds = 'leadsRounds',
  LinkedIn = 'linkedIn',
  Location = 'location',
  Medium = 'medium',
  Nationalities = 'nationalities',
  OtherUrl = 'otherUrl',
  Phone = 'phone',
  PreferenceContact = 'preferenceContact',
  PreferenceFollowUp = 'preferenceFollowUp',
  ProfessionalAccreditations = 'professionalAccreditations',
  ProfilePic = 'profilePic',
  ProfilePicKey = 'profilePicKey',
  Pronouns = 'pronouns',
  Role = 'role',
  Substack = 'substack',
  TakesBoardSeats = 'takesBoardSeats',
  TaxState = 'taxState',
  Twitter = 'twitter',
  Type = 'type',
  UniversityAffiliations = 'universityAffiliations',
  Website = 'website',
  YearlyAvgInvCount = 'yearlyAvgInvCount',
}

export const INVESTOR_URL_FIELDS = [
  InvestorFieldNames.AngelList,
  InvestorFieldNames.LinkedIn,
  InvestorFieldNames.Medium,
  InvestorFieldNames.OtherUrl,
  InvestorFieldNames.Substack,
  InvestorFieldNames.Twitter,
  InvestorFieldNames.Website,
];

export default InvestorFieldNames;
