import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { IMPACT_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';

const InvImpactsField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      component={CustomSelect}
      isClearable
      isMulti
      label="Impact &amp; Team Diversity Focus"
      name={InvestorFieldNames.InvImpacts}
      options={IMPACT_OPTIONS}
      placeholder="Select option..."
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.InvImpacts]: yup
      .array()
      .of(yup.string())
      .nullable()
      .test(
        'notApplicableOnly',
        `"${IMPACT_OPTIONS[0].value}" cannot be combined with other options`,
        (impacts): boolean => {
          if (impacts?.includes(IMPACT_OPTIONS[0].value)) {
            // Return false to indicate the test failed
            return impacts.length === 1;
          }
          return true;
        },
      ),
  }),
};

export default InvImpactsField;
