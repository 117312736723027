import React, { useState } from 'react';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import UpdateStartupRelationshipDialog from '/src/routes/startup/RoundManager/InvestorPipeline/UpdateStartupRelationshipDialog';
import { StartupRelationship } from '../../../../types/model/StartupRelationship';
import API from '/src/middleware/API';
import { StartupRelationshipUpdate } from '/../libs/shared-types/src/constants/ApiRoutes';
import Logger from '/src/services/logger';
import InvestorDetailRelationshipCard from '/src/routes/investor/InvestorDetail/cards/InvestorDetailRelationshipCard';
import { StartupRelationshipView } from '../../../../types/view/StartupRelationshipView';
import { PersonDataType } from '../../../../constants/PersonDataType';

interface PersonActivityTabProps {
  startupRelationship: StartupRelationshipView;
  personId: string;
  personName: string;
  personDataType: PersonDataType;
  fetchPersonData: () => Promise<void>;
}

function PersonActivityTab({
  startupRelationship,
  personId,
  personName,
  personDataType,
  fetchPersonData,
}: PersonActivityTabProps): JSX.Element {
  const [modalConfig, setModalConfig] = useState<{
    type: undefined | 'updateStartupRelationship' | 'updateInvestorStatus';
    isOpen: boolean;
  }>({
    type: undefined,
    isOpen: false,
  });

  const closeModal = () => {
    setModalConfig({ type: undefined, isOpen: false });
  };

  const openUpdateStartupRelationshipModal = () => {
    setModalConfig({
      type: 'updateStartupRelationship',
      isOpen: true,
    });
  };

  const updateStartupRelationship = async (
    personId: string,
    personDataType: PersonDataType,
    update: Partial<StartupRelationship>,
  ) => {
    try {
      await API.put(StartupRelationshipUpdate.buildEndpoint(), {
        investorId: personId,
        investorDataType: personDataType,
        update,
      });
      fetchPersonData();
      closeModal();
    } catch (error: any) {
      Logger.error(error.message);
      throw error;
    }
  };

  return (
    <>
      <div className="mx-auto mt-8 grid max-w-full grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-3">
        <main className="space-y-6 lg:col-span-2 lg:col-start-1">
          <InvestorDetailRelationshipCard
            relationship={startupRelationship}
            updateRelationship={openUpdateStartupRelationshipModal}
          />
        </main>
      </div>

      <ModalWrapper open={modalConfig.isOpen} onClose={() => closeModal()}>
        {modalConfig.type === 'updateStartupRelationship' && (
          <UpdateStartupRelationshipDialog
            connectionName={personName}
            connectionDataType={personDataType}
            relationship={startupRelationship}
            onCancel={closeModal}
            onSave={(update: Partial<StartupRelationship>) => {
              return updateStartupRelationship(
                personId,
                personDataType,
                update,
              );
            }}
          />
        )}
      </ModalWrapper>
    </>
  );
}

export default PersonActivityTab;
