import { enumToSelectOptions } from '../extensions/SelectOptionsExtensions';
import { NOT_SET_OPTION } from './SelectOptions/SelectOptions';

export enum InvestmentAuthority {
  None = 'None',
  Junior = 'Junior',
  Senior = 'Senior',
  Partner = 'Partner',
}

export const INVESTMENT_AUTHORITY_WITH_UNSET_OPTIONS = [
  ...enumToSelectOptions(InvestmentAuthority),
  {
    value: NOT_SET_OPTION.value,
    label: NOT_SET_OPTION.label,
  },
];
