import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { useLocation } from 'react-router-dom';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import { formatCurrentDate } from '/src/util/formatting/dates';
import FormField from '/src/interfaces/FormField';
import FormikInput from '../../inputs/FormikInput';
import { COMPLETE_PROFILE_ROUTE } from '/src/constants/Routes';
import {
  VC_BEGINNING_DATE,
  VC_BEGINNING_DATE_STRING,
} from '/../libs/shared-types/src/constants/DateRanges';

function FieldWrapper(): JSX.Element {
  const { pathname } = useLocation();
  const isCompleteProfile = pathname.includes(COMPLETE_PROFILE_ROUTE);

  return (
    <Field
      component={FormikInput}
      disabled={!isCompleteProfile}
      label="Founded On"
      min={VC_BEGINNING_DATE_STRING}
      max={formatCurrentDate()}
      name={StartupFieldNames.FoundedOn}
      placeholder="mm/dd/yyyy"
      type="date"
      secondaryLabel="The company incorporation date, or the project start date if you are not yet incorporated"
    />
  );
}

const FoundedOnField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [StartupFieldNames.FoundedOn]: yup
      .date()
      .min(
        VC_BEGINNING_DATE,
        `Date must be after ${VC_BEGINNING_DATE.toLocaleDateString()}`,
      )
      .max(new Date(), 'Future dates are not allowed')
      .required('Required'),
  }),
};

export default FoundedOnField;
