import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { StartupGetInvestorListById } from '../../../libs/shared-types/src/constants/ApiRoutes';
import API from '../middleware/API';
import Logger from '../services/logger';
import { HOUR_MS } from '../../../libs/shared-types/src/constants/time';
import { InvestorListItemsPaginatedCollectionResponse } from '../types/view/InvestorListView';
import { removeUndefinedOrNullProperties } from '../util/removeUndefinedOrNullProperties';

export const INVESTOR_LIST_BY_ID_KEY = 'investor_list_by_id';
// Cache time > Stale time
const STALE_TIME_INVESTOR_LIST = HOUR_MS * 4;
const CACHE_TIME_INVESTOR_LIST = STALE_TIME_INVESTOR_LIST * 1.25;

export interface UseGetInvestorListByIdProps {
  id?: string;
  page: string | number | null;
  perPage: string | number | null;
  filter: string | null;
  sortKey: string | null;
  sortOrder: string | null;
  fieldFilters: string | null;
}

const useGetInvestorListById = (props: UseGetInvestorListByIdProps) => {
  const { id, ...params } = props;
  const cleanParam = removeUndefinedOrNullProperties(params);
  return useQuery({
    queryKey: [INVESTOR_LIST_BY_ID_KEY, id, cleanParam],
    queryFn: () => fetchGetInvestorListById({ id, params: cleanParam }),
    staleTime: STALE_TIME_INVESTOR_LIST,
    gcTime: CACHE_TIME_INVESTOR_LIST,
    placeholderData: keepPreviousData,
    // make the request only if there is an id
    enabled: !!id,
  });
};

export default useGetInvestorListById;

export interface FetchGetInvestorListByIdProps {
  id?: string;
  params?: Exclude<
    Partial<{
      [K in keyof UseGetInvestorListByIdProps]:
        | undefined
        | NonNullable<UseGetInvestorListByIdProps[K]>;
    }>,
    'id'
  >;
}

async function fetchGetInvestorListById({
  id,
  params,
}: FetchGetInvestorListByIdProps) {
  try {
    const fieldFilters =
      params?.fieldFilters && JSON.parse(params.fieldFilters);
    const listData =
      await API.get<InvestorListItemsPaginatedCollectionResponse>(
        StartupGetInvestorListById.buildEndpoint(id, {
          ...params,
          fieldFilters,
        }),
      );
    return listData;
  } catch (error: unknown) {
    Logger.error(error);
    if (
      error instanceof Error &&
      error.message === 'Upgrade to a paid subscription to access this feature'
    ) {
      throw new Error('Upgrade to a paid subscription to access this feature');
    } else {
      throw new Error('Please refresh the page and try again.');
    }
  }
}
