import React, { ReactNode } from 'react';
import { BaseMapper } from '/src/util/mapper';
import { ComparableStartupDealViewSessionByConnectionView } from '../../../../types/view/StartupDealViewSessionByConnectionView';
import DurationCell from '/src/components/table/cells/DurationCell';
import LastViewCell from '/src/components/table/cells/LastViewCell';
import {
  DataRoomViewStatus,
  DeckViewed,
} from '../../AccessManager/AccessManagerViewSessionsTable';
import InsightCell from '/src/components/table/cells/InsightCell';
import { StartupAccessManagerViewSessionView } from '../../../../types/view/StartupAccessManagerView';

export interface ViewSessionByConnectionRow
  extends Record<
    keyof ComparableStartupDealViewSessionByConnectionView,
    ReactNode
  > {
  id: string;
}

export class ViewSessionByConnectionMapper extends BaseMapper<
  StartupAccessManagerViewSessionView,
  ViewSessionByConnectionRow
> {
  mapTo(viewSession: StartupAccessManagerViewSessionView) {
    return {
      id: viewSession.sessionId,

      lastViewedOn: <LastViewCell lastViewedOn={viewSession.lastViewedOn} />,

      totalMillisecondsSpentOnDeal: (
        <DurationCell durationInMs={viewSession.totalMillisecondsSpentOnDeal} />
      ),

      deckViewed: (
        <DeckViewed
          deckViewed={viewSession.deckViewed}
          totalMillisecondsSpentOnDeck={
            viewSession.totalMillisecondsSpentOnDeck
          }
          totalDeckDownloadCount={viewSession.totalDeckDownloadCount}
        />
      ),

      dataRoomViewStatus: (
        <DataRoomViewStatus
          dataRoomViewStatus={viewSession.dataRoomViewStatus}
        />
      ),

      insights: (
        <InsightCell
          location={viewSession.location}
          deviceType={viewSession.deviceType}
          deviceOs={viewSession.deviceOs}
          browser={viewSession.browser}
        />
      ),
    };
  }
}
