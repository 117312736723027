import { TooltipSelectOption } from '../types/SelectOptions';

export enum IntroPathRelationshipType {
  LPInFund = 'LP in Fund',
  ExitedPortfolioFounder = 'Exited Portfolio Founder',
  CurrentPortfolioFounder = 'Current Portfolio Founder',
  ConnectedFounder = 'Connected Founder',
  CoInvestor = 'Co-Investor',
  ConnectedInvestor = 'Connected Investor',
  ConnectedPerson = 'Connected Person',
}

export const INTRO_PATH_RELATIONSHIP_TYPE_OPTIONS: TooltipSelectOption[] = [
  {
    value: IntroPathRelationshipType.LPInFund,
    label: IntroPathRelationshipType.LPInFund,
    tooltipText: 'The target investor received investment from this LP',
  },
  {
    value: IntroPathRelationshipType.ExitedPortfolioFounder,
    label: IntroPathRelationshipType.ExitedPortfolioFounder,
    tooltipText:
      'The target investor has invested in this founder and made money',
  },
  {
    value: IntroPathRelationshipType.CurrentPortfolioFounder,
    label: IntroPathRelationshipType.CurrentPortfolioFounder,
    tooltipText:
      "The target investor has invested in this founder but hasn't made money yet",
  },
  {
    value: IntroPathRelationshipType.ConnectedFounder,
    label: IntroPathRelationshipType.ConnectedFounder,
    tooltipText: "The target investor knows but didn't invest in this founder",
  },
  {
    value: IntroPathRelationshipType.CoInvestor,
    label: IntroPathRelationshipType.CoInvestor,
    tooltipText:
      'The target investor previously invested alongside this investor',
  },
  {
    value: IntroPathRelationshipType.ConnectedInvestor,
    label: IntroPathRelationshipType.ConnectedInvestor,
    tooltipText:
      "The target investor knows but didn't invest alongside this investor",
  },
  {
    value: IntroPathRelationshipType.ConnectedPerson,
    label: IntroPathRelationshipType.ConnectedPerson,
    tooltipText:
      "The target investor knows this person but hasn't had any business transaction",
  },
];
