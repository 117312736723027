import React, { Fragment, useEffect, useRef, useState } from 'react';

import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpDownIcon,
} from '@heroicons/react/20/solid';
import { SortOrder } from '/src/interfaces/Sortable';
import { joinClassNames } from '/src/util/formatting/strings';
import { TableHeader } from '/src/interfaces/TableHeader';
import SortIcon from '/src/components/utility/SortIcon';
import { getPluralSuffix } from '/src/util/formatting/numbers';
import usePaginatedUrlsParams from '/src/hooks/usePaginatedUrlParams';
import Logger from '/src/services/logger';
import { PaginatedRequestParams } from '/../libs/shared-types/src/types/view/APIResponse';
import { Listbox, Transition } from '@headlessui/react';
import CurrencyInput from 'react-currency-input-field';
import { range } from '/src/util/range';

export function isRowBlurred(
  pageNumber: number,
  rowIndex: number,
  blurRowsAfterIndex?: number,
) {
  if (blurRowsAfterIndex === undefined) {
    return false;
  }
  return pageNumber > 1 || rowIndex > blurRowsAfterIndex;
}

export interface PaginatedTableProps {
  headers: TableHeader<any>[];
  rowComponents?: JSX.Element[];
  refreshData?: (args: Partial<PaginatedRequestParams<any>>) => Promise<void>;
  parentPage: number;
  parentTotalCount: number;
  parentSortOrder: SortOrder;
  parentSortedColumn: string;
  parentFilter: string;
  parentPerPage: number;
  parentTotalPages: number;
  blurRowsAfterIndex?: number;
  stickyHeaderStyle?: string;
}

function BackendPaginatedTable({
  headers,
  rowComponents,
  refreshData,
  parentPage,
  parentTotalCount,
  parentSortOrder,
  parentSortedColumn,
  parentFilter,
  parentPerPage,
  parentTotalPages,
  stickyHeaderStyle = 'sticky top-0',
}: PaginatedTableProps): JSX.Element {
  const searchRef = useRef<HTMLInputElement>(null);
  const [pageInputDisplayValue, setPageInputDisplayValue] = useState<string>();

  // These setters are to update the URL only
  const {
    page,
    perPage,
    sortOrder,
    sortKey,
    setPage,
    setPerPage,
    setFilter,
    setSortOrder,
    setSortKey,
  } = usePaginatedUrlsParams();

  useEffect(() => {
    setPage(parentPage);
  }, [parentPage]);
  useEffect(() => {
    setPerPage(parentPerPage);
  }, [parentPerPage]);
  useEffect(() => {
    setSortOrder(parentSortOrder);
  }, [parentSortOrder]);
  useEffect(() => {
    setSortKey(parentSortedColumn);
  }, [parentSortedColumn]);
  useEffect(() => {
    setFilter(parentFilter);
  }, [parentFilter]);
  useEffect(() => {
    // Must refresh the page input when page or totalPages change
    setPageInputDisplayValue(parentPage.toString());
  }, [parentTotalPages, parentPage]);

  const defaultedPage = page ?? 1;

  async function handleSort(key: string) {
    try {
      if (refreshData) {
        await refreshData({
          sortKey: key,
          sortOrder: (sortOrder ?? 1) * -1,
        });
      }
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  async function handlePageChange(newPage: number) {
    if (newPage === page) {
      // Already on this page, no need to refresh the data
      return;
    }
    try {
      if (refreshData) {
        await refreshData({ page: newPage });
      }
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  async function handlePerPageChange(newPerPage: number) {
    if (newPerPage === perPage) {
      // PerPage is the same, so no need to refresh the data
      return;
    }
    try {
      if (refreshData) {
        await refreshData({ perPage: newPerPage });
      }
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  const nextPage = () => handlePageChange(defaultedPage + 1);
  const previousPage = () => handlePageChange(defaultedPage - 1);

  useEffect(() => {
    if (rowComponents && searchRef.current) {
      searchRef.current.value = '';
    }
  }, [rowComponents]);

  if (page === undefined || perPage === undefined || !sortKey || !sortOrder) {
    return <></>;
  }

  return (
    <>
      <div className="border-b border-gray-200 bg-gray-50 p-0.5 shadow dark:border-gray-800 dark:bg-gray-900 dark:shadow-gray-800 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <thead
            className={joinClassNames(
              stickyHeaderStyle,
              'z-20 w-full rounded-lg bg-gray-50 dark:bg-gray-900',
            )}
          >
            <tr>
              {headers.map((header) => (
                <th
                  scope="col"
                  key={headers.indexOf(header)}
                  onClick={() =>
                    header.sortKey ? handleSort(header.sortKey) : null
                  }
                  className={joinClassNames(
                    'app-table-header-item group',
                    header.sortKey ? 'cursor-pointer' : '',
                  )}
                >
                  <div
                    className={joinClassNames(
                      header.sortKey && sortKey === header.sortKey
                        ? 'font-bold text-gray-700'
                        : '',
                      'relative inline-flex items-center',
                    )}
                  >
                    {header.element}
                    <span className="absolute -right-6 bottom-0">
                      {header.sortKey && sortKey !== header.sortKey && (
                        <span
                          className={joinClassNames(
                            'ml-2 hidden rounded bg-gray-200 text-gray-500 group-hover:block',
                            header.sortKey ? 'group-hover:opacity-100' : '',
                          )}
                        >
                          <SortIcon
                            order={
                              sortOrder === SortOrder.Asc
                                ? SortOrder.Desc
                                : SortOrder.Asc
                            }
                          />
                        </span>
                      )}
                      {header.sortKey && sortKey === header.sortKey && (
                        <span className="ml-2 rounded bg-gray-200 text-gray-700 group-hover:block">
                          <SortIcon order={sortOrder as SortOrder} />
                        </span>
                      )}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
            {parentTotalCount === 0 && (
              <tr>
                <td
                  colSpan={headers.length}
                  className="w-100 text-center text-sm text-gray-600 dark:text-gray-200"
                >
                  <p className="my-4">No results found</p>
                </td>
              </tr>
            )}
            {rowComponents}
          </tbody>
        </table>

        <nav
          className="flex items-center justify-between border-t border-gray-200 bg-gray-50 pb-1 pl-3 pr-2 pt-2 dark:border-gray-800 dark:bg-gray-900"
          aria-label="Pagination"
        >
          <div className="flex flex-1 items-center justify-start">
            <div className="text-xs dark:text-gray-400">
              <span className="font-medium">{parentTotalCount}</span>
              &nbsp;result{getPluralSuffix(parentTotalCount)},&nbsp;
            </div>

            <Listbox value={perPage} onChange={handlePerPageChange}>
              <div className="relative cursor-pointer">
                <Listbox.Button className="relative mr-5 w-full cursor-default rounded-md px-2.5 py-1.5 text-left text-xs hover:bg-gray-200 focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300">
                  {perPage} per page
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-4 w-4 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute -top-44 z-50 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {range(0, 50, 10)
                      .reverse()
                      .map(
                        (perPageSize) =>
                          perPageSize !== 0 && (
                            <Listbox.Option
                              key={perPageSize}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-8 pr-4 ${active
                                  ? 'bg-blue-100 text-blue-900'
                                  : 'text-gray-900'
                                }`
                              }
                              value={perPageSize}
                            >
                              {perPageSize}
                            </Listbox.Option>
                          ),
                      )}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
          <div className="flex flex-1 items-center justify-end">
            <button
              onClick={() => handlePageChange(1)}
              type="button"
              disabled={page === 1}
              className="relative mx-1 inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-200 dark:hover:bg-gray-800"
              title="First page"
            >
              <span className="sr-only">First</span>
              <ChevronDoubleLeftIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </button>
            <button
              onClick={previousPage}
              type="button"
              disabled={page === 1}
              className="relative mx-1 inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-200 dark:hover:bg-gray-800"
              title="Previous page"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </button>

            <label className="mx-2 flex items-center text-xs">
              <span>Page&nbsp;</span>
              <CurrencyInput
                className="m-0 h-6 w-10 rounded border border-gray-300 py-0 pl-2 text-xs"
                value={pageInputDisplayValue}
                // Disabling step because the arrow buttons
                // allow to spam the change Event even after I tried debounce
                // step={1}
                allowNegativeValue={false}
                allowDecimals={false}
                disableAbbreviations
                onValueChange={(value) => {
                  if (value === null || value === undefined || value === '') {
                    setPageInputDisplayValue('');
                    return;
                  }

                  // The page value cannot be greater than TotalPages
                  const numericalValue = Math.min(
                    Number(value),
                    parentTotalPages,
                  );
                  if (!isNaN(numericalValue) && numericalValue !== 0) {
                    setPageInputDisplayValue(numericalValue.toString());
                    handlePageChange(numericalValue);
                  }
                }}
              />
              <span>&nbsp;of {parentTotalPages}</span>
            </label>
            <button
              onClick={nextPage}
              type="button"
              disabled={page >= parentTotalPages}
              className="relative mx-1 inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-200 dark:hover:bg-gray-800"
              title="Next page"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </button>
            <button
              onClick={() => handlePageChange(parentTotalPages)}
              type="button"
              disabled={page === parentTotalPages}
              className="relative mx-1 inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-200 dark:hover:bg-gray-800"
              title="Last page"
            >
              <span className="sr-only">Last</span>
              <ChevronDoubleRightIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </button>
          </div>
        </nav>
      </div>
    </>
  );
}

export default BackendPaginatedTable;
