import React from 'react';
import DeviceTypeIcon from '../../utility/RenderIconFromValue/DeviceTypeIcon';
import DeviceOsIcon from '../../utility/RenderIconFromValue/DeviceOsIcon';
import BrowserIcon from '../../utility/RenderIconFromValue/BrowserIcon';

export interface InsightCellProps {
  location: string;
  deviceType: string;
  deviceOs: string;
  browser: string;
}

function InsightCell({
  location,
  deviceType,
  deviceOs,
  browser,
}: InsightCellProps) {
  return (
    <>
      <div className="app-table-row-text">{location}</div>
      <div className="app-table-row-text--light flex items-center space-x-2">
        <DeviceTypeIcon className="h-5 w-5" deviceType={deviceType} />
        <DeviceOsIcon className="h-4 w-4" deviceOs={deviceOs} />
        <BrowserIcon className="h-4 w-4" browser={browser} />
      </div>
    </>
  );
}

export default InsightCell;
