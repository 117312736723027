import React from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { formatCurrentDate } from '/src/util/formatting/dates';
import FormField from '/src/interfaces/FormField';
import FormikInput from '../../inputs/FormikInput';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import { capitalize, isBlank } from '/src/util/formatting/strings';
import {
  VC_BEGINNING_DATE,
  VC_BEGINNING_DATE_STRING,
} from '/../libs/shared-types/src/constants/DateRanges';

function FieldWrapper(): JSX.Element {
  return (
    <Field
      component={FormikInput}
      label="When did you join the firm?"
      min={VC_BEGINNING_DATE_STRING}
      max={formatCurrentDate()}
      name={InvestorFieldNames.FirmJoinedOn}
      placeholder="mm/dd/yyyy"
      type="date"
    />
  );
}

const FirmJoinedOnField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [InvestorFieldNames.FirmJoinedOn]: yup
      .date()
      .min(
        VC_BEGINNING_DATE,
        `Date must be after ${VC_BEGINNING_DATE.toLocaleDateString()}`,
      )
      .max(new Date(), 'Future dates are not allowed')
      .test({
        name: 'firmMustBeSet',
        message: `You must add a ${capitalize(InvestorFieldNames.Firm)} too`,
        test(value) {
          if (value && isBlank(this.parent[InvestorFieldNames.Firm])) {
            return false;
          }
          return true;
        },
      })
      .nullable(),
  }),
};

export default FirmJoinedOnField;
