import React from 'react';
import { BriefcaseIcon, MapPinIcon } from '@heroicons/react/24/outline';

import { formatImageAddress } from '/src/util/formatting/strings';
import {
  HiddenFitScore,
  generateFitScoreTag,
} from '/src/util/generateTagCloud';
import FlagEmoji from '/src/components/utility/FlagEmoji';
import { PublicInvestor } from '/../libs/shared-types/src/types/view/investor/PublicInvestor';
import { InvestorDetailView } from '/../libs/shared-types/src/types/view/InvestorDetailView';
import UserAvatarWithInitials from '/src/components/UserAvatarWithInitials';
import VerifiedInvestorBadge from '/src/components/Badges/VerifiedInvestorBadge';
import NavigationBackButton from '/src/components/utility/NavigationBackButton';
import InvestorPipelineStageSelector from '../../startup/RoundManager/InvestorPipeline/InvestorPipelineStageSelector';
import { StartupInvestorPipelineUpdateInvestor } from '/../libs/shared-types/src/constants/ApiRoutes';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';

function getFlagEmojiMargin(flagIndex: number): string {
  if (flagIndex === 0) {
    return '-mt-10';
  }

  if (flagIndex === 1) {
    return '-mt-[2.3rem]';
  }

  if (flagIndex === 2) {
    return '-mt-9';
  }

  return '';
}

interface InvestorDetailHeaderProps {
  investor: PublicInvestor | InvestorDetailView;
  hasFitScoreAccess?: boolean;
  fetchInvestor?: (isRefreshing: boolean) => Promise<void>;
}

function InvestorDetailHeader({
  investor,
  fetchInvestor,
  hasFitScoreAccess = false,
}: InvestorDetailHeaderProps): JSX.Element {
  // Since PublicInvestor does not contain this property, we must handle the type check
  const investorFitScore =
    'fitScore' in investor && typeof investor.fitScore === 'number'
      ? investor.fitScore
      : undefined;

  async function updateInvestorStage(stageName: string) {
    if (
      'pipelineCurrentStage' in investor &&
      investor.pipelineCurrentStage &&
      investor.pipelineCurrentStage.name === stageName
    ) {
      return;
    }

    try {
      await API.put(StartupInvestorPipelineUpdateInvestor.buildEndpoint(), {
        investorId: investor._id,
        investorDataType: (investor as any).investorDataType,
        update: {
          currentStageName: stageName,
        },
      });

      if (fetchInvestor !== undefined) {
        await fetchInvestor(true);
      }
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  return investor !== undefined ? (
    <section>
      <NavigationBackButton className="-mt-4 mb-2" />

      <div className="mx-auto">
        <div className="sm:flex sm:items-center sm:space-x-5">
          <figure className="relative w-fit">
            <UserAvatarWithInitials
              containerStyles="h-24 sm:h-28 w-24 sm:w-28"
              firstName={investor.firstName}
              lastName={investor.lastName}
              textStyles="text-4xl"
              imgAlt="Investor Profile"
              imgSrc={formatImageAddress(investor.profilePicKey)}
            />
            {investor.nationalities && (
              <span className="absolute -bottom-5 right-0 flex flex-row -space-x-3">
                {investor.nationalities.map((countryCode, index) => (
                  <FlagEmoji
                    key={countryCode}
                    className={`mx-1 h-7 w-7 ${getFlagEmojiMargin(index)}`}
                    countryCode={countryCode}
                  />
                ))}
              </span>
            )}
          </figure>
          <div className="sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
            <div className="block min-w-0 flex-1 space-y-1">
              <h1 className="flex items-center">
                <span className="truncate text-2xl font-bold leading-tight text-gray-900">
                  {`${investor.firstName} ${investor.lastName}`}
                </span>
                {investor.isVerified && <VerifiedInvestorBadge />}
                {investor.pronouns && (
                  <span className="ml-2">{`(${investor.pronouns})`}</span>
                )}
              </h1>

              <div className="flex items-center">
                <BriefcaseIcon className="h-5 w-5 text-gray-600" />
                <p className="truncate pl-1 font-medium text-gray-700">
                  {investor.role && investor.firm
                    ? `${investor.role} @ ${investor.firm}`
                    : 'Angel Investor'}
                </p>
              </div>

              {investor.location && (
                <div className="flex items-center">
                  <MapPinIcon className="h-5 w-5 text-gray-600" />
                  <p className="truncate pl-1 font-medium text-gray-600">
                    {investor.location}
                  </p>
                </div>
              )}

              <div className="flex items-center space-x-3">
                {investorFitScore !== undefined &&
                  hasFitScoreAccess &&
                  generateFitScoreTag(investorFitScore, true)}
                {investorFitScore !== undefined && !hasFitScoreAccess && (
                  <HiddenFitScore />
                )}

                {'pipelineCurrentStage' in investor &&
                  investor.pipelineCurrentStage && (
                    <InvestorPipelineStageSelector
                      currentStageStatus={investor.pipelineCurrentStage}
                      onStageSelected={(stageName: string) =>
                        updateInvestorStage(stageName)
                      }
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <></>
  );
}

export default InvestorDetailHeader;
