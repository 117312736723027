import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { LINKSHARE_CONSUME_ERROR } from '/src/constants/ErrorMessages';
import {
  removeVisitorNameAndEmail,
  removeVisitorSessionToken,
} from '/src/middleware/SessionStorage';
import { SharedStateContext } from '/src/contexts/SharedStateContext';
import { SUCCESS } from '/src/constants/SuccessMessages';
import { ToastConfiguration } from '/src/interfaces/ToastConfiguration';
import AppRoutes from './AppRoutes';
import LinkShareWrapper from '/src/components/utility/LinkShareWrapper';
import Navigation from '/src/components/layouts/navigation/SidebarNavigation';
import Toast from '/src/components/notifications/Toast';
import { InvestorRequests } from '/src/contexts/InvestorRequestsContext';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import { NewInvestorFirstLogInTour } from '/src/components/UserActivation/NewInvestorFirstLoginTour';
import { NewStartupFirstLogInTour } from '/src/components/UserActivation/NewStartupFirstLoginTour';
import { localStorageGetAuthenticatedAccountType } from '/src/middleware/LocalStorage';
import {
  AccountMetadataContext,
  AccountMetadataProvider,
} from '/src/contexts/AccountMetadataContext';

function PageTitle(): JSX.Element {
  const { flowliePartner } = useContext(AccountMetadataContext);

  const parterText =
    flowliePartner && flowliePartner.name ? `by ${flowliePartner.name}` : '';

  return (
    <Helmet>
      <title>Flowlie | App {parterText}</title>
    </Helmet>
  );
}

function AppEntryPoint(): JSX.Element {
  const {
    cachedPage,
    setCachedPage,
    toastConfiguration,
    setToastConfiguration,
  } = useContext(SharedStateContext);
  const [toastData, setToastData] = useState<ToastConfiguration>();
  const [isToastShown, setIsToastShown] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  function redirectToCachedPage() {
    if (cachedPage && cachedPage.url !== '') {
      const userAccountType = localStorageGetAuthenticatedAccountType();
      if (
        cachedPage.accountType === null ||
        cachedPage.accountType === userAccountType
      ) {
        setCachedPage(null);
        navigate(cachedPage.url);
      } else {
        // if there is some issue with the account type
        // (as in it doesn't match the currently logged in user's account type)
        // then we just clean the cached page data
        setCachedPage(null);
      }
    }
  }

  function displayGlobalToast() {
    if (toastConfiguration !== null) {
      // we only want to display toasts with non empty messages
      if (toastConfiguration?.message !== '') {
        setToastData(toastConfiguration);
        setIsToastShown(true);
        window.setTimeout(() => setIsToastShown(false), 8000);
      }

      // we will always reset the toast config data though
      setToastConfiguration(null);
    }
  }

  useEffect(() => {
    if (toastConfiguration !== null) {
      setToastData(undefined);
    }

    displayGlobalToast();
  }, [toastConfiguration, location]);

  useEffect(() => {
    redirectToCachedPage();
  }, [cachedPage]);

  useEffect(() => {
    // Clean up session storage of visitor data
    removeVisitorNameAndEmail();
    removeVisitorSessionToken();
  }, []);

  return (
    <>
      {localStorageGetAuthenticatedAccountType() === AccountTypes.Investor && (
        <NewInvestorFirstLogInTour />
      )}
      {localStorageGetAuthenticatedAccountType() === AccountTypes.Startup && (
        <NewStartupFirstLogInTour />
      )}
      <AccountMetadataProvider>
        <InvestorRequests>
          <Navigation>
            <PageTitle />
            <LinkShareWrapper>
              <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none">
                <div className="mx-4 sm:px-6 md:px-8">{AppRoutes}</div>
              </main>
            </LinkShareWrapper>
          </Navigation>
        </InvestorRequests>
      </AccountMetadataProvider>
      {toastData && (
        <Toast
          isShown={isToastShown}
          onClose={() => setIsToastShown(false)}
          title={toastData.isError ? LINKSHARE_CONSUME_ERROR : SUCCESS}
          isError={toastData.isError}
          text={toastData.message}
        />
      )}
    </>
  );
}

export default AppEntryPoint;
