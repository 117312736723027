import React from 'react';
import useUrlParams from '../hooks/useUrlParams';

// TODO: This must require the Generic type to be passed in
// To specify the type of the fieldFilters
export interface FieldFilterWrapperProps {
  children: (
    updateParams: (newFieldFilterParam: Record<string, any>) => void,
  ) => React.ReactNode;
}

function FieldFilterWrapper({ children }: FieldFilterWrapperProps) {
  const { setParam } = useUrlParams();

  const updateParams = (newFieldFilterParam: Record<string, any>) => {
    setParam({
      fieldFilters: newFieldFilterParam,
      page: 1,
    });
  };

  return children(updateParams);
}

export default FieldFilterWrapper;
