import React, { useEffect, useRef } from 'react';
import TablePagination, {
  TablePaginationProps,
} from '../../pagination/TablePagination';
import BaseTable, { BaseTableProps } from './BaseTable';
import { PaginatedRequestParams } from '../../../types/view/APIResponse';
import { SortOrder } from '/src/interfaces/Sortable';
import useUrlParams from '../../../hooks/useUrlParams';

interface BasePaginatedTableProps<ISortKeyType, IFieldFilters = any>
  extends BaseTableProps,
    TablePaginationProps {
  requestParams: PaginatedRequestParams<ISortKeyType, IFieldFilters>;
}

function BasePaginatedTable<ISortKeyType, IFieldFilters>({
  enableSelect,
  enableSort,
  headerStructure,
  onPageChange,
  onPerPageChange,
  onRowClick,
  onSelectedChange,
  onSortChange,
  page,
  pageCount,
  perPage,
  resetSelectedTrigger,
  resultCount,
  rows,
  tableName,
  requestParams,
  headerClassName,
}: BasePaginatedTableProps<ISortKeyType, IFieldFilters>) {
  const searchRef = useRef<HTMLInputElement>(null);
  const { setParam } = useUrlParams();

  const handlePageChange = (newPage: number) => {
    setParam({
      page: newPage,
    });
    if (onPageChange) {
      onPageChange(newPage);
    }
  };

  const handlePerPageChange = (newPerPage: number) => {
    setParam({
      perPage: newPerPage,
    });
    if (onPerPageChange) {
      onPerPageChange(newPerPage);
    }
  };

  const handleSortOrderChange = ({
    headerKey,
    label,
    headerIndex,
    sort,
  }: {
    headerKey: string;
    label: string;
    headerIndex: number;
    sort: SortOrder | null;
  }) => {
    let newSortParams: Record<string, string | number> = { sortKey: headerKey };
    if (sort) {
      newSortParams = { ...newSortParams, sortOrder: sort };
      if (onSortChange) {
        onSortChange({ headerKey, label, headerIndex, sort });
      }
    }
    setParam(newSortParams);
  };

  useEffect(() => {
    if (rows && searchRef.current) {
      searchRef.current.value = '';
    }
  }, [rows]);

  return (
    <div className="w-full border-b border-gray-200 bg-gray-50 p-0.5 shadow dark:border-gray-800 dark:bg-gray-900 dark:shadow-gray-800 sm:rounded-lg">
      <BaseTable
        key={`${String(requestParams.sortKey)}-${requestParams.sortOrder}`}
        enableSelect={enableSelect}
        enableSort={enableSort}
        headerStructure={headerStructure.map((header) => {
          if (header.headerKey === requestParams.sortKey) {
            return {
              ...header,
              isDefaultSortKey: true,
              defaultSortOrder: requestParams.sortOrder,
            };
          }
          return header;
        })}
        onRowClick={onRowClick}
        onSelectedChange={onSelectedChange}
        onSortChange={handleSortOrderChange}
        resetSelectedTrigger={resetSelectedTrigger}
        rows={rows}
        tableName={tableName}
        headerClassName={headerClassName}
      />

      <TablePagination
        // key={`${sortKey}-${sortOrder}`}
        resultCount={resultCount}
        perPage={requestParams.perPage ?? 10}
        page={requestParams.page ?? 1}
        pageCount={pageCount}
        onPageChange={handlePageChange}
        onPerPageChange={handlePerPageChange}
      />
    </div>
  );
}

export default BasePaginatedTable;
