import React from 'react';
import DebugRender from '../DebugRender';
import {
  ChromeBrowserIcon,
  EdgeBrowserIcon,
  FirefoxBrowserIcon,
  InternetExplorerBrowserIcon,
  OperaBrowserIcon,
  SafariBrowserIcon,
} from '../../CustomIcons';
import { UA_BrowserNames } from '/../libs/shared-types/src/constants/UaParserValues';

interface BrowserIconProps {
  className?: string;
  browser: string | undefined;
}

export function BrowserIcon({
  className = 'h-4 w-4',
  browser,
}: BrowserIconProps) {
  if (!browser) {
    return <></>;
  }

  switch (browser) {
    case UA_BrowserNames.Chrome:
    case UA_BrowserNames.ChromeHeadless:
    case UA_BrowserNames.ChromeWebView:
    case UA_BrowserNames.Chromium:
    case UA_BrowserNames.MobileChrome:
      return <ChromeBrowserIcon className={className} />;
    case UA_BrowserNames.Safari:
    case UA_BrowserNames.MobileSafari:
      return <SafariBrowserIcon className={className} />;
    case UA_BrowserNames.Firefox:
    case UA_BrowserNames.FirefoxFocus:
    case UA_BrowserNames.FirefoxReality:
    case UA_BrowserNames.MobileFirefox:
    case UA_BrowserNames.Mozilla:
      return <FirefoxBrowserIcon className={className} />;
    case UA_BrowserNames.Edge:
      return <EdgeBrowserIcon className={className} />;
    case UA_BrowserNames.IE:
    case UA_BrowserNames.IEMobile:
      return <InternetExplorerBrowserIcon className={className} />;
    case UA_BrowserNames.Opera:
    case UA_BrowserNames.OperaCoast:
    case UA_BrowserNames.OperaMini:
    case UA_BrowserNames.OperaMobi:
    case UA_BrowserNames.OperaTablet:
      return <OperaBrowserIcon className={className} />;
    default:
      return (
        <DebugRender>
          <span>{browser}</span>
        </DebugRender>
      );
  }
}

export default BrowserIcon;
