import React from 'react';

export interface CellOptionalTextProps {
  text?: string | null;
}

function CellOptionalText({ text }: CellOptionalTextProps) {
  return <div className="app-table-row-text">{text || '-'}</div>;
}

export default CellOptionalText;
