export enum ChangeStatType {
  Increase,
  Stable,
  Decrease,
}

export function valueToChangeStatType(value: number): ChangeStatType {
  if (value > 0) {
    return ChangeStatType.Increase;
  } else if (value < 0) {
    return ChangeStatType.Decrease;
  } else {
    return ChangeStatType.Stable;
  }
}

export interface ChangeStats {
  count: number;
  change: number;
  type: ChangeStatType;
  periodMs: number;
}
