import React, { useContext, useEffect, useRef, useState } from 'react';
import { IntroPathPaginatedCollectionResponse } from '../types/view/IntroPathView';
import { AccountMetadataContext } from '../contexts/AccountMetadataContext';
import useUrlParams from './useUrlParams';
import { SubscriptionTiers } from '../../../libs/shared-types/src/constants/SubscriptionTiers';
import { removeUndefinedOrNullProperties } from '../util/removeUndefinedOrNullProperties';
import { StartupGetIntroPaths } from '../../../libs/shared-types/src/constants/ApiRoutes';
import Logger from '../services/logger';
import API from '../middleware/API';
import { ConnectionDataType } from '../constants/ConnectionDataType';
import { SortOrder } from '../../../libs/shared-types/src/constants/SortOrder';

export interface UseGetInvestorIntroPathsProps {
  connectionId?: string;
  connectionDataType: ConnectionDataType;
}

const useGetInvestorIntroPaths = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [introPaths, setIntroPaths] =
    useState<IntroPathPaginatedCollectionResponse>();
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const isFirstLoading = useRef<boolean>(true);
  const [isRefetching, setIsRefetching] = useState(false);

  const { getParam } = useUrlParams();
  const page = getParam('page');
  const perPage = getParam('perPage');
  const sortKey = getParam('sortKey');
  const sortOrder = getParam('sortOrder');
  const filter = getParam('filter');

  function refetch() {
    setIsRefetching((prev) => !prev);
  }

  useEffect(() => {
    async function fetchIntroPaths(args: Partial<any>): Promise<void> {
      if (subscriptionTier === SubscriptionTiers.StartupFree) {
        return;
      }
      if (isFirstLoading.current) {
        setIsLoading(true);
        isFirstLoading.current = false;
      }

      try {
        const cleanParam = removeUndefinedOrNullProperties(args);
        const towardConnectionResponse =
          await API.get<IntroPathPaginatedCollectionResponse>(
            StartupGetIntroPaths.buildEndpoint('', {
              ...cleanParam,
            }),
          );

        setIntroPaths(towardConnectionResponse);
      } catch (error: any) {
        Logger.error(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchIntroPaths({
      page,
      perPage,
      sortKey: sortKey ?? 'impactScore',
      sortOrder: sortOrder ?? SortOrder.Desc,
      filter,
    });
    return () => {
      isFirstLoading.current = false;
    };
  }, [page, perPage, sortKey, sortOrder, isRefetching, filter]);
  return {
    isLoading,
    introPaths,
    refetch,
  };
};

export default useGetInvestorIntroPaths;
