import { FlowLink } from '../../../types/model/FlowLink';
import RoundView from '../../../types/view/RoundView';
import { InternalStartup } from '../../../types/view/startup/InternalStartup';
import { MIN_DATE } from '../../MinimumDate';
import { PublicProfilePrivacySetting } from '../../PublicProfilePrivacySetting';
import { RoundStages } from '../../RoundStages';
import { RoundTypes } from '../../RoundTypes';
import { ValuationCapType } from '../../ValuationCapType';
import { DAY_MS } from '../../time';

const EXAMPLE_FLOW_LINK: FlowLink = {
  _id: '627828e081f8975a14caa968',
  createdOn: new Date(),
  disabledOn: MIN_DATE,
  excludedFromOnePager: [],
  privacySetting: PublicProfilePrivacySetting.RequireNameAndEmail,
  slug: 'sample-slug',
  updatedOn: new Date(),
};

export const EXAMPLE_CURRENT_ROUND: RoundView = {
  _id: '627828e081f8975a14caa968',
  closedOn: new Date(MIN_DATE),
  // Estimated close date a few days from now
  estimatedCloseDate: new Date(Date.now() + 3 * DAY_MS),
  gracePeriodEndsOn: new Date(MIN_DATE),
  isBridge: false,
  isDownRound: false,
  isExtension: false,
  isOpen: true,
  isInGracePeriod: false,
  modifiedOn: new Date('2023-01-21T21:33:35.902Z'),
  openedOn: new Date('2022-05-08T20:32:32.397Z'),
  raisedAmount: 165000,
  raiseTarget: 600000,
  roundStage: RoundStages.PreSeed,
  roundDisplayName: 'Pre-Seed',
  roundType: RoundTypes.Safe,
  estimatedPostCap: 6000000,
  discount: 20,
  hasLeadInvestor: false,
  hasQsbsPotential: false,
  hasTermSheet: false,
  runwayTarget: 12,
  valuationCap: 6000000,
  valuationCapType: ValuationCapType.PostMoney,
};

export const EXAMPLE_STARTUP: InternalStartup = {
  _id: '63e1e5be0f8e91f835b87ac7',
  accountEmail: 'instamart@test.com',
  accountFirstName: 'James',
  accountLastName: 'Mart',
  accountPhone: '+11234567890',
  additionalTractionInfo:
    'Began negotiations with Amazon to acquire the Alexa division to bolster our in store virtual assistants.',
  avgOrderValue: 1000,
  avgRevenuePerUser: 67,
  avgTakeRate: 0.06,
  bookingsAmount: 56456,
  businessModels: ['B2C'],
  companyType: 'Operating Company',
  createdOn: new Date('2022-04-07T23:31:53.850Z'),
  currentStage: RoundStages.PreSeed,
  customerAcquisitionCost: 56,
  customerGrowthRateMom: 3,
  customerRetentionRateMom: 4,
  dailyActiveUserCount: 1,
  dataRoom: {
    checklist: [],
  },
  decks: [
    {
      _id: '62c0b598537d8b7fbc992634',
      changeReason: 'Improved competitive analysis',
      createdOn: new Date('2023-01-16T01:41:41.347Z'),
      fileName: 'example_deck_v1.1.pdf',
      key: 'example_deck.pdf',
      pageCount: 12,
      version: { major: 1, minor: 1 },
    },
  ],
  description:
    'We provide an end-to-end solution to digitalize mini-markets and manage inventory with AI. Our platform lets you deploy a store in minutes, and with our prioprietary ML model you can predict demand and adjust prices based on the market conditions.',
  employeesCount: '11-25',
  flowLink: {
    excludedFromOnePager: EXAMPLE_FLOW_LINK.excludedFromOnePager,
    privacySetting: EXAMPLE_FLOW_LINK.privacySetting,
  },
  foundedOn: new Date('2021-11-03T04:00:00.000Z'),
  founders: [
    {
      _id: '62c0b598537d8b7fbc992634',
      firstName: 'James',
      lastName: 'Mart',
      email: 'instamart@test.com',
      role: 'Founder',
      createdOn: new Date('2022-07-02T21:16:08.351Z'),
      updatedOn: new Date('2022-07-02T21:16:08.351Z'),
      highestEducationLevel: "Bachelor's degree",
      isTechnical: false,
      nationalities: ['IE', 'US'],
      universityAffiliations: ['Massachusetts Institute of Technology'],
      yearsOfIndustryExperience: 12,
    },
  ],
  geography: 'Tucson, AZ',
  grossMargin: 100,
  hasCustomers: true,
  hasIncubator: true,
  hasPendingLawsuits: false,
  hasPreviousBankruptcies: false,
  hasPreviousConvictions: false,
  hasUsers: true,
  impacts: ['None'],
  incubators: ['ODX', 'Y Combinator'],
  industries: ['E-Commerce & Marketplaces', 'Data Analytics & AI/ML'],
  invitedBy: {
    accountId: '6106d7ac7fa1eb48aca1c26d',
    isInvestor: true,
  },
  ipProtections: ['Copyrights'],
  legalEntityType: 'C-Corp',
  legalFormationState: 'Delaware',
  legalName: 'Instamart, Inc.',
  lifeTimeValue: 9,
  logoKey: 'instamart_logo.png',
  monthlyActiveUserCount: 10,
  name: 'InstaMart',
  netMargin: 5,
  netMonthlyBurn: 0,
  netPromoterScore: 4,
  netRevenueLastYear: 0,
  netRevenueYTD: 0,
  netRevenueRetention: 3,
  pilotsCount: 51,
  primaryGrowthDriver: 'Marketing-led',
  productDemoVideoUrl: 'https://example.com',
  productStatus: 'Private Beta',
  revenue: 60,
  revenueGrowth: 12,
  revenueMonthlyGmv: 1000,
  revenueType: 'Transactional',
  roundHistory: [
    EXAMPLE_CURRENT_ROUND,
    {
      _id: '6252144beed006d89113afe6',
      closedOn: new Date('2022-04-10T20:20:13.321Z'),
      estimatedCloseDate: new Date('-271821-04-20T00:00:00.000Z'),
      gracePeriodEndsOn: new Date('2022-04-25T00:00:00.000Z'),
      isBridge: false,
      isDownRound: false,
      isExtension: false,
      isInGracePeriod: false,
      isOpen: false,
      modifiedOn: new Date('2022-04-10T19:23:00.232Z'),
      openedOn: new Date('2022-04-09T23:18:35.519Z'),
      raisedAmount: 102000,
      raiseTarget: 100000,
      roundStage: RoundStages.Angel,
      roundDisplayName: 'Angel',
      roundType: RoundTypes.PricedCommon,
      estimatedPostCap: 1000000,
      hasLeadInvestor: false,
      hasQsbsPotential: true,
      hasTermSheet: true,
      valuationCap: 1000000,
      valuationCapType: ValuationCapType.PostMoney,
    },
  ],
  runway: 9,
  teamPresentationVideoUrl: 'https://youtu.be/TAoMg2Gsb2E',
  totalAddressableMarket: 20000000000,
  userCount: 123,
  userGrowthRateMom: 32,
  userRetentionRateMom: 12,
  website: 'https://instamart.co/',
  weeklyActiveUserCount: 8,
};
