import React from 'react';
import CopyTextButton from '/src/components/CopyTextButton';
import SocialHandles, {
  SocialHandlesProps,
} from '/src/components/SocialHandles';
import UserAvatarWithInitials from '/src/components/UserAvatarWithInitials';
import {
  formatImageAddress,
  splitOnWhiteSpace,
} from '/src/util/formatting/strings';
import ConnectedOnLinkedInBadge from '../../ConnectedOnLinkedInBadge';

export interface CellIntroductionProps {
  email?: string;
  name: string;
  subtitle?: string;
  image?: string;
  socialUrls?: SocialHandlesProps['socialUrls'];
  enableCopy?: boolean;
  isConnectedOnLinkedIn?: boolean;
}

function CellIntroduction({
  email,
  name,
  subtitle,
  image,
  socialUrls,
  enableCopy = true,
  isConnectedOnLinkedIn,
}: CellIntroductionProps) {
  return (
    <div className="flex items-center">
      <div className="h-10 w-10 shrink-0">
        <UserAvatarWithInitials
          containerStyles="h-10 w-10"
          firstName={splitOnWhiteSpace(name)[0]}
          lastName={splitOnWhiteSpace(name)[1]}
          textStyles="text-xl"
          imgAlt="Investor Profile"
          imgSrc={formatImageAddress(image)}
        />
      </div>
      <div className="ml-2">
        <div className="app-table-row-text--bold flex items-center space-x-2">
          <span>{name}</span>
          <ConnectedOnLinkedInBadge
            isConnectedOnLinkedIn={isConnectedOnLinkedIn}
          />
          {socialUrls !== undefined && (
            <span className="-mt-1.5">
              <SocialHandles socialUrls={socialUrls} />
            </span>
          )}
        </div>
        {email && (
          <div className="text-sm text-gray-500">
            <a
              className="truncate hover:underline"
              href={`mailto:${email}`}
              onClick={(e) => e.stopPropagation()}
            >
              {email}
            </a>
            {enableCopy && (
              <CopyTextButton text={email} className="absolute ml-1" />
            )}
          </div>
        )}
        {subtitle && <div className="text-sm text-gray-500">{subtitle}</div>}
      </div>
    </div>
  );
}

export default CellIntroduction;
