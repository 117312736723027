import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '../../inputs/CustomSelect';
import { enumToSelectOptions } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';
import { InvestmentAuthority } from '/../libs/shared-types/src/constants/InvestmentAuthority';
import InvestorTypes from '/../libs/shared-types/src/constants/InvestorTypes';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  return values[InvestorFieldNames.InvAuthority] ||
    values[InvestorFieldNames.Type] !== InvestorTypes.Angel ? (
    <Field
      className="custom-select"
      component={CustomSelect}
      closeMenuOnSelect
      isClearable
      label="What is your investment authority at the firm?"
      name={InvestorFieldNames.InvAuthority}
      options={enumToSelectOptions(InvestmentAuthority)}
      placeholder="Select..."
    />
  ) : (
    <></>
  );
}

const InvAuthorityField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [InvestorFieldNames.InvAuthority]: yup
      .string()
      .nullable()
      .test({
        name: 'invAuthorityDoesNotApplyToAngels',
        message: `Angel investors cannot have an investment authority`,
        test(value) {
          if (
            value &&
            this.parent[InvestorFieldNames.Type] === InvestorTypes.Angel
          ) {
            return false;
          }
          return true;
        },
      }),
  }),
};

export default InvAuthorityField;
