import React from 'react';
import BasicStatistic from '/src/components/cards/BasicStatistic';
import { InvestorPipelineSystemStages } from '/../libs/shared-types/src/constants/InvestorPipelineSystemStages';
import { InvestorPipelineViewStatsSummary } from '../../../../types/view/InvestorPipelineView';

interface InvestorPipelineKpiCardsProps {
  statsSummary: InvestorPipelineViewStatsSummary;
}

function InvestorPipelineKpiCards({
  statsSummary,
}: InvestorPipelineKpiCardsProps): JSX.Element {
  if (statsSummary.totalLeads.count === 0) {
    return <></>;
  }
  return (
    <section className="mb-4 grid w-full grid-cols-2 gap-5 lg:grid-cols-4">
      <BasicStatistic
        label="Total Leads"
        statistic={statsSummary.totalLeads.count}
        changeStat={statsSummary.totalLeads}
        tooltip="Total number of investors in the pipeline."
      />
      <BasicStatistic
        label="Active Leads"
        statistic={statsSummary.activeLeads.count}
        suffix={`(${((statsSummary.activeLeads.count / statsSummary.totalLeads.count) * 100).toFixed(0)}%)`}
        changeStat={statsSummary.activeLeads}
        tooltip="Investors who you have reached out to and are moving through the pipeline."
      />
      <BasicStatistic
        label="Invested"
        statistic={statsSummary.invested.count}
        suffix={`(${((statsSummary.invested.count / statsSummary.totalLeads.count) * 100).toFixed(0)}%)`}
        changeStat={statsSummary.invested}
        tooltip={`Investors who are in the ${InvestorPipelineSystemStages.Wired} stage`}
      />
      <BasicStatistic
        label="Not Invested"
        statistic={statsSummary.notInvested.count}
        suffix={`(${((statsSummary.notInvested.count / statsSummary.totalLeads.count) * 100).toFixed(0)}%)`}
        changeStat={statsSummary.notInvested}
        tooltip={`Investors who are in the ${InvestorPipelineSystemStages.NotInvested} stage`}
      />
    </section>
  );
}

export default InvestorPipelineKpiCards;
