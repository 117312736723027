import React, { useContext, useState } from 'react';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import { UserPlusIcon } from '@heroicons/react/24/outline';
import { UserPlusIcon as UserPlusIconSolid } from '@heroicons/react/24/solid';
import EmptyState from '/src/components/notifications/EmptyState';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import { SubscriptionTiers } from '/../libs/shared-types/src/constants/SubscriptionTiers';
import { SubscriptionCTAPill } from '/src/components/SubscriptionCTA';
import SearchBar from '/src/components/inputs/SearchBar';
import { Person } from '/../libs/shared-types/src/types/model/Person';
import { StartupDeletePerson } from '/../libs/shared-types/src/constants/ApiRoutes';
import API from '/src/middleware/API';
import SimpleDialog from '/src/components/notifications/SimpleDialog';
import { ToastConfiguration } from '/src/interfaces/ToastConfiguration';
import Toast from '/src/components/notifications/Toast';
import { ERROR, SUCCESS } from '/src/constants/SuccessMessages';
import {
  PersonsPaginatedCollectionResponse,
  PersonView,
} from '../../../types/view/PersonView';
import BasePaginatedTable from '/src/components/table/baseTable/BasePaginatedTable';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { PERSON_ACTION, PersonMapper } from './PersonMapper';
import ImportPersonDialog from './ImportPersonDialog';
import UpdateStartupRelationshipDialog from '../RoundManager/InvestorPipeline/UpdateStartupRelationshipDialog';
import { updateStartupRelationship } from '/src/services/startupService';
import { StartupRelationship } from '../../../types/model/StartupRelationship';
import { PersonDataType } from '/../libs/shared-types/src/constants/PersonDataType';
import FieldFilterWrapper from '/src/components/FieldFilterWrapper';
import DropdownMultiCheckBoxes from '/src/components/DropdownMultiCheckBoxes';
import { RELATIONSHIP_STRENGTH_WITH_UNSET_OPTIONS } from '/../libs/shared-types/src/constants/Strength';
import { NOT_SET_OPTION } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import { getSupportedRelationshipType } from '/../libs/shared-types/src/constants/StartupRelationshipType';
import { getConnectionDetailUrl } from '/src/util/navigation';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { INVESTOR_LIST_BY_ID_KEY } from '/src/hooks/useGetInvestorListById';

interface MyNetworkPersonsProps {
  apiResponse?: PersonsPaginatedCollectionResponse;
  isSearchLoading: boolean;
  refetch: () => void;
}

function MyNetworkPersons({
  apiResponse,
  isSearchLoading,
  refetch,
}: MyNetworkPersonsProps): JSX.Element {
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [toastConfig, setToastConfig] = useState<ToastConfiguration>();
  const [modalConfig, setModalConfig] = useState<
    | {
        type: '' | 'edit';
        isModalOpen: boolean;
        person: undefined;
      }
    | {
        type: 'delete' | 'updateStartupRelationship';
        isModalOpen: boolean;
        person: PersonView;
      }
  >({
    type: '',
    isModalOpen: false,
    person: undefined,
  });
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  function closeModal() {
    setModalConfig({ type: '', isModalOpen: false, person: undefined });
    setErrorMessage('');
  }

  function invalidateTargetLists() {
    queryClient.invalidateQueries({ queryKey: [INVESTOR_LIST_BY_ID_KEY] });
  }

  async function handleSavePerson() {
    refetch();
    closeModal();
  }

  async function handleDeletePerson(investor: PersonView) {
    try {
      await API.delete<Person>(
        `${StartupDeletePerson.buildEndpoint(investor._id)}`,
      );
      refetch();
      closeModal();
      invalidateTargetLists();
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  }

  async function handleUpdatePersonRelationship(
    personId: string,
    update: Partial<StartupRelationship>,
  ) {
    try {
      await updateStartupRelationship(personId, PersonDataType.Person, update);
      refetch();
      closeModal();
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  }

  if (subscriptionTier === SubscriptionTiers.StartupFree) {
    return (
      <div className="mt-6 w-full rounded-md bg-white p-8 shadow">
        <p className="font-medium">
          Import your connections and discover who can help you fundraise
        </p>
        <ul className="mt-4 list-inside list-disc">
          <li>Import all your non-investor connections into Flowlie</li>
          <li>
            Define relationships to find the strongest intro to your target
            investors
          </li>
          <li>All your connections stay private to you</li>
        </ul>
        <p className="my-6">
          Try Flowlie Pro for free for 14 days to unlock the ability to import
          your network and map intro paths
        </p>
        <div className="w-max">
          <SubscriptionCTAPill
            id="cta_my_network_persons"
            text="Import your connections"
          />
        </div>
      </div>
    );
  }

  if (!apiResponse) {
    return <></>;
  }

  function onRowClick(rowKey: string) {
    const person = apiResponse?.results.find((x) => x._id === rowKey);
    if (!person) {
      return;
    }

    const detailUrl = getConnectionDetailUrl(PersonDataType.Person, person._id);
    navigate(detailUrl);
  }

  const onAction = async ({ type, payload }: any) => {
    if (type === PERSON_ACTION.REFRESH) {
      refetch();
    }

    if (type === PERSON_ACTION.DELETE_RELATIONSHIP) {
      setModalConfig({
        type: 'delete',
        isModalOpen: true,
        person: payload.person,
      });
    }

    if (type === PERSON_ACTION.EDIT_RELATIONSHIP) {
      setModalConfig({
        type: 'edit',
        isModalOpen: true,
        person: payload.person,
      });
    }

    if (type === PERSON_ACTION.UPDATE_STARTUP_RELATIONSHIP) {
      setModalConfig({
        type: 'updateStartupRelationship',
        isModalOpen: true,
        person: payload.person,
      });
    }
  };

  const rows = new PersonMapper(
    !selectedRows.length ? onAction : undefined,
  ).mapAllTo(apiResponse.results);

  return (
    <>
      {apiResponse.totalCount === 0 &&
        apiResponse.filter === '' &&
        !Object.keys(apiResponse.fieldFilters).length && (
          <EmptyState
            title="You have not imported any connections yet"
            subTitle="Create and manage connections from your own personal network."
            icon={<UserPlusIcon className="mx-auto h-12 w-12 text-gray-400" />}
            actionButton={
              <button
                type="button"
                className="app-button--primary"
                onClick={() =>
                  setModalConfig({
                    type: 'edit',
                    isModalOpen: true,
                    person: undefined,
                  })
                }
              >
                Add Connection
              </button>
            }
          />
        )}

      {(apiResponse.totalCount > 0 ||
        apiResponse.filter !== '' ||
        Object.keys(apiResponse.fieldFilters).length > 0) && (
        <>
          <div className="mb-3 flex flex-wrap items-center justify-between gap-x-3 gap-y-3">
            <span className="relative flex items-center">
              <SearchBar
                isDebounce
                placeholder="Search Imported connections"
                initialValue={apiResponse.filter}
                isDisabled={false}
                shouldUpdateUrlParams
              />
              {isSearchLoading && (
                <LoadingSpinner
                  color="blue"
                  className="absolute right-[-2rem]"
                />
              )}
            </span>

            <div className="flex flex-wrap items-center justify-between gap-x-3 gap-y-3">
              <FieldFilterWrapper>
                {(updateParams) => (
                  <div className="flex flex-row items-center space-x-2">
                    <span className="text-sm text-gray-600">Filters:</span>
                    <DropdownMultiCheckBoxes
                      key={JSON.stringify(
                        apiResponse.fieldFilters.relationshipTypes,
                      )}
                      label="Type"
                      checkboxes={getSupportedRelationshipType(
                        PersonDataType.Person,
                      ).map((x) => ({
                        label: x,
                        value:
                          apiResponse.fieldFilters.relationshipTypes?.includes(
                            x,
                          ) ?? false,
                      }))}
                      onCheckboxesChange={(checkboxStates) => {
                        updateParams({
                          ...apiResponse.fieldFilters,
                          relationshipTypes: Object.keys(checkboxStates).filter(
                            (key) => checkboxStates[key],
                          ),
                        });
                      }}
                    />

                    <DropdownMultiCheckBoxes
                      key={JSON.stringify(
                        apiResponse.fieldFilters.relationshipStrenghts,
                      )}
                      label="Relationship"
                      checkboxes={RELATIONSHIP_STRENGTH_WITH_UNSET_OPTIONS.map(
                        (relationship) => ({
                          label: relationship.label,
                          value:
                            apiResponse.fieldFilters.relationshipStrenghts?.includes(
                              relationship.value === null
                                ? null
                                : Number(relationship.value),
                            ) ?? false,
                        }),
                      )}
                      onCheckboxesChange={(
                        checkboxStates: Record<string, boolean>,
                      ) => {
                        updateParams({
                          ...apiResponse.fieldFilters,
                          relationshipStrenghts: Object.keys(checkboxStates)
                            .filter((option) => checkboxStates[option])
                            .map((selectedOption) =>
                              selectedOption === NOT_SET_OPTION.label
                                ? NOT_SET_OPTION.value
                                : Number(
                                    RELATIONSHIP_STRENGTH_WITH_UNSET_OPTIONS.find(
                                      (relationshipOption) =>
                                        relationshipOption.label ===
                                        selectedOption,
                                    )?.value,
                                  ),
                            ),
                        });
                      }}
                    />
                  </div>
                )}
              </FieldFilterWrapper>
              <button
                type="button"
                className="app-button--primary"
                onClick={() => {
                  setModalConfig({
                    type: 'edit',
                    isModalOpen: true,
                    person: undefined,
                  });
                }}
              >
                <UserPlusIconSolid className="mr-2 h-5 w-5" />
                Add Connection
              </button>
            </div>
          </div>

          <BasePaginatedTable
            tableName="imported-investors-table"
            enableSort
            onRowClick={onRowClick}
            headerStructure={[
              {
                headerKey: 'name',
                label: 'Name',
              },
              {
                headerKey: 'firm',
                label: 'Firm & Role',
              },
              {
                headerKey: 'email',
                label: 'email',
              },
              {
                headerKey: 'links',
                label: 'links',
              },
              {
                headerKey: 'relationshipType',
                label: 'Type',
              },
              {
                headerKey: 'connectedFounder',
                label: 'Founder',
              },
              {
                headerKey: 'relationshipStrength',
                label: 'Relationship',
              },
              {
                headerKey: 'updatedOn',
                label: 'Updated On',
              },
              {
                headerKey: 'action',
                label: 'Action',
                className: 'sr-only',
              },
            ]}
            rows={rows.map((row) => ({
              rowKey: row.id,
              data: row,
            }))}
            resultCount={apiResponse.totalCount}
            perPage={apiResponse.perPage}
            page={apiResponse.page}
            pageCount={apiResponse.totalPages}
            requestParams={{
              page: apiResponse.page,
              perPage: apiResponse.perPage,
              sortOrder: apiResponse.sortOrder,
              sortKey: apiResponse.sortKey,
              filter: apiResponse.filter,
              fieldFilters: apiResponse.fieldFilters,
            }}
          />
        </>
      )}

      <ModalWrapper open={modalConfig.isModalOpen} onClose={() => closeModal()}>
        {modalConfig.type === 'edit' && (
          <ImportPersonDialog
            person={modalConfig.person}
            onCancel={() => closeModal()}
            onSave={handleSavePerson}
          />
        )}
        {modalConfig.type === 'delete' && modalConfig.person && (
          <SimpleDialog
            onCancel={closeModal}
            onPrimaryAction={() => handleDeletePerson(modalConfig.person!)}
            title="Are you sure you want to delete this connection?"
            text="This action cannot be undone. All associated relationships and intro paths will be deleted."
            primaryAction="Delete Connection"
            color="red"
            errorMessage={errorMessage}
          />
        )}
        {modalConfig.type === 'updateStartupRelationship' &&
          modalConfig.person && (
            <UpdateStartupRelationshipDialog
              connectionName={modalConfig.person.name}
              connectionDataType={PersonDataType.Person}
              relationship={modalConfig.person?.relationship}
              onCancel={closeModal}
              onSave={(update: Partial<StartupRelationship>) =>
                handleUpdatePersonRelationship(modalConfig.person._id, update)
              }
            />
          )}
      </ModalWrapper>
      {toastConfig && (
        <Toast
          isShown={toastConfig !== undefined}
          onClose={() => setToastConfig(undefined)}
          title={toastConfig.isError ? ERROR : SUCCESS}
          isError={toastConfig.isError}
          text={toastConfig.message}
        />
      )}
    </>
  );
}

export default MyNetworkPersons;
