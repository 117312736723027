import React, { useEffect, useRef, useState } from 'react';
import { SortOrder } from '/src/interfaces/Sortable';
import TableHeader from './BaseTableHeader';
import { cn } from '/src/util/cn';

export interface TableHeadProps {
  headerStructure: Array<{
    headerKey: string;
    label: string;
    className?: string;
    tooltip?: string;
  }>;
  tableName: string;
  enableSort: boolean;
  enableSelect: boolean;
  defaultSortOrder?: SortOrder;
  defaultHeaderSort?: number;
  areAllSelected: boolean;
  isAnySelected: boolean;
  onSortChange?: ({
    headerKey,
    label,
    headerIndex,
    sort,
  }: {
    headerKey: string;
    label: string;
    headerIndex: number;
    sort: SortOrder | null;
  }) => void;
  headerClassName?: string;
  onCheckChange?: (isChecked: boolean) => void;
  selectAll: () => void;
  clear: () => void;
}

export default function TableHead({
  headerStructure,
  enableSort,
  tableName,
  enableSelect,
  defaultSortOrder,
  defaultHeaderSort,
  areAllSelected,
  isAnySelected,
  selectAll,
  clear,
  onSortChange,
  onCheckChange,
  headerClassName = '',
}: TableHeadProps) {
  const [headerSortIndex, setHeaderSortIndex] = useState<number | null>(() =>
    defaultHeaderSort !== undefined ? defaultHeaderSort : null,
  );
  const [sort, setSort] = useState<SortOrder | null>(() =>
    defaultSortOrder !== undefined ? defaultSortOrder : null,
  );

  // classnames
  const baseRowClassName =
    'sticky top-0 z-20 w-full rounded-lg bg-gray-50 dark:bg-gray-900 select-none group/row';

  // handlers
  const handleHeaderClick = ({
    label,
    headerIndex,
    headerKey,
  }: {
    label: string;
    headerIndex: number;
    headerKey: string;
  }) => {
    if (enableSort) {
      // sorting cycle null --> asc --> desc --> asc --> ...
      const newSort =
        headerIndex !== headerSortIndex
          ? SortOrder.Asc
          : sort === null
            ? SortOrder.Asc
            : sort === SortOrder.Asc
              ? SortOrder.Desc
              : SortOrder.Asc;

      setSort(newSort);
      // header target to sort
      setHeaderSortIndex(headerIndex);
      if (onSortChange) {
        onSortChange({ headerKey, label, headerIndex, sort: newSort });
      }
    }
  };

  const handleBulkToggle = () => {
    // toggle bulk selection
    if (isAnySelected) {
      clear();
    } else {
      selectAll();
    }

    if (onCheckChange) {
      onCheckChange(!isAnySelected);
    }
  };

  const checkboxReference = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (checkboxReference.current) {
      if (isAnySelected && !areAllSelected) {
        checkboxReference.current.indeterminate = true;
      } else {
        checkboxReference.current.indeterminate = false;
      }
    }
  }, [isAnySelected, areAllSelected]);
  return (
    <thead className="bg-gray-50 dark:bg-gray-900">
      <tr className={cn(baseRowClassName, headerClassName)}>
        {enableSelect && (
          <th
            className={cn(
              'app-table-header-item group cursor-pointer !p-1 opacity-0 group-hover/row:opacity-100',
              isAnySelected ? 'opacity-100' : '',
            )}
            scope="col"
          >
            <input
              ref={checkboxReference}
              type="checkbox"
              id={`bulk-selection-${tableName}`}
              name={`bulk-selection-${tableName}`}
              className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-0"
              checked={areAllSelected}
              onChange={handleBulkToggle}
            />
          </th>
        )}
        {headerStructure.map(
          ({ label, headerKey, className, tooltip }, headerIndex) => (
            <TableHeader
              key={headerKey}
              label={label}
              headerIndex={headerIndex}
              headerKey={headerKey}
              sort={sort}
              enableSort={enableSort}
              headerSortIndex={headerSortIndex}
              handleHeaderClick={handleHeaderClick}
              className={className}
              tooltip={tooltip}
            />
          ),
        )}
      </tr>
    </thead>
  );
}
