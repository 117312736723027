import React, { Fragment, useState } from 'react';
import * as yup from 'yup';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import API from '/src/middleware/API';
import Alert from '/src/components/notifications/Alert';
import { StartupAddOrUpdatePerson } from '/../libs/shared-types/src/constants/ApiRoutes';
import InvestorFieldNames, {
  INVESTOR_URL_FIELDS,
} from '/src/enums/InvestorFieldNames';
import INVESTOR_FIELD_MAPPING from '/src/components/InvestorForm/InvestorFormFieldMapping';
import AUTH_FIELD_MAPPING from '/src/components/Authentication/AuthFormFieldMap';
import AuthFieldNames from '/src/enums/AuthFieldNames';
import { Person } from '/../libs/shared-types/src/types/model/Person';
import { removeUnsetOptionalValues, updateUrlPrefix } from '/src/util/forms';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '/src/components/inputs/CustomSelect';
import { INVESTOR_LOCATION_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import formatGroupLabel from '/src/components/inputs/ReactSelectAdditions/selectFormatGroupLabel';
import DebugRender from '/src/components/utility/DebugRender';
import { Disclosure } from '@headlessui/react';
import { FirmInput } from '/src/components/InvestorForm/Fields/FirmField';
import { RoleInput } from '/src/components/InvestorForm/Fields/RoleField';
import {
  FIRM_LENGTH_MAX,
  ROLE_LENGTH_MAX,
} from '/../libs/shared-types/src/constants/TextLengthRanges';
import { capitalize, isBlank } from '/src/util/formatting/strings';

const emptyPerson = {
  [AuthFieldNames.FirstName]: '',
  [AuthFieldNames.LastName]: '',
  [AuthFieldNames.Email]: '',
  [InvestorFieldNames.Firm]: '',
  [InvestorFieldNames.Role]: '',
  [InvestorFieldNames.LinkedIn]: '',
  [InvestorFieldNames.Website]: '',
  [InvestorFieldNames.Location]: '',
  [InvestorFieldNames.UniversityAffiliations]: '',
};

const firmField: FormField = {
  fieldComponent: <FirmInput />,
  validation: yup.object({
    [InvestorFieldNames.Firm]: yup
      .string()
      .nullable()
      .max(FIRM_LENGTH_MAX, `Must be at most ${FIRM_LENGTH_MAX} characters`)
      .test({
        name: 'bothFirmAndRoleMustBeSet',
        exclusive: false,
        params: {},
        message: `You must add a ${capitalize(InvestorFieldNames.Role)} too`,
        test(value) {
          if (value && isBlank(this.parent[InvestorFieldNames.Role])) {
            return false;
          }
          return true;
        },
      }),
  }),
};

const roleField: FormField = {
  fieldComponent: <RoleInput />,
  validation: yup.object({
    [InvestorFieldNames.Role]: yup
      .string()
      .nullable()
      .max(ROLE_LENGTH_MAX, `Must be at most ${ROLE_LENGTH_MAX} characters`)
      .test({
        name: 'bothFirmAndRoleMustBeSet',
        exclusive: false,
        params: {},
        message: `You must add a ${capitalize(InvestorFieldNames.Firm)} too`,
        test(value) {
          if (value && isBlank(this.parent[InvestorFieldNames.Firm])) {
            return false;
          }
          return true;
        },
      }),
  }),
};

const emailFieldOptional: FormField = {
  ...AUTH_FIELD_MAPPING[AuthFieldNames.Email],
  validation: yup.object({
    [AuthFieldNames.Email]: yup
      .string()
      .max(255, 'Must be at most 255 characters')
      .email('The email is not valid'),
  }),
};

const locationFieldOptional: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      closeMenuOnSelect
      isClearable
      component={CustomSelect}
      groupLabelFormat={formatGroupLabel}
      label="Location"
      name={InvestorFieldNames.Location}
      options={INVESTOR_LOCATION_OPTIONS}
      placeholder="Select location..."
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.Location]: yup.string(),
  }),
};

const fields = [
  AUTH_FIELD_MAPPING[AuthFieldNames.FirstName],
  AUTH_FIELD_MAPPING[AuthFieldNames.LastName],
  emailFieldOptional,
  INVESTOR_FIELD_MAPPING[InvestorFieldNames.LinkedIn],
  firmField,
  roleField,
  INVESTOR_FIELD_MAPPING[InvestorFieldNames.Website],
  locationFieldOptional,
  INVESTOR_FIELD_MAPPING[InvestorFieldNames.UniversityAffiliations],
];

interface ImportInvestorDialogProps {
  person?: Partial<Person>;
  onCancel: () => void;
  onSave: (person: Person) => void;
}

function ImportInvestorDialog({
  person,
  onCancel,
  onSave,
}: ImportInvestorDialogProps): JSX.Element {
  const [initialValues, setInitialValues] = useState<any>({
    ...emptyPerson,
    ...person,
  });
  const [errorMessage, setErrorMessage] = useState('');

  const initialTouched = Object.keys(emptyPerson).reduce<
    Record<string, boolean>
  >((acc, key) => {
    acc[key as keyof typeof emptyPerson] = true;
    return acc;
  }, {});

  async function onSubmit(
    values: Person,
    { setSubmitting }: FormikHelpers<any>,
  ) {
    setSubmitting(true);
    try {
      const cleanValues = removeUnsetOptionalValues(
        updateUrlPrefix(values, INVESTOR_URL_FIELDS),
      );

      const person = await API.post<Person>(
        StartupAddOrUpdatePerson.buildEndpoint(),
        cleanValues,
      );
      onSave(person);
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <div className="w-screen bg-white p-4 sm:max-w-2xl sm:p-7">
      <Formik
        initialValues={initialValues}
        validationSchema={fields
          .map((field) => field.validation)
          .reduce((merged, schema) => merged.concat(schema))}
        validateOnMount
        enableReinitialize
        initialTouched={initialTouched}
        onSubmit={onSubmit}
      >
        {({ values, dirty, isSubmitting, isValid }) => (
          <Form>
            <header className="relative mb-6 items-center justify-between sm:flex sm:flex-row">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {!initialValues._id ? 'Create' : 'Edit'} Connection
                <p className="text-sm font-normal text-gray-600">
                  The connections you import are private and will only be
                  visible to you.
                </p>
              </h3>

              <div className="my-4 flex flex-col space-y-3 sm:my-0 sm:ml-4 sm:flex-row sm:space-x-3 sm:space-y-0">
                <button
                  type="button"
                  onClick={() => onCancel()}
                  className="app-button--neutral justify-center truncate"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="app-button--green justify-center truncate"
                  disabled={!isValid || !dirty || isSubmitting}
                >
                  Save
                  {isSubmitting && (
                    <div className="ml-2">
                      <LoadingSpinner color="white" />
                    </div>
                  )}
                </button>
              </div>
            </header>

            <section className="sm:grid sm:grid-cols-2 sm:gap-x-10">
              {fields.map((field) => (
                <Fragment key={fields.indexOf(field)}>
                  {field.fieldComponent}
                </Fragment>
              ))}
            </section>

            <div className="my-4">
              <Alert
                color="red"
                alertType="Error"
                content={errorMessage}
                isShown={errorMessage !== ''}
                onClose={() => setErrorMessage('')}
              />
            </div>

            <DebugRender>
              <Disclosure>
                <Disclosure.Button className="app-button--neutral">
                  <pre>Formik Values:</pre>
                </Disclosure.Button>
                <Disclosure.Panel>
                  <pre className="mb-24">{JSON.stringify(values, null, 2)}</pre>
                </Disclosure.Panel>
              </Disclosure>
            </DebugRender>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ImportInvestorDialog;
