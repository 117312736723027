import { ConnectionDataType } from '/../libs/shared-types/src/constants/ConnectionDataType';
import { InvestorDataType } from '/../libs/shared-types/src/constants/InvestorDataType';
import { PersonDataType } from '/../libs/shared-types/src/constants/PersonDataType';
import {
  INVESTOR_DETAIL,
  STARTUP_INVESTOR_FRAGMENT_DETAIL_ROUTE,
  STARTUP_PERSON_DETAIL_ROUTE,
  UNVERIFIED_INVESTOR_DETAIL,
} from '../constants/Routes';

export function getConnectionDetailUrl(
  connectionDataType: ConnectionDataType,
  id: string,
) {
  let baseUrl = '';
  switch (connectionDataType) {
    case InvestorDataType.Verified:
      baseUrl = INVESTOR_DETAIL;
      break;
    case InvestorDataType.Unverified:
      baseUrl = UNVERIFIED_INVESTOR_DETAIL;
      break;
    case InvestorDataType.Fragment:
      baseUrl = STARTUP_INVESTOR_FRAGMENT_DETAIL_ROUTE;
      break;
    case PersonDataType.Person:
      baseUrl = STARTUP_PERSON_DETAIL_ROUTE;
      break;
    default:
      break;
  }

  if (!baseUrl) {
    return '';
  }

  return `${baseUrl}/${id}`;
}
