import React from 'react';
import { joinClassNames } from '/src/util/formatting/strings';
import DropdownButton from '/src/components/DropdownButton';
import { Menu } from '@headlessui/react';
import { EllipsisVerticalIcon, TrashIcon } from '@heroicons/react/24/solid';
import { ArrowsRightLeftIcon } from '@heroicons/react/20/solid';
import {
  INTRO_PATH_BY_CONNECTION_ACTION,
  IntroPathByConnectionActionProps,
} from '../../../routes/startup/MyNetwork/IntroPathByConnectionMapper';
import { IntroPathView } from '../../../types/view/IntroPathView';
import { getConnectionDataType } from '../../../../../libs/shared-types/src/constants/ConnectionDataType';

export interface TargetInvestorActionProps {
  introPath: IntroPathView;
  connection:
    | IntroPathView['sourceConnection']
    | IntroPathView['targetConnection'];
  onAction: ({ type, payload }: IntroPathByConnectionActionProps) => void;
}

function IntroPathByConnectionCellAction({
  introPath,
  connection,
  onAction,
}: TargetInvestorActionProps) {
  const handleUpdateIntroPath = (
    event: React.MouseEvent<HTMLButtonElement>,
    introPath: IntroPathView,
  ) => {
    event.stopPropagation();
    onAction({
      type: INTRO_PATH_BY_CONNECTION_ACTION.EDIT_INTRO_PATH,
      payload: {
        currentDataType: getConnectionDataType(connection),
        currentId: connection._id,
        currentName: connection.firstName + ' ' + connection.lastName,
        introPath: introPath,
      },
    });
  };

  const handleDeleteIntroPath = (
    event: React.MouseEvent<HTMLButtonElement>,
    introPath: IntroPathView,
  ) => {
    event.stopPropagation();
    onAction({
      type: INTRO_PATH_BY_CONNECTION_ACTION.DELETE_INTRO_PATH,
      payload: {
        currentDataType: getConnectionDataType(connection),
        currentId: connection._id,
        introPath,
      },
    });
  };

  return (
    <div
      className="app-table-row-text"
      onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
      aria-hidden="true"
    >
      <DropdownButton
        label={'Manage Investor'}
        button={
          <Menu.Button
            className="rounded-md px-2 py-1.5 text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
              e.stopPropagation()
            }
          >
            <EllipsisVerticalIcon className="h-5 w-5" />
          </Menu.Button>
        }
      >
        <Menu.Item>
          {({ active }) => (
            <button
              type="button"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                handleUpdateIntroPath(event, introPath)
              }
              className={joinClassNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'group flex w-full items-center px-4 py-2 text-sm',
              )}
              title="Update Intro Path"
            >
              <ArrowsRightLeftIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              Update Intro Path
            </button>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <button
              type="button"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                handleDeleteIntroPath(event, introPath)
              }
              className={joinClassNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'group flex w-full items-center px-4 py-2 text-sm',
              )}
              title="Delete intro path"
            >
              <TrashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              Delete
            </button>
          )}
        </Menu.Item>
      </DropdownButton>
    </div>
  );
}

export default IntroPathByConnectionCellAction;
