import React, { useRef } from 'react';
import { cn } from '/src/util/cn';
import SortIcon from '../../utility/SortIcon';
import { SortOrder } from '/src/interfaces/Sortable';
import Tooltip from '../../utility/Tooltip';

export interface TableHeaderProps {
  label: string;
  headerKey: string;
  headerIndex: number;
  sort: SortOrder | null;
  enableSort: boolean;
  headerSortIndex: number | null;
  className?: string;
  tooltip?: string;
  handleHeaderClick: ({
    label,
    headerIndex,
    headerKey,
  }: {
    label: string;
    headerIndex: number;
    headerKey: string;
  }) => void;
}

export default function TableHeader({
  label,
  headerKey,
  headerIndex,
  sort,
  enableSort,
  headerSortIndex,
  className,
  tooltip,
  handleHeaderClick,
}: TableHeaderProps) {
  const headerClassName = cn(
    'px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-400 group cursor-pointer',
    className,
  );

  return (
    <th
      className={headerClassName}
      key={headerKey}
      onClick={() => handleHeaderClick({ headerKey, label, headerIndex })}
    >
      <div
        className={cn(
          'relative flex items-center justify-start',
          headerSortIndex === headerIndex ? 'font-bold text-gray-700' : '',
        )}
      >
        <div className="flex">
          <span className="text-nowrap">{label}</span>
          {tooltip && (
            <Tooltip tooltipText={tooltip} position="left" width="w-64" />
          )}
        </div>
        <div
          className={cn(
            'group-hover:opacity-100',
            headerSortIndex === headerIndex ? 'opacity-100' : 'opacity-0',
          )}
        >
          {enableSort && sort && <SortIcon order={sort} />}
        </div>
      </div>
    </th>
  );
}
