import React, { useEffect, useState } from 'react';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { useLocation, useNavigate } from 'react-router-dom';
import { joinClassNames } from '/src/util/formatting/strings';

interface NavigationBackButtonProps {
  className?: string;
}

function NavigationBackButton({
  className = '',
}: NavigationBackButtonProps): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const [navigationCount, setNavigationCount] = useState(0);

  const goBack = () => {
    navigate(navigationCount);
  };

  // When the location changes, we decrement the navigationCount by 1
  useEffect(() => {
    setNavigationCount(navigationCount - 1);
  }, [location]);

  if (!location.pathname.includes('app')) {
    return <></>;
  }

  return (
    <button
      type="button"
      className={joinClassNames(
        className,
        'group flex items-center space-x-1 text-gray-500 hover:text-gray-600 hover:underline hover:underline-offset-2',
      )}
      onClick={goBack}
    >
      <ChevronLeftIcon className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />
      <span>Back</span>
    </button>
  );
}

export default NavigationBackButton;
