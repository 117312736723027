import React from 'react';
import { isMinDate } from '/src/util/time';
import {
  formatDate,
  formatDateTime,
  howLongBetween,
} from '/src/util/formatting/dates';

export function LastViewCell({ lastViewedOn }: { lastViewedOn: Date }) {
  return (
    <>
      {isMinDate(lastViewedOn) && (
        <div className="app-table-row-text">Never</div>
      )}
      {!isMinDate(lastViewedOn) && (
        <>
          <div className="app-table-row-text">
            {howLongBetween(lastViewedOn)}
          </div>
          <div className="app-table-row-text--light">
            {formatDate(lastViewedOn)}
            {' at '}
            {formatDateTime(lastViewedOn)}
          </div>
        </>
      )}
    </>
  );
}

export default LastViewCell;
