import React, { useState } from 'react';
import CsvToHtmlTable, {
  parseCsvToRowsAndColumn,
} from '../../../components/table/CsvToHtmlTable';
import { Breadcrumbs } from '../../AdminRoutes';
import { Disclosure } from '@headlessui/react';
import Logger from '/src/services/logger';
import { UnverifiedInvestor } from '/../libs/shared-types/src/types/model/UnverifiedInvestor';
import InvestorTypes from '../../../constants/InvestorTypes';
import { ContactPreferences } from '../../../constants/ContactPreferences';
import { ProductCategory } from '../../../constants/ProductCategory';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { createOrUpdateUnverifiedInvestor } from '/src/services/Admin/UnverifiedInvestor';
import { ToastConfiguration } from '/src/interfaces/ToastConfiguration';
import Toast from '/src/components/notifications/Toast';
import { UNVERIFIED_INVESTOR_VALIDATION } from './UnverifiedInvestorForm';
import Tooltip from '/src/components/utility/Tooltip';
import { InvestmentAuthority } from '../../../constants/InvestmentAuthority';

function cleanArrayString(arrayString: string): string[] | undefined {
  return arrayString
    ? arrayString
        .replaceAll('"', '')
        .replaceAll(', ', '; ')
        .split(',')
        .map((x) => x.replace('; ', ', '))
    : undefined;
}

function isTrue(value: string): boolean {
  return value === 'true' || value === 'TRUE';
}

function convertCsvToJson(csvText: string): Partial<UnverifiedInvestor>[] {
  const rowsWithColumns = parseCsvToRowsAndColumn(csvText.trim(), ',', '\r');
  const unverifiedInvestors: Partial<UnverifiedInvestor>[] = [];

  rowsWithColumns
    .filter((x) => x[2] === 'Research Done')
    .forEach((row, index) => {
      try {
        const unverifiedInvestor: Partial<UnverifiedInvestor> = {
          firstName: row[4] ? row[4] : undefined,
          lastName: row[5] ? row[5] : undefined,
          email: row[6] ? row[6] : undefined,
          website: row[7] ? row[7] : undefined,
          linkedIn: row[8] ? row[8] : undefined,
          twitter: row[9] ? row[9] : undefined,
          type: row[10] ? (row[10] as InvestorTypes) : undefined,
          firm: row[11] ? row[11] : undefined,
          role: row[12] ? row[12] : undefined,
          location: row[13] ? row[13] : undefined,
          highestEducationLevel: row[14] ? row[14] : undefined,
          invStages: cleanArrayString(row[15]),
          invProductStage: row[16] ? row[16] : undefined,
          invProductCategories: cleanArrayString(row[17]) as ProductCategory[],
          invIndustries: cleanArrayString(row[18]),
          antiInvIndustries: cleanArrayString(row[19]),
          invBusinessModels: cleanArrayString(row[20]),
          invGeographies: cleanArrayString(row[21]),
          leadsRounds: row[22] ? row[22] : undefined,
          takesBoardSeats: row[23] ? row[23] : undefined,
          checkRangeMin: row[24] ? Number(row[24]) : undefined,
          checkRangeMax: row[25] ? Number(row[25]) : undefined,
          hasInitialOwnershipTarget: isTrue(row[26]),
          initialOwnershipTargetMin: row[27] ? Number(row[27]) : undefined,
          initialOwnershipTargetMax: row[28] ? Number(row[28]) : undefined,
          yearlyAvgInvCount: row[29] ? row[29] : undefined,
          isIncludedInInvestorDbForStartups: isTrue(row[30]),
          isOpenToColdInbound: isTrue(row[31]),
          preferenceContact: cleanArrayString(row[32]) as ContactPreferences[],
          preferenceFollowUp: isTrue(row[33]),
          expFunctional: cleanArrayString(row[34])
            ? cleanArrayString(row[34])
            : undefined,
          expIndustry: cleanArrayString(row[35])
            ? cleanArrayString(row[35])
            : undefined,
          invImpacts: cleanArrayString(row[36])
            ? cleanArrayString(row[36])
            : undefined,
          firmJoinedOn: row[37] ? new Date(row[37]) : undefined,
          invAuthority: row[38] ? (row[38] as InvestmentAuthority) : undefined,
        };
        unverifiedInvestors.push(unverifiedInvestor);
      } catch (error: any) {
        Logger.warn(
          'Error creating unverifiedInvestor from row',
          'index:',
          index,
          'recordIdentifier:',
          row[0],
        );
        Logger.warn('Error message:', error.message);
      }
    });

  return unverifiedInvestors;
}

function UnverifiedInvestorUpload(): JSX.Element {
  const [isReadingFile, setIsReadingFile] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [localFile, setLocalFile] = useState<any>();
  const [investors, setInvestors] = useState<Partial<UnverifiedInvestor>[]>([]);
  const [toastConfig, setToastConfig] = useState<ToastConfiguration>();
  const [uploadResults, setUploadResults] = useState<{
    successes: string[];
    failures: { email: string; reason: string }[];
  }>({ successes: [], failures: [] });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    const file = input.files ? input.files[0] : null;

    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onloadstart = () => {
      setIsReadingFile(true);
    };
    reader.onloadend = () => {
      setLocalFile(reader.result);
      const parsedInvestors = convertCsvToJson(reader.result?.toString() ?? '');
      setInvestors(parsedInvestors);
      setIsReadingFile(false);
    };
    reader.readAsText(file);
  };

  async function validateInvestors() {
    // Reset the state
    uploadResults.failures = [];
    uploadResults.successes = [];
    if (investors.length === 0) {
      return;
    }

    setIsValidating(true);
    for (const investor of investors) {
      try {
        await UNVERIFIED_INVESTOR_VALIDATION.validate(investor);

        uploadResults.successes.push(investor.email ?? '');
        setUploadResults({
          ...uploadResults,
          successes: uploadResults.successes,
        });
      } catch (error: any) {
        Logger.warn('VALIDATION FAILED', error);

        const fieldValue = investor[error.path as keyof UnverifiedInvestor];
        const validationError = {
          fieldPath: error.path,
          fieldValue: fieldValue ?? 'undefined_value',
          fieldValueType: typeof fieldValue,
          fieldValueLength:
            Array.isArray(fieldValue) || typeof fieldValue === 'string'
              ? fieldValue.length
              : undefined,
          errorType: error.type,
          errorMessages: error.errors,
        };
        uploadResults.failures.push({
          email: investor.email ?? '',
          reason: validationError as any,
        });
        setUploadResults({
          ...uploadResults,
          failures: uploadResults.failures,
        });
      }
    }
    setIsValidating(false);
    setToastConfig({
      title: `Validation Finished - ${uploadResults.successes.length}/${investors.length} passed validation`,
      message:
        uploadResults.failures.length > 0
          ? `${uploadResults.successes.length} out of ${investors.length} are valid.`
          : 'All unverified investors are valid!',
      isError: false,
    });
  }

  async function uploadInvestors() {
    // Reset the state
    uploadResults.failures = [];
    uploadResults.successes = [];

    if (investors.length === 0) {
      return;
    }

    setIsUploading(true);
    for (const investor of investors) {
      try {
        await UNVERIFIED_INVESTOR_VALIDATION.validate(investor);

        const successMessage = await createOrUpdateUnverifiedInvestor(
          investor,
          undefined,
        );
        uploadResults.successes.push(successMessage ?? '');
        setUploadResults({
          ...uploadResults,
          successes: uploadResults.successes,
        });
      } catch (error: any) {
        Logger.warn('UPLOAD FAILED', error);

        uploadResults.failures.push({
          email: investor.email ?? '',
          reason: error.message,
        });
        setUploadResults({
          ...uploadResults,
          failures: uploadResults.failures,
        });
      }
    }
    setIsUploading(false);
    setToastConfig({
      title: `Upload Finished - ${uploadResults.successes.length}/${investors.length} uploaded`,
      message:
        uploadResults.failures.length > 0
          ? `${uploadResults.successes.length} out of ${investors.length} were uploaded successfully.`
          : 'All unverified investors have been uploaded successfully!',
      isError: false,
    });
  }

  return (
    <div className="flex w-full flex-col p-4">
      <Breadcrumbs />

      <header className="sticky top-0 z-20 bg-white pb-2 dark:bg-transparent dark:backdrop-blur">
        <div className="relative flex max-w-full items-center justify-between">
          <h3 className="page-title">Bulk Upload Unverified Investors</h3>
        </div>
      </header>

      <section className="space-y-10">
        <label htmlFor={'csvFile'} className="input-label">
          <div>Select CSV file to upload</div>
          <input
            accept=".csv"
            type="file"
            id={'csvFile'}
            name={'csvFile'}
            onChange={handleFileChange}
          />
          {isReadingFile && (
            <PageLoadingSpinner message="Loading file, be patient... 🗿" />
          )}
          {investors.length > 0 && (
            <>
              <button
                type="button"
                className="app-button--neutral ml-10"
                disabled={isValidating}
                onClick={() => validateInvestors()}
              >
                Validate Investors
                <Tooltip tooltipText="Only validates if any required fields are missing" />
                {isValidating && (
                  <div className="ml-2">
                    <LoadingSpinner color="white" />
                  </div>
                )}
              </button>
              <button
                type="button"
                className="app-button--green ml-10"
                disabled={isUploading}
                onClick={() => uploadInvestors()}
              >
                Upload Investors
                {isUploading && (
                  <div className="ml-2">
                    <LoadingSpinner color="white" />
                  </div>
                )}
              </button>
            </>
          )}
        </label>

        {uploadResults.failures.length > 0 && (
          <Disclosure as="div">
            <Disclosure.Button className="app-button--neutral">
              <pre>Show Errors ({uploadResults.failures.length})</pre>
            </Disclosure.Button>
            <Disclosure.Panel>
              <pre className="mb-6 text-sm">
                {JSON.stringify(uploadResults.failures, null, 2)}
              </pre>
            </Disclosure.Panel>
          </Disclosure>
        )}

        {uploadResults.successes.length > 0 && (
          <Disclosure as="div">
            <Disclosure.Button className="app-button--neutral">
              <pre>Show Successes ({uploadResults.successes.length})</pre>
            </Disclosure.Button>
            <Disclosure.Panel>
              <pre className="mb-6 text-sm">
                {JSON.stringify(uploadResults.successes, null, 2)}
              </pre>
            </Disclosure.Panel>
          </Disclosure>
        )}

        {investors.length > 0 && (
          <Disclosure as="div">
            <Disclosure.Button className="app-button--neutral">
              <pre>Show Parsed UnverifiedInvestors ({investors.length})</pre>
            </Disclosure.Button>
            <Disclosure.Panel>
              <pre className="mb-6 text-sm">
                {JSON.stringify(investors, null, 2)}
              </pre>
            </Disclosure.Panel>
          </Disclosure>
        )}

        {localFile && (
          <Disclosure as="div">
            <Disclosure.Button className="app-button--neutral">
              <pre>Show Raw Text Data</pre>
            </Disclosure.Button>
            <Disclosure.Panel>
              <pre className="mb-6 text-sm">{localFile.toString()}</pre>
            </Disclosure.Panel>
          </Disclosure>
        )}

        {localFile && (
          <Disclosure as="div">
            <Disclosure.Button className="app-button--neutral">
              <pre>
                Show Table ({localFile.toString().split('\n').length} rows)
              </pre>
            </Disclosure.Button>
            <Disclosure.Panel>
              <CsvToHtmlTable data={localFile.toString()} csvDelimiter="," />
            </Disclosure.Panel>
          </Disclosure>
        )}
      </section>

      <div className="z-20">
        <Toast
          isShown={toastConfig !== undefined}
          onClose={() => setToastConfig(undefined)}
          title={toastConfig?.title ?? ''}
          isError={toastConfig?.isError}
          text={toastConfig?.message ?? ''}
        />
      </div>
    </div>
  );
}

export default UnverifiedInvestorUpload;
