import { listToSelectOptions } from '../../extensions/SelectOptionsExtensions';
import NATIONALITY_OPTIONS from './NationalityOptions';

/**
 * IMPORTANT! If you modify any groupLabel you must also update the STATE_OPTIONS
 */
const STARTUP_GEOGRAPHY_VALUES = [
  {
    groupLabel: 'Remote First',
    options: [
      'Remote (North America)',
      'Remote (Central & South America)',
      'Remote (Europe)',
      'Remote (Middle East & Africa)',
      'Remote (Asia & Oceania)',
      'Remote (Global)',
    ],
  },
  {
    groupLabel: 'US Northeast',
    options: [
      'Albany, NY',
      'Alexandria, VA',
      'Allentown, PA',
      'Arlington, VA',
      'Baltimore, MD',
      'Bangor, ME',
      'Boston, MA',
      'Bridgeport, CT',
      'Buffalo, NY',
      'Burlington, VT',
      'Cambridge, MA',
      'Camden - Cherry Hill, NJ',
      'Cape Cod, MA',
      'Charleston, WV',
      'Charlottesville, VA',
      'Concord, NH',
      'Dover, DE',
      'Greenwich, CT',
      'Harrisburg, PA',
      'Hartford, CT',
      'Jersey City, NJ',
      'Long Island, NY',
      'Manchester, NH',
      'Morgantown, WV',
      'New Haven, CT',
      'New York, NY',
      'Newark, DE',
      'Newark, NJ',
      'Norfolk, VA',
      'Philadelphia, PA',
      'Pittsburgh, PA',
      'Portland, ME',
      'Portsmouth, NH',
      'Providence, RI',
      'Richmond, VA',
      'Rochester, NY',
      'Springfield, MA',
      'Stamford, CT',
      'State College, PA',
      'Syracuse, NY',
      'Trenton, NJ',
      'Virginia Beach, VA',
      'Warwick, RI',
      'Washington, DC',
      'Williamsburg, VA',
      'Wilmington, DE',
      'Worcester, MA',
    ],
  },
  {
    groupLabel: 'US Southeast',
    options: [
      'Atlanta, GA',
      'Augusta, GA',
      'Birmingham, AL',
      'Cape Coral - Fort Myers, FL',
      'Charleston, SC',
      'Charlotte, NC',
      'Columbia, SC',
      'Columbus, GA',
      'Durham - Chapel Hill, NC',
      'Fayetteville, NC',
      'Fort Lauderdale, FL',
      'Gainesville, FL',
      'Greensboro, NC',
      'Greenville, SC',
      'Huntsville, AL',
      'Jacksonville, FL',
      'Knoxville, TN',
      'Memphis, TN',
      'Miami Beach, FL',
      'Miami, FL',
      'Mobile, AL',
      'Montgomery, AL',
      'Nashville, TN',
      'Orlando, FL',
      'Raleigh, NC',
      'Savannah, GA',
      'Tallahassee, FL',
      'Tampa - St. Petersburg, FL',
      'West Palm Beach, FL',
      'Wilmington, NC',
      'Winston-Salem, NC',
    ],
  },
  {
    groupLabel: 'US Midwest',
    options: [
      'Ames, IA',
      'Ann Arbor, MI',
      'Bismarck, ND',
      'Bloomington, IN',
      'Cedar Rapids, IA',
      'Champaign, IL',
      'Chicago, IL',
      'Cincinnati, OH',
      'Cleveland, OH',
      'Columbus, OH',
      'Dayton, OH',
      'Des Moines, IA',
      'Detroit, MI',
      'Evansville, IN',
      'Fargo, ND',
      'Fort Wayne, IN',
      'Grand Rapids, MI',
      'Indianapolis, IN',
      'Kansas City, KS',
      'Kansas City, MO',
      'Lansing, MI',
      'Lexington, KY',
      'Lincoln, NE',
      'Louisville, KY',
      'Madison, WI',
      'Milwaukee, WI',
      'Minneapolis - St. Paul, MN',
      'Naperville - Aurora, IL',
      'Omaha, NE',
      'Peoria, IL',
      'Rapid City, SD',
      'Rochester, MN',
      'Saint Louis, MO',
      'Sioux City, IA',
      'Sioux Falls, SD',
      'Toledo, OH',
      'Wichita, KS',
    ],
  },
  {
    groupLabel: 'US Southwest',
    options: [
      'Austin, TX',
      'Baton Rouge, LA',
      'College Station, TX',
      'Corpus Christi, TX',
      'Dallas - Fort Worth, TX',
      'El Paso, TX',
      'Houston, TX',
      'Jackson, MS',
      'Lafayette, LA',
      'Little Rock, AR',
      'Lubbock, TX',
      'New Orleans, LA',
      'Oklahoma City, OK',
      'San Antonio, TX',
      'Stillwater, OK',
      'Tulsa, OK',
      'Waco, TX',
    ],
  },
  {
    groupLabel: 'US Rocky Mountains',
    options: [
      'Albuquerque, NM',
      'Aspen, CO',
      'Billings, MT',
      'Boise, ID',
      'Boulder, CO',
      'Bozeman, MT',
      'Casper, WI',
      'Cheyenne, WI',
      'Colorado Springs, CO',
      'Denver, CO',
      'Fort Collins, CO',
      'Lehi - American Fork, UT',
      'Missoula, MT',
      'Ogden, UT',
      'Provo, UT',
      'Salt Lake City, UT',
      'Santa Fe, NM',
      'Telluride, CO',
    ],
  },
  {
    groupLabel: 'US Pacific West',
    options: [
      'Anaheim, CA',
      'Anchorage, AK',
      'Bakersfield, CA',
      'Belmont, CA',
      'Bend, OR',
      'Berkeley, CA',
      'Carson City, NV',
      'Eugene, OR',
      'Foster City, CA',
      'Fremont, CA',
      'Fresno, CA',
      'Half Moon Bay, CA',
      'Henderson, NV',
      'Hilo, HI',
      'Honolulu, HI',
      'Irvine, CA',
      'Las Vegas, NV',
      'Long Beach, CA',
      'Los Angeles, CA',
      'Menlo Park, CA',
      'Mountain View, CA',
      'Oakland, CA',
      'Palo Alto, CA',
      'Phoenix, AZ',
      'Portland, OR',
      'Redwood City, CA',
      'Reno, NV',
      'Sacramento, CA',
      'Salem, OR',
      'San Diego, CA',
      'San Francisco Bay Area, CA',
      'San Jose Bay Area, CA',
      'San Mateo, CA',
      'Santa Ana, CA',
      'Santa Rosa, CA',
      'Seattle, WA',
      'Spokane, WA',
      'Stockton, CA',
      'Tacoma, WA',
      'Tucson, AZ',
    ],
  },
  {
    groupLabel: 'Canada',
    options: [
      'Calgary, AB',
      'Edmonton, AB',
      'Fredericton, NB',
      'Halifax, NS',
      'Hamilton, ON',
      'London, ON',
      'Moncton, NB',
      'Montreal, QC',
      'Ottawa, ON',
      'Quebec City, QC',
      'Saskatoon, SK',
      'Toronto - Mississauga, ON',
      'Vancouver - Surrey, BC',
      'Victoria, BC',
      'Windsor, ON',
      'Winnipeg, MB',
    ],
  },
  {
    groupLabel: 'Central & South America',
    options: [
      'Argentina',
      'Brazil',
      'British Virgin Islands',
      'Cayman Islands',
      'Chile',
      'Colombia',
      'Costa Rica',
      'Dominican Republic',
      'Ecuador',
      'El Salvador',
      'Guatemala',
      'Honduras',
      'Jamaica',
      'Mexico',
      'Panama',
      'Paraguay',
      'Peru',
      'Puerto Rico',
      'The Bahamas',
      'Uruguay',
      'Venezuela',
    ],
  },
  {
    groupLabel: 'Western & Northern Europe',
    options: [
      'Belgium',
      'Denmark',
      'Finland',
      'France',
      'Germany',
      'Iceland',
      'Ireland',
      'Italy',
      'Liechtenstein',
      'Luxembourg',
      'Malta',
      'Netherlands',
      'Norway',
      'Portugal',
      'Spain',
      'Sweden',
      'Switzerland',
      'United Kingdom',
    ],
  },
  {
    groupLabel: 'Central & Eastern Europe',
    options: [
      'Albania',
      'Austria',
      'Belarus',
      'Bosnia and Herzegovina',
      'Bulgaria',
      'Croatia',
      'Cyprus',
      'Czech Republic',
      'Estonia',
      'Greece',
      'Hungary',
      'Latvia',
      'Lithuania',
      'Moldova',
      'Poland',
      'Romania',
      'Serbia',
      'Slovakia',
      'Slovenia',
      'Ukraine',
    ],
  },
  {
    groupLabel: 'Middle East & Northern Africa',
    options: [
      'Algeria',
      'Armenia',
      'Azerbaijan',
      'Bahrain',
      'Egypt',
      'Georgia',
      'Israel',
      'Jordan',
      'Kuwait',
      'Lebanon',
      'Morocco',
      'Oman',
      'Palestine',
      'Qatar',
      'Saudi Arabia',
      'Tunisia',
      'Turkey',
      'United Arab Emirates',
    ],
  },
  {
    groupLabel: 'Western & Central Africa',
    options: [
      'Angola',
      'Cameroon',
      'Chad',
      'Democratic Republic of Congo',
      'Ghana',
      'Guinea',
      'Mauritania',
      'Niger',
      'Nigeria',
      'Republic of the Congo',
      'Senegal',
      'Sierra Leone',
    ],
  },
  {
    groupLabel: 'Eastern & Southern Africa',
    options: [
      'Botswana',
      'Ethiopia',
      'Kenya',
      'Madagascar',
      'Mozambique',
      'Namibia',
      'South Africa',
      'Tanzania',
      'Uganda',
      'Zimbabwe',
    ],
  },
  {
    groupLabel: 'Western & Central Asia',
    options: [
      'Bangladesh',
      'India',
      'Iran',
      'Kazakhstan',
      'Pakistan',
      'Russia',
    ],
  },
  {
    groupLabel: 'Southeast Asia & Oceania',
    options: [
      'Australia',
      'Cambodia',
      'China',
      'Hong Kong',
      'Indonesia',
      'Japan',
      'Malaysia',
      'New Zealand',
      'Philippines',
      'Singapore',
      'South Korea',
      'Taiwan',
      'Thailand',
      'Vietnam',
    ],
  },
];

export const STARTUP_GEO_OPTIONS = STARTUP_GEOGRAPHY_VALUES.map((x) => ({
  groupLabel: x.groupLabel,
  options: listToSelectOptions(x.options),
}));

export function getCountryCode(geography: string): string | undefined {
  let countryCode = NATIONALITY_OPTIONS.find(
    (x) => x.label === geography
  )?.value;

  if (!countryCode && geography.includes(', ')) {
    const geographyGroup = STARTUP_GEOGRAPHY_VALUES.find((x) =>
      x.options.some((y) => y === geography)
    );

    if (geographyGroup) {
      countryCode = geographyGroup.groupLabel.startsWith('US') ? 'US' : 'CA';
    }
  }

  return countryCode;
}

export function getRemoteRegion(geography: string): string | undefined {
  if (!geography.includes('Remote')) {
    return undefined;
  }
  const textBetweenParenthesis = geography.match(/\(([^)]+)\)/);
  const remoteRegion = textBetweenParenthesis
    ? textBetweenParenthesis[1]
    : null;

  if (!remoteRegion || remoteRegion === 'Global') {
    return undefined;
  }

  return remoteRegion;
}
