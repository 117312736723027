import React, { Fragment, useState, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import {
  ChevronUpDownIcon,
  ClockIcon,
  EnvelopeIcon,
  EnvelopeOpenIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  NoSymbolIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { IntroPathStatus } from '../../../../libs/shared-types/src/constants/IntroPathStatus';

export interface IntroPathStatusOption {
  value: IntroPathStatus;
  label: string;
  icon: JSX.Element;
}

export const INTRO_PATH_STATUS_OPTIONS: IntroPathStatusOption[] = [
  {
    value: IntroPathStatus.NotRequested,
    label: IntroPathStatus.NotRequested,
    icon: (
      <XMarkIcon
        key={IntroPathStatus.NotRequested}
        className="size-5 text-gray-500"
        aria-hidden="true"
      />
    ),
  },
  {
    value: IntroPathStatus.Requested,
    label: IntroPathStatus.Requested,
    icon: (
      <EnvelopeIcon
        key={IntroPathStatus.Requested}
        className="size-5 text-blue-600"
        aria-hidden="true"
      />
    ),
  },
  {
    value: IntroPathStatus.RequestIgnored,
    label: IntroPathStatus.RequestIgnored,
    icon: (
      <EnvelopeOpenIcon
        key={IntroPathStatus.RequestIgnored}
        className="size-5 text-amber-600"
        aria-hidden="true"
      />
    ),
  },
  {
    value: IntroPathStatus.RequestRefused,
    label: IntroPathStatus.RequestRefused,
    icon: (
      <NoSymbolIcon
        key={IntroPathStatus.RequestRefused}
        className="size-[1.3rem] text-red-600"
        aria-hidden="true"
      />
    ),
  },
  {
    value: IntroPathStatus.IntroPending,
    label: IntroPathStatus.IntroPending,
    icon: (
      <ClockIcon
        key={IntroPathStatus.IntroPending}
        className="size-5 text-gray-500"
        aria-hidden="true"
      />
    ),
  },
  {
    value: IntroPathStatus.OptInIgnored,
    label: IntroPathStatus.OptInIgnored,
    icon: (
      <ClockIcon
        key={IntroPathStatus.OptInIgnored}
        className="size-5 text-red-600"
        aria-hidden="true"
      />
    ),
  },
  {
    value: IntroPathStatus.OptInRefused,
    label: IntroPathStatus.OptInRefused,
    icon: (
      <HandThumbDownIcon
        key={IntroPathStatus.OptInRefused}
        className="size-5 text-red-600"
        aria-hidden="true"
      />
    ),
  },
  {
    value: IntroPathStatus.IntroMade,
    label: IntroPathStatus.IntroMade,
    icon: (
      <HandThumbUpIcon
        key={IntroPathStatus.IntroMade}
        className="size-5 text-green-600"
        aria-hidden="true"
      />
    ),
  },
];

function getIntroPathOption(value: IntroPathStatus) {
  const status = INTRO_PATH_STATUS_OPTIONS.find((x) => x.value === value);
  return status;
}

interface IntroPathLabelProps {
  status: IntroPathStatus;
  isInput?: boolean;
}

export function IntroPathLabel({ status }: IntroPathLabelProps) {
  const statusOption = getIntroPathOption(status);
  return (
    <div className="flex items-center space-x-1">
      {statusOption ? (
        <>
          <span>{statusOption.icon}</span>
          <span className="block truncate">{statusOption.label}</span>
        </>
      ) : (
        <span className="block truncate">Add Status</span>
      )}
    </div>
  );
}

interface IntroPathSelectProps {
  onSelect: (status: IntroPathStatus) => void;
  value: IntroPathStatus;
  disabled?: boolean;
}

function IntroPathSelect({
  onSelect,
  value,
  disabled = false,
}: IntroPathSelectProps) {
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <Listbox value={selected} onChange={setSelected} disabled={disabled}>
      <div className="relative">
        <Listbox.Button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
            event.stopPropagation()
          }
          className="flex cursor-default items-center space-x-1 rounded-md px-2.5 py-1.5 text-left text-xs tracking-wider hover:bg-gray-200 focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300"
        >
          <IntroPathLabel status={selected} />
          <ChevronUpDownIcon
            className="size-4 text-gray-400"
            aria-hidden="true"
          />
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-50 mt-1 overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {INTRO_PATH_STATUS_OPTIONS.map((option, index) => (
              <Listbox.Option
                key={index}
                className={({ active }) =>
                  `flex cursor-default select-none items-center space-x-1.5 p-2 pr-3 ${
                    active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'
                  }`
                }
                value={option.value}
                onClick={(event: React.MouseEvent<HTMLLIElement>) => {
                  event.stopPropagation();
                  onSelect(option.value as IntroPathStatus);
                }}
              >
                {({ selected }) => (
                  <>
                    <span>{option.icon}</span>
                    <span
                      className={`block truncate text-xs ${
                        selected ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      {option.label}
                    </span>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}

export default IntroPathSelect;
