import React, { ReactNode, useContext } from 'react';
import { SubscriptionTiers } from '/../libs/shared-types/src/constants/SubscriptionTiers';
import { SubscriptionCTAPill } from '/src/components/SubscriptionCTA';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import { StartupDealViewSessionByConnectionPaginatedCollectionResponse } from '/../libs/shared-types/src/types/view/StartupDealViewSessionByConnectionView';
import { ViewSessionByConnectionMapper } from '/src/routes/startup/ConnectionDetail/tabs/viewSessionByConnectionMapper';
import BasePaginatedTable from '/src/components/table/baseTable/BasePaginatedTable';
import DropdownSelect from '/src/components/DropdownSelect';
import FieldFilterWrapper from '/src/components/FieldFilterWrapper';
import BasicStatistic from '/src/components/cards/BasicStatistic';
import {
  formatDate,
  formatMilliSecondsAsTimeDuration,
  howLongBetween,
} from '/src/util/formatting/dates';
import Alert from '/src/components/notifications/Alert';
import { ConnectionDataType } from '/../libs/shared-types/src/constants/ConnectionDataType';
import { PersonDataType } from '/../libs/shared-types/src/constants/PersonDataType';

interface ViewSessionsByConnectionTabProps {
  apiResponse?: StartupDealViewSessionByConnectionPaginatedCollectionResponse;
  connectionEmail?: string;
  connectionDataType?: ConnectionDataType;
}

function ViewSessionsByConnectionTab({
  apiResponse,
  connectionEmail,
  connectionDataType,
}: ViewSessionsByConnectionTabProps): JSX.Element {
  const { subscriptionTier } = useContext(AccountMetadataContext);

  if (subscriptionTier === SubscriptionTiers.StartupFree) {
    return (
      <div className="mt-6 w-full rounded-md bg-white p-8 shadow">
        <p className="font-medium">
          Track all view sessions of every person looking at your materials
        </p>
        <ul className="mt-4 list-inside list-disc">
          <li>See each time they see your materials and from where</li>
          <li>Track their total time spent and the last deck version seen</li>
        </ul>
        <p className="my-6">
          Try Flowlie Pro for free for 14 days to unlock detailed view analytics
          for each person accessing your materials
        </p>
        <div className="w-max">
          <SubscriptionCTAPill
            id="cta_investor_detail_activity_tab"
            text="Access full view history"
          />
        </div>
      </div>
    );
  }

  if (!connectionEmail) {
    return (
      <Alert
        alertType="Warning"
        color="yellow"
        content={
          <span>
            This{' '}
            {connectionDataType === PersonDataType.Person
              ? 'person'
              : 'investor'}{' '}
            does not have an email associated with them. Update their profile to
            track their views
          </span>
        }
        isShown={true}
        canDismiss={false}
      />
    );
  }

  if (!apiResponse) return <></>;

  const rows = new ViewSessionByConnectionMapper().mapAllTo(
    apiResponse.results,
  );

  return (
    <section>
      <dl className="my-4 grid w-full grid-cols-2 gap-5 lg:grid-cols-5">
        <BasicStatistic
          label="Last Viewed On"
          statistic={
            apiResponse.stats.lastViewedOn
              ? howLongBetween(apiResponse.stats.lastViewedOn)
              : ''
          }
          suffix={apiResponse.stats.lastViewedOn ? '' : '-'}
        />
        <BasicStatistic
          label="Total Views"
          statistic={apiResponse.stats.totalViewCount}
          suffix={`(${formatMilliSecondsAsTimeDuration(
            apiResponse.stats.totalViewTime,
          )})`}
        />
        <BasicStatistic
          label="Total Deck Views"
          statistic={apiResponse.stats.totalDeckViews}
          suffix={`(${formatMilliSecondsAsTimeDuration(
            apiResponse.stats.totalDeckViewTime,
          )})`}
        />
        <BasicStatistic
          label="Last Deck Seen"
          statistic={
            apiResponse.stats?.lastDeckVersionViewed
              ? `V ${apiResponse.stats.lastDeckVersionViewed.major}.${apiResponse.stats.lastDeckVersionViewed.minor}`
              : ''
          }
          suffix={
            apiResponse.stats.lastDeckSeenOn
              ? `(${formatDate(apiResponse.stats.lastDeckSeenOn)})`
              : '-'
          }
        />
        <BasicStatistic
          label="Last Deck Downloaded"
          statistic={
            apiResponse.stats?.lastDeckVersionDownloaded
              ? `V ${apiResponse.stats.lastDeckVersionDownloaded.major}.${apiResponse.stats.lastDeckVersionDownloaded.minor}`
              : ''
          }
          suffix={
            apiResponse.stats.lastDeckVersionDownloadedOn
              ? `(${formatDate(apiResponse.stats.lastDeckVersionDownloadedOn)})`
              : '-'
          }
        />
      </dl>
      <div className="flex flex-row justify-end">
        <FieldFilterWrapper>
          {(updateParams) => (
            <DropdownSelect
              onSelectionChange={(option) => {
                updateParams({
                  roundStage: option,
                });
              }}
              initialValue={apiResponse.fieldFilters['roundStage'] ?? ''}
              label="Round Stage"
              items={apiResponse.startupRoundOptions}
            />
          )}
        </FieldFilterWrapper>
      </div>
      <BasePaginatedTable
        tableName="session-view-by-connection"
        headerClassName="lg:top-[13rem] top-64"
        enableSort
        headerStructure={[
          {
            headerKey: 'lastViewedOn',
            label: 'Date Accessed',
          },
          {
            headerKey: 'totalMillisecondsSpentOnDeal',
            label: 'Duration',
          },
          {
            headerKey: 'deckViewed',
            label: 'Deck Viewed',
          },
          {
            headerKey: 'dataRoomViewStatus',
            label: 'Data Room Viewed',
          },

          {
            headerKey: 'insights',
            label: 'Insights',
            tooltip: 'Additional information about the view session',
          },
        ]}
        rows={rows.map((row) => ({
          rowKey: row.id,
          data: row as unknown as Record<string, ReactNode>,
        }))}
        resultCount={apiResponse.totalCount}
        perPage={apiResponse.perPage}
        page={apiResponse.page}
        pageCount={apiResponse.totalPages}
        requestParams={{
          page: apiResponse.page,
          perPage: apiResponse.perPage,
          sortOrder: apiResponse.sortOrder,
          sortKey: apiResponse.sortKey,
          filter: apiResponse.filter,
        }}
      />
    </section>
  );
}

export default ViewSessionsByConnectionTab;
