import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { PaginatedRequestParams } from '../types/view/APIResponse';

function useUrlParams<T, K>() {
  const [searchParams, setSearchParams] = useSearchParams();

  const getParam = (key: keyof PaginatedRequestParams<T, K>) => {
    return searchParams.get(key);
  };

  /**
   * Sets the search parameters based on the provided parameters.
   * When several parameters are provided, they are all updated in one step.
   * It does not remove the previous query parameters already set in the url.
   *
   * @param params - An object containing the parameters to be set,
   *                 which may include any subset of the `PaginatedSearchParams<T, K>`.
   *                 The `Partial` type allows for passing only a subset of the properties.
   *
   * @template T - The type of the items in the paginated result.
   * @template K - The type of the keys for filtering or sorting the paginated results.
   *
   * @example
   *
   * const newParams = {
   *   fieldFilters: { investorTypes: ['Angel'] },
   *   page: 1,
   *   perPage: 10,
   * };
   *
   * setParam(newParams); // Updates the searchParams with serialized params.
   */
  const setParam = (params: Partial<PaginatedRequestParams<T, K>>) => {
    const listOfParams = Object.entries(params);
    for (const [paramKey, paramValue] of listOfParams) {
      if (paramKey === 'fieldFilters') {
        searchParams.set(paramKey, JSON.stringify(paramValue));
      } else {
        searchParams.set(paramKey, String(paramValue));
      }
    }
    setSearchParams(searchParams);
  };

  return {
    getParam,
    setParam,
  };
}

export default useUrlParams;
