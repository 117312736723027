import React from 'react';
import generateSocialHandles from '/src/util/generateSocialHandles';
import { LinkedIn } from '/src/components/CustomIcons';
import CopyTextButton from '/src/components/CopyTextButton';
import { PersonView } from '../../../types/view/PersonView';
import { generateTag } from '/src/util/generateTagCloud';
import { PencilSquareIcon } from '@heroicons/react/20/solid';

interface PersonDetailAboutCardProps {
  person: PersonView;
  onEditClick: () => void;
}

function PersonDetailAboutCard({
  person,
  onEditClick,
}: PersonDetailAboutCardProps): JSX.Element {
  const socialHandles = [];
  if (person.linkedIn) {
    socialHandles.push({
      icon: <LinkedIn />,
      name: 'LinkedIn',
      url: person.linkedIn,
    });
  }

  return (
    <section>
      <div className="rounded-lg bg-white shadow">
        <div className="flex items-center justify-between px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">About</h3>
          <button
            className="app-button--neutral flex items-center"
            onClick={onEditClick}
          >
            <PencilSquareIcon className="mr-2 size-[1rem]" aria-hidden="true" />
            <span>Edit</span>
          </button>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 xl:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Type</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {generateTag(person.type)}
              </dd>
            </div>

            {person.email && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Email</dt>
                <dd className="hyperlink mt-1 flex items-center text-sm">
                  <a className="truncate" href={`mailto:${person.email}`}>
                    {person.email}
                  </a>
                  <CopyTextButton text={person.email} className="ml-2" />
                </dd>
              </div>
            )}
            {person.website && (
              <div className="sm:col-span-2 2xl:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Website</dt>
                <dd className="hyperlink mt-1 text-sm">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={person.website}
                  >
                    {person.website}
                  </a>
                </dd>
              </div>
            )}
            {socialHandles.length > 0 && (
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Social Handles
                </dt>
                <span className="mt-1 flex flex-wrap space-x-2">
                  {generateSocialHandles(socialHandles)}
                </span>
              </div>
            )}
          </dl>
        </div>
      </div>
    </section>
  );
}

export default PersonDetailAboutCard;
