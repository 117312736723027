import React from 'react';
import { BriefcaseIcon, MapPinIcon } from '@heroicons/react/24/outline';
import { PersonView } from '/../libs/shared-types/src/types/view/PersonView';
import UserAvatarWithInitials from '/src/components/UserAvatarWithInitials';
import NavigationBackButton from '/src/components/utility/NavigationBackButton';

interface PersonDetailHeaderProps {
  person: PersonView;
}

function PersonDetailHeader({ person }: PersonDetailHeaderProps): JSX.Element {
  return (
    <section>
      <NavigationBackButton className="-mt-4 mb-2" />

      <div className="mx-auto">
        <div className="sm:flex sm:items-center sm:space-x-5">
          <figure className="relative w-fit">
            <UserAvatarWithInitials
              containerStyles="h-24 sm:h-28 w-24 sm:w-28"
              firstName={person.firstName}
              lastName={person.lastName}
              textStyles="text-4xl"
              imgAlt="Connection Profile"
              imgSrc={''}
            />
          </figure>
          <div className="sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
            <div className="block min-w-0 flex-1 space-y-1">
              <h1 className="flex items-center">
                <span className="truncate text-2xl font-bold leading-tight text-gray-900">
                  {`${person.firstName} ${person.lastName}`}
                </span>
              </h1>

              {person.role && person.firm && (
                <div className="flex items-center">
                  <BriefcaseIcon className="h-5 w-5 text-gray-600" />
                  <p className="truncate pl-1 font-medium text-gray-700">
                    {`${person.role} @ ${person.firm}`}
                  </p>
                </div>
              )}

              {person.location && (
                <div className="flex items-center">
                  <MapPinIcon className="h-5 w-5 text-gray-600" />
                  <p className="truncate pl-1 font-medium text-gray-600">
                    {person.location}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PersonDetailHeader;
