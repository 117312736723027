import React, { Fragment, useState } from 'react';
import * as yup from 'yup';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import API from '/src/middleware/API';
import Alert from '/src/components/notifications/Alert';
import { StartupAddOrUpdateInvestorFragment } from '/../libs/shared-types/src/constants/ApiRoutes';
import InvestorFieldNames, {
  INVESTOR_URL_FIELDS,
} from '/src/enums/InvestorFieldNames';
import INVESTOR_FIELD_MAPPING from '/src/components/InvestorForm/InvestorFormFieldMapping';
import AUTH_FIELD_MAPPING from '/src/components/Authentication/AuthFormFieldMap';
import AuthFieldNames from '/src/enums/AuthFieldNames';
import { InvestorFragment } from '/../libs/shared-types/src/types/model/InvestorFragment';
import { removeUnsetOptionalValues, updateUrlPrefix } from '/src/util/forms';
import FormField from '/src/interfaces/FormField';
import CustomSelect from '/src/components/inputs/CustomSelect';
import { INVESTOR_LOCATION_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import formatGroupLabel from '/src/components/inputs/ReactSelectAdditions/selectFormatGroupLabel';
import DebugRender from '/src/components/utility/DebugRender';
import { Disclosure } from '@headlessui/react';
import { enumToSelectOptions } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';
import {
  FitScoreLabels,
  getFitLabelColor,
} from '/../libs/shared-types/src/types/model/FitScore';
import { SelectOption } from '/../libs/shared-types/src/types/SelectOptions';
import { cn } from '/src/util/cn';
import { checkRangeMaxFieldOptional } from '/src/components/InvestorForm/Fields/CheckRangeMaxField';
import { checkRangeMinFieldOptional } from '/src/components/InvestorForm/Fields/CheckRangeMinField';
import { leadsRoundsFieldOptional } from '/src/components/InvestorForm/Fields/LeadsRoundsField';
import { IMPORTED_INVESTOR_KEY } from '/src/hooks/useGetImportedInvestor';
import { useQueryClient } from '@tanstack/react-query';
import { INVESTOR_LIST_BY_ID_KEY } from '/src/hooks/useGetInvestorListById';

enum FieldNames {
  FitLabel = 'fitLabel',
}

const emptyInvestorFragment = {
  [AuthFieldNames.FirstName]: '',
  [AuthFieldNames.LastName]: '',
  [AuthFieldNames.Email]: '',
  [InvestorFieldNames.Type]: '',
  [InvestorFieldNames.Firm]: '',
  [InvestorFieldNames.Role]: '',
  [InvestorFieldNames.LinkedIn]: '',
  [InvestorFieldNames.Website]: '',
  [InvestorFieldNames.Location]: '',
  [InvestorFieldNames.CheckRangeMin]: '',
  [InvestorFieldNames.CheckRangeMax]: '',
  [FieldNames.FitLabel]: '',
};

const emailFieldOptional: FormField = {
  ...AUTH_FIELD_MAPPING[AuthFieldNames.Email],
  validation: yup.object({
    [AuthFieldNames.Email]: yup
      .string()
      .max(255, 'Must be at most 255 characters')
      .email('The email is not valid'),
  }),
};

const fitLabelField: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      component={CustomSelect}
      closeMenuOnSelect
      isClearable
      label="Investor Fit"
      secondaryLabel="How relevant is this investor to your startup?"
      name={FieldNames.FitLabel}
      options={enumToSelectOptions(FitScoreLabels)}
      placeholder="Select option..."
      formatOptionLabel={({ label, value }: SelectOption) => {
        const colors = getFitLabelColor(value as FitScoreLabels);
        return (
          <div>
            <span
              className={cn(
                colors?.bgColor ?? '',
                colors?.textColor ?? '',
                'rounded px-2.5 py-1 text-sm tracking-wide',
              )}
            >
              {value}
            </span>
          </div>
        );
      }}
    />
  ),
  validation: yup.object({
    [FieldNames.FitLabel]: yup.string(),
  }),
};

const locationFieldOptional: FormField = {
  fieldComponent: (
    <Field
      className="custom-select"
      closeMenuOnSelect
      isClearable
      component={CustomSelect}
      groupLabelFormat={formatGroupLabel}
      label="Location"
      name={InvestorFieldNames.Location}
      options={INVESTOR_LOCATION_OPTIONS}
      placeholder="Select location..."
    />
  ),
  validation: yup.object({
    [InvestorFieldNames.Location]: yup.string(),
  }),
};

const fields = [
  AUTH_FIELD_MAPPING[AuthFieldNames.FirstName],
  AUTH_FIELD_MAPPING[AuthFieldNames.LastName],
  INVESTOR_FIELD_MAPPING[InvestorFieldNames.Type],
  emailFieldOptional,
  INVESTOR_FIELD_MAPPING[InvestorFieldNames.Firm],
  INVESTOR_FIELD_MAPPING[InvestorFieldNames.Role],
  INVESTOR_FIELD_MAPPING[InvestorFieldNames.LinkedIn],
  INVESTOR_FIELD_MAPPING[InvestorFieldNames.Website],
  checkRangeMinFieldOptional,
  checkRangeMaxFieldOptional,
  leadsRoundsFieldOptional,
  fitLabelField,
  locationFieldOptional,
];

interface ImportInvestorDialogProps {
  investor?: Partial<InvestorFragment>;
  onCancel: () => void;
  onSave: (investorFragment: InvestorFragment) => void;
}

function ImportInvestorDialog({
  investor,
  onCancel,
  onSave,
}: ImportInvestorDialogProps): JSX.Element {
  const [initialValues, setInitialValues] = useState<any>({
    ...emptyInvestorFragment,
    ...investor,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const queryClient = useQueryClient();

  const initialTouched = Object.keys(emptyInvestorFragment).reduce<
    Record<string, boolean>
  >((acc, key) => {
    acc[key as keyof typeof emptyInvestorFragment] = true;
    return acc;
  }, {});

  async function onSubmit(
    values: InvestorFragment,
    { setSubmitting }: FormikHelpers<any>,
  ) {
    setSubmitting(true);
    try {
      const cleanValues = removeUnsetOptionalValues(
        updateUrlPrefix(values, INVESTOR_URL_FIELDS),
      );

      const investorFragment = await API.post<InvestorFragment>(
        StartupAddOrUpdateInvestorFragment.buildEndpoint(),
        cleanValues,
      );
      onSave(investorFragment);
      invalidateImportedInvestor()
      invalidateTargetLists()
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setSubmitting(false);
    }
  }

  function invalidateImportedInvestor() {
    queryClient.invalidateQueries({ queryKey: [IMPORTED_INVESTOR_KEY] })
  }

  function invalidateTargetLists() {
    queryClient.invalidateQueries({ queryKey: [INVESTOR_LIST_BY_ID_KEY] })
  }

  return (
    <div className="w-screen bg-white p-4 sm:max-w-2xl sm:p-7">
      <Formik
        initialValues={initialValues}
        validationSchema={fields
          .map((field) => field.validation)
          .reduce((merged, schema) => merged.concat(schema))}
        validateOnMount
        enableReinitialize
        initialTouched={initialTouched}
        onSubmit={onSubmit}
      >
        {({ values, dirty, isSubmitting, isValid }) => (
          <Form>
            <header className="relative mb-6 items-center justify-between sm:flex sm:flex-row">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {!initialValues._id ? 'Create' : 'Edit'} Investor
                <p className="text-sm font-normal text-gray-600">
                  The investors you import are private and will only be visible
                  to you.
                </p>
              </h3>

              <div className="my-4 flex flex-col space-y-3 sm:my-0 sm:ml-4 sm:flex-row sm:space-x-3 sm:space-y-0">
                <button
                  type="button"
                  onClick={() => onCancel()}
                  className="app-button--neutral justify-center truncate"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="app-button--green justify-center truncate"
                  disabled={!isValid || !dirty || isSubmitting}
                >
                  Save
                  {isSubmitting && (
                    <div className="ml-2">
                      <LoadingSpinner color="white" />
                    </div>
                  )}
                </button>
              </div>
            </header>

            <section className="sm:grid sm:grid-cols-2 sm:gap-x-10">
              {fields.map((field) => (
                <Fragment key={fields.indexOf(field)}>
                  {field.fieldComponent}
                </Fragment>
              ))}
            </section>

            <div className="my-4">
              <Alert
                color="red"
                alertType="Error"
                content={errorMessage}
                isShown={errorMessage !== ''}
                onClose={() => setErrorMessage('')}
              />
            </div>

            <DebugRender>
              <Disclosure>
                <Disclosure.Button className="app-button--neutral">
                  <pre>Formik Values:</pre>
                </Disclosure.Button>
                <Disclosure.Panel>
                  <pre className="mb-24">{JSON.stringify(values, null, 2)}</pre>
                </Disclosure.Panel>
              </Disclosure>
            </DebugRender>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ImportInvestorDialog;
