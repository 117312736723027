import React, { useState } from 'react';
import * as yup from 'yup';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { Field, Form, Formik, FormikHelpers, FormikValues } from 'formik';
import Alert from '/src/components/notifications/Alert';
import { removeUnsetOptionalValues } from '/src/util/forms';
import CustomSelect from '/src/components/inputs/CustomSelect';
import DebugRender from '/src/components/utility/DebugRender';
import { Disclosure } from '@headlessui/react';
import Logger from '/src/services/logger';
import FormikInput from '/src/components/inputs/FormikInput';
import DateString from '/src/components/utility/DateString';
import {
  convertDatePickerInputToUtc,
  formatCurrentDate,
  formatDatePickerInput,
} from '/src/util/formatting/dates';
import { RELATIONSHIP_STRENGTH_OPTIONS } from '/../libs/shared-types/src/constants/Strength';
import BooleanSelectInput from '/src/components/inputs/BooleanSelectInput';
import { FormatOptionLabelMeta } from 'react-select';
import { TooltipSelectOption } from '../../../../types/SelectOptions';
import FormatOptionWithTooltipLabel from '/src/components/inputs/ReactSelectAdditions/formatOptionWithTooltipLabel';
import { StartupRelationship } from '/../libs/shared-types/src/types/model/StartupRelationship';
import { StartupRelationshipView } from '/../libs/shared-types/src/types/view/StartupRelationshipView';
import {
  getSupportedRelationshipType,
  STARTUP_RELATIONSHIP_TYPE_OPTIONS,
  StartupRelationshipType,
} from '/../libs/shared-types/src/constants/StartupRelationshipType';
import { ConnectionDataType } from '/../libs/shared-types/src/constants/ConnectionDataType';
import { PersonDataType } from '/../libs/shared-types/src/constants/PersonDataType';

enum FieldNames {
  ConnectedFounderId = 'connectedFounderId',
  ConnectedOn = 'connectedOn',
  ConnectionDataType = 'connectionDataType',
  IsConnectedOnLinkedIn = 'isConnectedOnLinkedIn',
  IsIntroNeeded = 'isIntroNeeded',
  LastContactedOn = 'lastContactedOn',
  Strength = 'strength',
  Type = 'type',
}

const validationSchema = yup.object({
  [FieldNames.ConnectedFounderId]: yup.string().nullable(),
  [FieldNames.ConnectedOn]: yup
    .date()
    .when(FieldNames.LastContactedOn, {
      is: (lastContactedOn: Date | '') => {
        return lastContactedOn !== '' && lastContactedOn !== undefined;
      },
      then: (schema) =>
        schema.max(
          yup.ref(FieldNames.LastContactedOn),
          'Must be before or equal to Last Contacted date',
        ),
    })
    .max(new Date(), 'Future dates are not allowed')
    .nullable(),
  [FieldNames.IsConnectedOnLinkedIn]: yup.boolean().nullable(),
  [FieldNames.IsIntroNeeded]: yup.boolean().nullable(),
  [FieldNames.LastContactedOn]: yup
    .date()
    // .min(yup.ref(FieldNames.ConnectedOn), 'Must be after Connection Since')
    .max(new Date(), 'Future dates are not allowed')
    .nullable(),
  [FieldNames.Strength]: yup.number().nullable(),
  [FieldNames.Type]: yup
    .string()
    .test({
      name: 'invalidRelationshipTypeForConnection',
      message: `This relationship type is not valid for this connection`,
      test(value) {
        if (
          value &&
          !getSupportedRelationshipType(
            this.parent[FieldNames.ConnectionDataType],
          ).includes(value as StartupRelationshipType)
        ) {
          return false;
        }
        return true;
      },
    })
    .required('Required'),
});

interface UpdateStartupRelationshipDialogProps {
  connectionName: string;
  connectionDataType: ConnectionDataType;
  relationship?: StartupRelationshipView;
  onCancel: () => void;
  onSave: (update: Partial<StartupRelationship>) => Promise<void>;
}

function UpdateStartupRelationshipDialog({
  connectionName,
  connectionDataType,
  relationship,
  onCancel,
  onSave,
}: UpdateStartupRelationshipDialogProps): JSX.Element {
  const [initialValues, setInitialValues] = useState<any>({
    [FieldNames.ConnectedFounderId]: relationship?.connectedFounderId ?? '',
    [FieldNames.ConnectedOn]: relationship?.connectedOn
      ? formatDatePickerInput(relationship?.connectedOn)
      : '',
    [FieldNames.ConnectionDataType]: connectionDataType,
    [FieldNames.IsConnectedOnLinkedIn]:
      relationship?.isConnectedOnLinkedIn ?? '',
    [FieldNames.IsIntroNeeded]: relationship?.isIntroNeeded ?? '',
    [FieldNames.LastContactedOn]: relationship?.lastContactedOn
      ? formatDatePickerInput(relationship?.lastContactedOn)
      : '',
    [FieldNames.Strength]: relationship?.strength?.toString() ?? '',
    [FieldNames.Type]: relationship?.type ?? '',
  });
  const [errorMessage, setErrorMessage] = useState('');

  const labelForConnectionType =
    connectionDataType === PersonDataType.Person ? 'person' : 'investor';

  const initialTouched = Object.keys(initialValues).reduce<
    Record<string, boolean>
  >((acc, key) => {
    acc[key] = true;
    return acc;
  }, {});

  async function onSubmit(
    values: FormikValues,
    { setSubmitting }: FormikHelpers<any>,
  ) {
    setSubmitting(true);
    try {
      let cleanValues = removeUnsetOptionalValues(values);
      cleanValues = {
        ...cleanValues,
        [FieldNames.ConnectedOn]: cleanValues.connectedOn
          ? convertDatePickerInputToUtc(new Date(cleanValues.connectedOn))
          : null,
        [FieldNames.LastContactedOn]: cleanValues.lastContactedOn
          ? convertDatePickerInputToUtc(new Date(cleanValues.lastContactedOn))
          : null,
      };

      Logger.info(cleanValues);

      await onSave(cleanValues);
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <div className="w-screen bg-white p-4 sm:max-w-2xl sm:p-7">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        initialTouched={initialTouched}
        validateOnMount
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ values, dirty, isSubmitting, isValid }) => (
          <Form>
            <header className="relative mb-6 items-center justify-between sm:flex sm:flex-row">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Update relationship with {connectionName}
                {relationship?.updatedOn && (
                  <p className="text-sm font-normal text-gray-600">
                    Last updated <DateString date={relationship?.updatedOn} />
                  </p>
                )}
              </h3>

              <div className="my-4 flex flex-col space-y-3 sm:my-0 sm:ml-4 sm:flex-row sm:space-x-3 sm:space-y-0">
                <button
                  type="button"
                  onClick={() => onCancel()}
                  className="app-button--neutral justify-center truncate"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="app-button--green justify-center truncate"
                  disabled={!isValid || !dirty || isSubmitting}
                >
                  Save
                  {isSubmitting && (
                    <div className="ml-2">
                      <LoadingSpinner color="white" />
                    </div>
                  )}
                </button>
              </div>
            </header>

            <section className="">
              <Field
                className="custom-select"
                component={CustomSelect}
                label="Relationship Type"
                secondaryLabel={`What best describes your relationship with this ${labelForConnectionType}?`}
                closeMenuOnSelect
                name={FieldNames.Type}
                options={STARTUP_RELATIONSHIP_TYPE_OPTIONS.filter((x) =>
                  getSupportedRelationshipType(connectionDataType).includes(
                    x.value as StartupRelationshipType,
                  ),
                )}
                formatOptionLabel={(
                  option: TooltipSelectOption,
                  formatOptionLabelMeta: FormatOptionLabelMeta<any>,
                ) => (
                  <FormatOptionWithTooltipLabel
                    option={option}
                    formatOptionLabelMeta={formatOptionLabelMeta}
                  />
                )}
                placeholder="Select..."
              />

              <Field
                className="custom-select"
                component={CustomSelect}
                label="Connected Founder"
                secondaryLabel={`Who is the founder most closely connected to this ${labelForConnectionType}?`}
                tooltip="You can add founders by editing your Company One-Pager"
                closeMenuOnSelect
                isClearable
                name={FieldNames.ConnectedFounderId}
                options={(relationship?.startupFounders ?? []).map(
                  (founder) => ({
                    value: founder._id,
                    label: founder.firstName + ' ' + founder.lastName,
                  }),
                )}
                placeholder="Select founder..."
              />

              <Field
                component={FormikInput}
                label="Connection Since"
                secondaryLabel="When did you first meet or connect?"
                max={formatCurrentDate()}
                name={FieldNames.ConnectedOn}
                placeholder="mm/dd/yyyy"
                type="date"
              />

              <Field
                component={FormikInput}
                label="Last Contacted"
                secondaryLabel="When did you last communicate?"
                max={formatCurrentDate()}
                name={FieldNames.LastContactedOn}
                placeholder="mm/dd/yyyy"
                type="date"
              />

              <Field
                className="custom-select"
                component={CustomSelect}
                label="How strong is this relationship?"
                isClearable
                closeMenuOnSelect
                name={FieldNames.Strength}
                options={RELATIONSHIP_STRENGTH_OPTIONS}
                formatOptionLabel={(
                  option: TooltipSelectOption,
                  formatOptionLabelMeta: FormatOptionLabelMeta<any>,
                ) => (
                  <FormatOptionWithTooltipLabel
                    option={option}
                    formatOptionLabelMeta={formatOptionLabelMeta}
                  />
                )}
                placeholder="Select..."
              />

              <BooleanSelectInput
                label="Are you connected on LinkedIn?"
                name={FieldNames.IsConnectedOnLinkedIn}
                isClearable
              />

              <BooleanSelectInput
                label={`Do you need an intro to this ${labelForConnectionType}?`}
                name={FieldNames.IsIntroNeeded}
                isClearable
              />
            </section>

            <div className="my-4">
              <Alert
                color="red"
                alertType="Error"
                content={errorMessage}
                isShown={errorMessage !== ''}
                onClose={() => setErrorMessage('')}
              />
            </div>

            <DebugRender>
              <Disclosure>
                <Disclosure.Button className="app-button--neutral">
                  <pre>Formik Values:</pre>
                </Disclosure.Button>
                <Disclosure.Panel>
                  <pre className="mb-24">{JSON.stringify(values, null, 2)}</pre>
                </Disclosure.Panel>
              </Disclosure>
            </DebugRender>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default UpdateStartupRelationshipDialog;
