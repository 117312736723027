import { BillingFrequency } from './BillingFrequency';
import { EnvironmentTypes } from './EnvironmentTypes';
import { SubscriptionTiers } from './SubscriptionTiers';

const ProductionPriceIds = {
  PRODUCTION_INVESTOR_PRO_PRICE_ID_ANNUALLY: 'price_1NXDH7Bx9OgURn8e68kCSpeK',
  PRODUCTION_INVESTOR_PRO_PRICE_ID_MONTHLY: 'price_1NXDH7Bx9OgURn8emAEPisIZ',
  PRODUCTION_STARTUP_PRO_PRICE_ID_ANNUALLY: 'price_1QAHuxBx9OgURn8e13pwOz81',
  PRODUCTION_STARTUP_PRO_PRICE_ID_MONTHLY: 'price_1QAHuMBx9OgURn8ez6WgaWsz',
};

const DevPriceIds = {
  DEV_INVESTOR_PRO_PRICE_ID_ANNUALLY: 'price_1NXXRrBx9OgURn8eEm5gXNsb',
  DEV_INVESTOR_PRO_PRICE_ID_MONTHLY: 'price_1NXXRrBx9OgURn8eAVtDzUZN',
  DEV_STARTUP_PRO_PRICE_ID_ANNUALLY: 'price_1QAIH1Bx9OgURn8eZ9Zbnm2U',
  DEV_STARTUP_PRO_PRICE_ID_MONTHLY: 'price_1QAIGtBx9OgURn8eQlHBHftH',
};

export function getPriceId(
  env: string | undefined,
  billingFrequency: BillingFrequency,
  subscriptionTier: SubscriptionTiers
): string {
  if (!env) {
    throw Error('Cannot compute price because env is undefined');
  }

  if (env === EnvironmentTypes.Production) {
    if (
      subscriptionTier === SubscriptionTiers.StartupPro &&
      billingFrequency === BillingFrequency.Annually
    ) {
      return ProductionPriceIds.PRODUCTION_STARTUP_PRO_PRICE_ID_ANNUALLY;
    }
    if (
      subscriptionTier === SubscriptionTiers.StartupPro &&
      billingFrequency === BillingFrequency.Monthly
    ) {
      return ProductionPriceIds.PRODUCTION_STARTUP_PRO_PRICE_ID_MONTHLY;
    }

    if (
      subscriptionTier === SubscriptionTiers.InvestorPro &&
      billingFrequency === BillingFrequency.Annually
    ) {
      return ProductionPriceIds.PRODUCTION_INVESTOR_PRO_PRICE_ID_ANNUALLY;
    }
    if (
      subscriptionTier === SubscriptionTiers.InvestorPro &&
      billingFrequency === BillingFrequency.Monthly
    ) {
      return ProductionPriceIds.PRODUCTION_INVESTOR_PRO_PRICE_ID_MONTHLY;
    }
  }

  if (env === EnvironmentTypes.Development || env === EnvironmentTypes.Local) {
    if (
      subscriptionTier === SubscriptionTiers.StartupPro &&
      billingFrequency === BillingFrequency.Annually
    ) {
      return DevPriceIds.DEV_STARTUP_PRO_PRICE_ID_ANNUALLY;
    }
    if (
      subscriptionTier === SubscriptionTiers.StartupPro &&
      billingFrequency === BillingFrequency.Monthly
    ) {
      return DevPriceIds.DEV_STARTUP_PRO_PRICE_ID_MONTHLY;
    }

    if (
      subscriptionTier === SubscriptionTiers.InvestorPro &&
      billingFrequency === BillingFrequency.Annually
    ) {
      return DevPriceIds.DEV_INVESTOR_PRO_PRICE_ID_ANNUALLY;
    }
    if (
      subscriptionTier === SubscriptionTiers.InvestorPro &&
      billingFrequency === BillingFrequency.Monthly
    ) {
      return DevPriceIds.DEV_INVESTOR_PRO_PRICE_ID_MONTHLY;
    }
  }
  throw Error('Account/environment type not supported');
}
