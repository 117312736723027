import React from 'react';

import TabsHeader, { TabHeader } from '/src/components/tabs/TabsHeader';
import { formatImageAddress } from '/src/util/formatting/strings';
import { PublicStartup } from '/../libs/shared-types/src/types/view/startup/PublicStartup';
import NavigationBackButton from '/src/components/utility/NavigationBackButton';
import SquaredLogo from '/src/components/SquaredLogo';

interface DealPreviewDetailHeaderProps {
  startup: PublicStartup;
  tabs: TabHeader[];
  actionButton: JSX.Element;
}

function DealPreviewDetailHeader({
  startup,
  tabs,
  actionButton,
}: DealPreviewDetailHeaderProps): JSX.Element {
  return (
    <header className="sticky top-0 z-40 bg-gray-100 sm:pt-6">
      <NavigationBackButton className="-mt-4 mb-2" />
      <div className="relative mx-auto max-w-full md:flex md:items-center md:justify-between md:space-x-5">
        <div className="flex items-center space-x-5">
          <div className="size-24 shrink-0">
            <SquaredLogo
              containerClassName="size-24"
              src={formatImageAddress(startup.logoKey)}
              alt={`${startup.name} logo`}
            />
          </div>

          <div className="space-y-1">
            <div className="inline-flex items-center space-x-2">
              <h2 className="truncate text-xl font-bold text-gray-900">
                {startup.name}
              </h2>
            </div>

            <div className="flex flex-row items-center space-x-2">
              <span className="hyperlink text-sm">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={startup.website}
                >
                  {startup.website.split('//').length > 0
                    ? startup.website.split('//')[1]
                    : startup.website}
                </a>
              </span>
            </div>
          </div>
        </div>

        <aside className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
          {actionButton}
        </aside>
      </div>

      <TabsHeader tabs={tabs} />
    </header>
  );
}

export default DealPreviewDetailHeader;
