import React, { useContext, useState } from 'react';
import { Route, useParams, Routes, Navigate } from 'react-router-dom';
import { toDashCase } from '/src/util/formatting/strings';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import TabsHeader from '/src/components/tabs/TabsHeader';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import { NOT_FOUND_ROUTE } from '/src/constants/Routes';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import {
  hasFeatureAccess,
  StartupPaidFeatures,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import {
  StartupGetPersonById,
  StartupGetPersonActivity,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import { ArrowsRightLeftIcon } from '@heroicons/react/20/solid';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import AddOrUpdateIntroPathDialog from '../../startup/MyNetwork/AddOrUpdateIntroPathDialog';
import { ToastConfiguration } from '/src/interfaces/ToastConfiguration';
import Toast from '/src/components/notifications/Toast';
import { ERROR, SUCCESS } from '/src/constants/SuccessMessages';
import ConnectionDetailIntroPathsTab from '../../startup/ConnectionDetail/tabs/ConnectionDetailIntroPathsTab';
import useGetInvestorIntroPathsByConnection from '../../../hooks/useGetInvestorIntroPathsByConnection';
import { PersonView } from '/../libs/shared-types/src/types/view/PersonView';
import { PersonDataType } from '/../libs/shared-types/src/constants/PersonDataType';
import ImportPersonDialog from '../../startup/MyNetwork/ImportPersonDialog';
import PersonDetailAboutCard from './PersonDetailAboutCard';
import { StartupRelationshipView } from '../../../types/view/StartupRelationshipView';
import PersonActivityTab from './tabs/PersonActivityTab';
import PersonDetailHeader from './PersonDetailHeader';
import useGetViewSessionByConnection from '/src/hooks/useGetViewSessionByConnection';
import ViewSessionsByConnectionTab from '../../investor/InvestorDetail/tabs/ViewSessionsByConnectionTab';

function PersonDetailRoute(): JSX.Element {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [person, setPerson] = useState<PersonView>();
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const [startupRelationship, setStartupRelationship] =
    useState<StartupRelationshipView>();
  const [isFirstActivityLoading, setIsFirstActivityLoading] = useState(true);

  const [modalConfig, setModalConfig] = useState<{
    type: '' | 'edit' | 'introPath';
    isModalOpen: boolean;
  }>({
    type: '',
    isModalOpen: false,
  });

  const [toastConfig, setToastConfig] = useState<ToastConfiguration>();

  const {
    isLoading: isLoadingInvestorIntroPaths,
    introPathsTowardConnection,
    introPathsFacilitatedByConnection,
    totalCount: totalIntroPathCount,
    introPathsFacilitatedByConnectionCount,
    introPathsTowardConnectionCount,
    refetch: refetchInvestorIntroPaths,
  } = useGetInvestorIntroPathsByConnection({
    connectionDataType: PersonDataType.Person,
    connectionId: id,
  });
  const {
    isFirstLoading: isViewSessionByConnectionFirstLoading,
    response: viewSessionByConnectionData,
    totalCount: viewSessionCount,
  } = useGetViewSessionByConnection({
    connectionDataType: PersonDataType.Person,
    connectionId: id,
  });

  const tabs = [
    { name: 'Profile' },
    { name: 'Activity' },
    { name: 'Views', stat: viewSessionCount },
    { name: 'Intro Paths', stat: totalIntroPathCount },
  ];

  function closeModal() {
    setModalConfig({ type: '', isModalOpen: false });
  }

  async function fetchData(isRefreshing = false) {
    if (id === undefined) {
      return;
    }

    setIsLoading(!isRefreshing);

    try {
      const personData = await API.get<PersonView>(
        StartupGetPersonById.buildEndpoint(id),
      );
      setPerson(personData);
      const personActivityData: StartupRelationshipView = await API.get(
        StartupGetPersonActivity.buildEndpoint(id, {
          personType: PersonDataType.Person,
        }),
      );
      setStartupRelationship(personActivityData);
    } catch (error: any) {
      Logger.error(error);
    } finally {
      setIsFirstActivityLoading(false);
      setIsLoading(false);
    }
  }

  // we listen for url changes and re-fetch data
  // if we get a new id
  React.useEffect(() => {
    fetchData();
  }, [id]);

  if (isFirstActivityLoading || isViewSessionByConnectionFirstLoading) {
    return <PageLoadingSpinner message="Loading a valued connection 🤝" />;
  }

  if (!person) {
    return (
      <div className="min-h-screen bg-gray-100">
        <main className="py-10">
          <h3>Oops! The connection you are looking for doesn&apos;t exist.</h3>
        </main>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <main className="pb-8">
        <header className="sticky top-0 z-40 bg-gray-100 sm:pt-6">
          <div className="relative mx-auto max-w-full lg:flex lg:items-center lg:justify-between lg:space-x-5">
            <PersonDetailHeader person={person} />
            <div className="flex space-x-3">
              <button
                className="app-button--primary mt-3 lg:mt-0"
                onClick={() =>
                  setModalConfig({ type: 'introPath', isModalOpen: true })
                }
                disabled={
                  !hasFeatureAccess(
                    StartupPaidFeatures.IntroPath,
                    subscriptionTier,
                  )
                }
              >
                <div className="relative mr-2">
                  <ArrowsRightLeftIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                Add Intro Path
              </button>
            </div>
          </div>

          <TabsHeader tabs={tabs} />
        </header>
        <div className="px-1">
          <>
            <Routes>
              {['', tabs[0].name].map((path) => (
                <Route
                  key={path}
                  path={toDashCase(path)}
                  element={
                    <main className="grid grid-cols-1 gap-4 lg:w-full lg:grid-cols-2">
                      <div className="flex flex-1 flex-col">
                        <div className="mt-4">
                          <PersonDetailAboutCard
                            person={person}
                            onEditClick={() =>
                              setModalConfig({
                                type: 'edit',
                                isModalOpen: true,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col"></div>
                    </main>
                  }
                />
              ))}

              {startupRelationship && person?._id && (
                <Route
                  path={`${toDashCase(tabs[1].name)}/*`}
                  element={
                    <PersonActivityTab
                      startupRelationship={startupRelationship}
                      personId={person._id}
                      personName={person.firstName + ' ' + person.lastName}
                      personDataType={PersonDataType.Person}
                      fetchPersonData={fetchData}
                    />
                  }
                />
              )}
              <Route
                path={`${toDashCase(tabs[2].name)}/*`}
                element={
                  <ViewSessionsByConnectionTab
                    apiResponse={viewSessionByConnectionData}
                    connectionEmail={person?.email}
                    connectionDataType={PersonDataType.Person}
                  />
                }
              />
              <Route
                path={`${toDashCase(tabs[3].name)}/*`}
                element={
                  <ConnectionDetailIntroPathsTab
                    connectionId={person._id}
                    connectionDataType={PersonDataType.Person}
                    connectionName={person.firstName + ' ' + person.lastName}
                    isLoading={isLoadingInvestorIntroPaths}
                    introPathsTowardThem={introPathsTowardConnection}
                    introPathsFacilitatedByThem={
                      introPathsFacilitatedByConnection
                    }
                    facilitatedByThemCount={
                      introPathsFacilitatedByConnectionCount
                    }
                    towardThemCount={introPathsTowardConnectionCount}
                    refetch={refetchInvestorIntroPaths}
                  />
                }
              />
              <Route
                path="*"
                element={<Navigate to={NOT_FOUND_ROUTE} replace />}
              />
            </Routes>
          </>
        </div>
      </main>

      <ModalWrapper open={modalConfig.isModalOpen} onClose={() => closeModal()}>
        {modalConfig.type === 'edit' && (
          <ImportPersonDialog
            person={person}
            onCancel={() => closeModal()}
            onSave={() => {
              closeModal();
              fetchData(true);
            }}
          />
        )}
        {modalConfig.type === 'introPath' && (
          <AddOrUpdateIntroPathDialog
            onCancel={closeModal}
            onSave={() => {
              closeModal();
              refetchInvestorIntroPaths();
            }}
            currentId={person._id}
            currentDataType={PersonDataType.Person}
            currentName={person.name}
          />
        )}
      </ModalWrapper>

      {toastConfig && (
        <Toast
          isShown={toastConfig !== undefined}
          onClose={() => setToastConfig(undefined)}
          title={toastConfig.isError ? ERROR : SUCCESS}
          isError={toastConfig.isError}
          text={toastConfig.message}
        />
      )}
    </div>
  );
}

export default PersonDetailRoute;
