import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { StartupInvestorDiscovery } from '../../../libs/shared-types/src/constants/ApiRoutes';
import API from '../middleware/API';
import { InvestorDatabasePaginatedCollectionResponse } from '../types/view/InvestorDiscoveryView';
import Logger from '../services/logger';
import { HOUR_MS } from '../../../libs/shared-types/src/constants/time';
import { removeUndefinedOrNullProperties } from '../util/removeUndefinedOrNullProperties';
import { SubscriptionTiers } from '../../../libs/shared-types/src/constants/SubscriptionTiers';
import {
  hasFeatureAccess,
  StartupPaidFeatures,
} from '../../../libs/shared-types/src/extensions/SubscriptionAccess';
import { SortOrder } from '../../../libs/shared-types/src/constants/SortOrder';

const INVESTOR_DATABASE_KEY = 'investor_database';
// Cache time > Stale time
const STALE_TIME_INVESTORS_DATABASE = HOUR_MS * 4;
const CACHE_TIME_INVESTORS_DATABASE = STALE_TIME_INVESTORS_DATABASE * 1.25;

export interface UseGetInvestorDatabaseProps {
  subscriptionTier: SubscriptionTiers | undefined;
  page: string | number | null;
  perPage: string | number | null;
  filter: string | null;
  sortKey: string | null;
  sortOrder: string | null;
  fieldFilters: string | null;
}

const useGetInvestorDatabase = ({
  subscriptionTier,
  page,
  perPage,
  filter,
  sortKey,
  sortOrder,
  fieldFilters,
}: UseGetInvestorDatabaseProps) => {
  const cleanParam = removeUndefinedOrNullProperties({
    page,
    perPage,
    filter,
    sortKey,
    sortOrder,
    fieldFilters,
  });
  return useQuery({
    queryKey: [INVESTOR_DATABASE_KEY, cleanParam],
    queryFn: () => fetchInvestors(subscriptionTier, cleanParam),
    staleTime: STALE_TIME_INVESTORS_DATABASE,
    gcTime: CACHE_TIME_INVESTORS_DATABASE,
    placeholderData: keepPreviousData,
  });
};

export default useGetInvestorDatabase;

async function fetchInvestors(
  subscriptionTier: UseGetInvestorDatabaseProps['subscriptionTier'],
  args?: Partial<{
    [K in keyof UseGetInvestorDatabaseProps]:
      | undefined
      | NonNullable<UseGetInvestorDatabaseProps[K]>;
  }>,
) {
  try {
    const fieldFilters = args?.fieldFilters && JSON.parse(args.fieldFilters);

    const canAccessFitScore = hasFeatureAccess(
      StartupPaidFeatures.InvestorFitScore,
      subscriptionTier,
    );
    let parsedSortOrder = Number(args?.sortOrder);
    if (canAccessFitScore && isNaN(Number(parsedSortOrder))) {
      parsedSortOrder = SortOrder.Desc;
    }

    if (!canAccessFitScore && isNaN(Number(parsedSortOrder))) {
      parsedSortOrder = SortOrder.Asc;
    }
    const url = StartupInvestorDiscovery.buildEndpoint(undefined, {
      ...args,
      fieldFilters,
    });

    const data =
      await API.get<InvestorDatabasePaginatedCollectionResponse>(url);
    return data;
  } catch (error: any) {
    Logger.error(error.message);
  }
}
