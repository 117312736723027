import React from 'react';
import {
  ChartPieIcon,
  CurrencyDollarIcon,
  PresentationChartBarIcon,
  GiftIcon,
  Squares2X2Icon,
  EyeIcon,
  NewspaperIcon,
  TableCellsIcon,
  PaperAirplaneIcon,
  FolderIcon,
  UserGroupIcon,
  ArrowsRightLeftIcon,
} from '@heroicons/react/24/outline';
import {
  STARTUP_ACCESS_MANAGER_ROUTE,
  STARTUP_DILUTION_CALCULATOR_ROUTE,
  STARTUP_INVESTOR_LEADS_ROUTE,
  STARTUP_PROFILE_ROUTE,
  STARTUP_ROUND_MANAGER_ROUTE,
  STARTUP_DECK_MANAGER_ROUTE,
  STARTUP_PERKS_ROUTE,
  STARTUP_DASHBOARD_ROUTE,
  STARTUP_DATA_ROOM_ROUTE,
  STARTUP_MY_NETWORK_ROUTE,
  STARTUP_MANAGE_INTRO_PATHS_ROUTE,
} from '/src/constants/Routes';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import CopyTextButton from '../../CopyTextButton';
import { NavLink } from 'react-router-dom';
import { joinClassNames } from '/src/util/formatting/strings';
import { GroupedNavigationItems } from './NavigationItems';

function getGroupedStartupNavigationLinks(
  flowlink: string,
): GroupedNavigationItems[] {
  return [
    {
      groupName: undefined,
      items: [
        {
          navigateTo: STARTUP_DASHBOARD_ROUTE,
          icon: <Squares2X2Icon className="app-nav-icon" />,
          content: (
            <>
              <span>Dashboard</span>
            </>
          ),
        },
      ],
    },
    {
      groupName: 'Raise Capital',
      items: [
        {
          navigateTo: `/app/${AccountTypes.Startup.toLowerCase()}-flowlink`,
          icon: <PaperAirplaneIcon className="app-nav-icon" />,
          content: (
            <>
              <span>Send FlowLink</span>
              <CopyTextButton
                text={flowlink}
                className="ml-auto mr-2 inline-block"
              />
            </>
          ),
        },
        {
          navigateTo: STARTUP_ACCESS_MANAGER_ROUTE,
          icon: <EyeIcon className="app-nav-icon" />,
          content: (
            <>
              <span>Track Views</span>
            </>
          ),
        },
        {
          navigateTo: STARTUP_ROUND_MANAGER_ROUTE,
          icon: <CurrencyDollarIcon className="app-nav-icon" />,
          content: (
            <>
              <span>Manage Round</span>
            </>
          ),
        },
        {
          navigateTo: STARTUP_MANAGE_INTRO_PATHS_ROUTE,
          icon: <ArrowsRightLeftIcon className="app-nav-icon" />,
          content: (
            <>
              <span>Manage Intros</span>
              <span className="ml-1.5 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                New
              </span>
            </>
          ),
        },
      ],
    },
    {
      groupName: 'Prepare Materials',
      items: [
        {
          navigateTo: STARTUP_PROFILE_ROUTE,
          icon: <NewspaperIcon className="app-nav-icon" />,
          content: (
            <>
              <span>Company One-Pager</span>
            </>
          ),
        },
        {
          navigateTo: STARTUP_DECK_MANAGER_ROUTE,
          icon: <PresentationChartBarIcon className="app-nav-icon" />,
          content: (
            <>
              <span>Pitch Deck</span>
            </>
          ),
        },
        {
          navigateTo: STARTUP_DATA_ROOM_ROUTE,
          icon: <FolderIcon className="app-nav-icon" />,
          content: (
            <>
              <span>Data Room</span>
            </>
          ),
        },
      ],
    },
    {
      groupName: 'Plan Your Round',
      items: [
        {
          navigateTo: STARTUP_DILUTION_CALCULATOR_ROUTE,
          icon: <ChartPieIcon className="app-nav-icon" />,
          content: (
            <>
              <span>Dilution Calculator</span>
            </>
          ),
        },
        {
          navigateTo: STARTUP_INVESTOR_LEADS_ROUTE,
          icon: <TableCellsIcon className="app-nav-icon" />,
          content: (
            <>
              <span>Investor Leads</span>
            </>
          ),
        },
        {
          navigateTo: STARTUP_MY_NETWORK_ROUTE,
          icon: <UserGroupIcon className="app-nav-icon" />,
          content: (
            <>
              <span>My Network</span>
              <span className="ml-1.5 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                New
              </span>
            </>
          ),
        },
        {
          navigateTo: STARTUP_PERKS_ROUTE,
          icon: <GiftIcon className="app-nav-icon" />,
          content: (
            <>
              <span>Discounts & Perks</span>
            </>
          ),
        },
      ],
    },
  ];
}

interface StartupNavigationLinksProps {
  flowlink: string;
  closeNav: () => void;
  isMenuExpanded: boolean;
}

export default function StartupNavigationLinks({
  flowlink,
  closeNav,
  isMenuExpanded,
}: StartupNavigationLinksProps): JSX.Element {
  return (
    <nav className="flex-0 relative mt-5 space-y-2.5 px-2">
      {getGroupedStartupNavigationLinks(flowlink).map((link, index) => (
        <section key={link.groupName ?? index}>
          {isMenuExpanded && link.groupName && (
            <span className="px-2 text-xs font-bold uppercase tracking-wide text-gray-500 dark:text-gray-100">
              {link.groupName}
            </span>
          )}
          {!isMenuExpanded && link.groupName && (
            <div className="mx-1 h-1 border-t"></div>
          )}
          {link.items.map((item) => (
            <NavLink
              key={item.navigateTo}
              onClick={closeNav}
              to={item.navigateTo}
              className={({ isActive }) =>
                joinClassNames(
                  isActive
                    ? 'bg-gray-100 text-blue-600 before:absolute before:left-[0.5rem] before:h-4 before:w-1 before:rounded before:bg-blue-500 hover:bg-gray-100 hover:text-blue-600 dark:bg-gray-700 dark:text-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'
                    : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-100 dark:hover:bg-gray-600 dark:hover:text-white',
                  'flex items-center space-x-2 rounded-md px-2 py-2 text-sm font-medium',
                )
              }
            >
              {item.icon}
              {isMenuExpanded ? item.content : <></>}
            </NavLink>
          ))}
        </section>
      ))}
    </nav>
  );
}
