export enum IntroPathStatus {
  NotRequested = 'Not Requested',
  Requested = 'Requested',
  RequestIgnored = 'Request Ignored',
  RequestRefused = 'Request Refused',
  IntroPending = 'Intro Pending',
  OptInIgnored = 'Opt-in Ignored',
  OptInRefused = 'Opt-in Refused',
  IntroMade = 'Intro Made',
}
