import React from 'react';
import TimeDurationString from '../../utility/TimeDurationString';

export type DurationCellProps = {
  durationInMs?: number;
};

function DurationCell({ durationInMs }: DurationCellProps) {
  if (!durationInMs) {
    return <span>-</span>;
  }
  return (
    <div className="app-table-row-text">
      <TimeDurationString timeMs={durationInMs} />
    </div>
  );
}

export default DurationCell;
