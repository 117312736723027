import React, { useContext, useEffect, useRef, useState } from 'react';
import { AccountMetadataContext } from '../contexts/AccountMetadataContext';
import useUrlParams from './useUrlParams';
import { removeUndefinedOrNullProperties } from '../util/removeUndefinedOrNullProperties';
import { StartupGetDealViewSessionsByConnection } from '../../../libs/shared-types/src/constants/ApiRoutes';
import Logger from '../services/logger';
import API from '../middleware/API';
import { ConnectionDataType } from '../../../libs/shared-types/src/constants/ConnectionDataType';
import { SortOrder } from '../../../libs/shared-types/src/constants/SortOrder';
import { StartupDealViewSessionByConnectionPaginatedCollectionResponse } from '/../libs/shared-types/src/types/view/StartupDealViewSessionByConnectionView';
import {
  hasFeatureAccess,
  StartupPaidFeatures,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';

export interface UseGetViewSessionByConnectionProps {
  connectionId?: string;
  connectionDataType: ConnectionDataType;
}

const useGetViewSessionByConnection = ({
  connectionDataType,
  connectionId,
}: UseGetViewSessionByConnectionProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [response, setResponse] =
    useState<StartupDealViewSessionByConnectionPaginatedCollectionResponse>();
  const { subscriptionTier } = useContext(AccountMetadataContext);

  const { getParam } = useUrlParams();
  const page = getParam('page');
  const perPage = getParam('perPage');
  const sortKey = getParam('sortKey') || 'lastViewedOn';
  const sortOrder = getParam('sortOrder') || SortOrder.Desc;
  const fieldFilters = getParam('fieldFilters');

  useEffect(() => {
    async function fetchViewSessionByConnection(
      args: Partial<any>,
    ): Promise<void> {
      if (
        !hasFeatureAccess(
          StartupPaidFeatures.AccessManagerViewSessions,
          subscriptionTier,
        )
      ) {
        setIsFirstLoading(false);
        return;
      }

      setIsLoading(true);

      try {
        const cleanParam = removeUndefinedOrNullProperties(args);
        const parsedFieldFilters = fieldFilters && JSON.parse(fieldFilters);
        const viewSessionByConnectionResponse =
          await API.get<StartupDealViewSessionByConnectionPaginatedCollectionResponse>(
            StartupGetDealViewSessionsByConnection.buildEndpoint(connectionId, {
              ...cleanParam,
              fieldFilters: parsedFieldFilters,
              connectionId,
              connectionDataType,
            }),
          );

        setResponse(viewSessionByConnectionResponse);
      } catch (error: any) {
        Logger.error(error);
      } finally {
        setIsLoading(false);
        setIsFirstLoading(false);
      }
    }

    if (connectionId) {
      fetchViewSessionByConnection({
        page,
        perPage,
        sortKey: sortKey ?? 'date',
        sortOrder: sortOrder ?? SortOrder.Desc,
      });
    }
  }, [
    page,
    perPage,
    sortKey,
    sortOrder,
    connectionId,
    connectionDataType,
    fieldFilters,
    subscriptionTier,
  ]);

  return {
    isLoading,
    isFirstLoading,
    data: response?.results,
    totalCount: response?.totalCount,
    response,
  };
};

export default useGetViewSessionByConnection;
