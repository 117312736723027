import React from 'react';

interface NotificationIndicatorProps {
  count?: number;
  title: string;
}

export const NotificationIndicator = ({
  title,
  count,
}: NotificationIndicatorProps): JSX.Element => {
  return (
    <span
      title={title}
      className="flex h-[1.3rem] w-[1.3rem] items-center justify-center rounded-full bg-red-500 text-center text-xs font-semibold text-white"
    >
      {count}
    </span>
  );
};

export interface NavigationItem {
  navigateTo: string;
  icon: JSX.Element;
  content: JSX.Element;
}

export interface GroupedNavigationItems {
  groupName: string | undefined;
  items: NavigationItem[];
}
