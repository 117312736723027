import { TooltipSelectOption } from '../types/SelectOptions';
import { ConnectionDataType } from './ConnectionDataType';
import { InvestorDataType } from './InvestorDataType';
import { PersonDataType } from './PersonDataType';

export enum StartupRelationshipType {
  CurrentInvestor = 'Current Investor',
  PreviousInvestor = 'Previous Investor',
  ProspectiveInvestor = 'Prospective Investor',
  PassedInvestor = 'Passed Investor',
  Advisor = 'Advisor',
  TeamMember = 'Team Member',
  BusinessPartner = 'Business Partner',
  Connector = 'Connector',
}

export const STARTUP_RELATIONSHIP_TYPE_OPTIONS: TooltipSelectOption[] = [
  {
    value: StartupRelationshipType.CurrentInvestor,
    label: StartupRelationshipType.CurrentInvestor,
    tooltipText: 'An investor who has invested in your current company',
  },
  {
    value: StartupRelationshipType.PreviousInvestor,
    label: StartupRelationshipType.PreviousInvestor,
    tooltipText: 'An investor who has invested in your previous company',
  },
  {
    value: StartupRelationshipType.ProspectiveInvestor,
    label: StartupRelationshipType.ProspectiveInvestor,
    tooltipText:
      'An investor who hasn’t yet invested in your current or previous company',
  },
  {
    value: StartupRelationshipType.PassedInvestor,
    label: StartupRelationshipType.PassedInvestor,
    tooltipText:
      'An investor who has explicitly passed on investing in your current company',
  },
  {
    value: StartupRelationshipType.Advisor,
    label: StartupRelationshipType.Advisor,
    tooltipText: 'A current or past advisor to the company',
  },
  {
    value: StartupRelationshipType.TeamMember,
    label: StartupRelationshipType.TeamMember,
    tooltipText: 'A current or past team member of the company',
  },
  {
    value: StartupRelationshipType.BusinessPartner,
    label: StartupRelationshipType.BusinessPartner,
    tooltipText: 'A business partner from another company you work with',
  },
  {
    value: StartupRelationshipType.Connector,
    label: StartupRelationshipType.Connector,
    tooltipText:
      "A person who doesn't fit any of the other criteria such as a founder of another company, a friend, or a family member",
  },
];

export function getSupportedRelationshipType(
  connectionDataType: ConnectionDataType
): StartupRelationshipType[] {
  switch (connectionDataType) {
    case InvestorDataType.Verified:
    case InvestorDataType.Unverified:
    case InvestorDataType.Fragment:
      return [
        StartupRelationshipType.CurrentInvestor,
        StartupRelationshipType.PreviousInvestor,
        StartupRelationshipType.ProspectiveInvestor,
        StartupRelationshipType.PassedInvestor,
      ];
    case PersonDataType.Person:
      return [
        StartupRelationshipType.Advisor,
        StartupRelationshipType.TeamMember,
        StartupRelationshipType.BusinessPartner,
        StartupRelationshipType.Connector,
      ];
    default:
      return [];
  }
}
