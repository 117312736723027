import React, { useContext, useState } from 'react';
import {
  BoltIcon,
  CalendarDaysIcon,
  LinkIcon,
} from '@heroicons/react/24/outline';
import { LinkedIn, Twitter } from '/src/components/CustomIcons';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import { buildLinkFromToken } from '/src/util/flowlink';
import { ShareIcon } from '@heroicons/react/20/solid';
import Logger from '/src/services/logger';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import CopyTextButton from '/src/components/CopyTextButton';
import MailToLink from '/src/components/utility/MailToLink';

function shareOnSocial(url: string, message: string) {
  return [
    {
      icon: <Twitter />,
      url: `https://www.twitter.com/share?&url=${url}&text=${message}&hashtags=${''}`,
    },
    {
      icon: <LinkedIn />,
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
    },
  ];
}

async function handleShare(shareData: ShareData) {
  try {
    await navigator.share(shareData);
  } catch (error) {
    Logger.error(error);
  }
}

function canShare(shareData: ShareData) {
  if (!navigator.canShare) {
    return false;
  } else if (navigator.canShare(shareData)) {
    return true;
  } else {
    return false;
  }
}

interface ReferralOfferCopy {
  title: string;
  subTitle: string;
  benefits: { icon: JSX.Element; name: string; description: string }[];
  referralEmailSubject: string;
  referralEmailBody: string;
  referralSocialPost: string;
}

function getReferralOfferCopy(
  accountType: AccountTypes | undefined,
  flowlink: string,
): ReferralOfferCopy | undefined {
  switch (accountType) {
    case AccountTypes.Investor:
      return {
        title: 'Refer a friend and earn $10 when they sign up!',
        subTitle: '7 friends = 1 month of Flowlie Pro',
        benefits: [
          {
            icon: <BoltIcon className="h-6 w-6" aria-hidden="true" />,
            name: 'Share the Investment Command center with fellow investors',
            description:
              'Flowlie is better together! When your network is on Flowlie, you can easily share deals with each other in 1 click and get deal sharing recommendations',
          },
        ],
        referralEmailSubject: 'Screen deals faster with Flowlie!',
        referralEmailBody: `Check out Flowlie, it has greatly helped me screen deals and manage my investments. Join for free with my referral link! ${flowlink}`,
        referralSocialPost: `Check out Flowlie, it has greatly helped me screen deals and manage my investments. Join for free with my referral link!`,
      };
    case AccountTypes.Startup:
      return {
        title: 'Refer a friend and earn $10 when they sign up!',
        subTitle: '',
        benefits: [
          {
            icon: <CalendarDaysIcon className="h-6 w-6" aria-hidden="true" />,
            name: 'Help fellow entrepreneurs fundraise faster!',
            description:
              'If Flowlie made a difference in your fundraising, would you help other founders plan their round, prepare their outreach, share their pitch, and track investor engament?',
          },
        ],
        referralEmailSubject: 'Fundraise faster with Flowlie!',
        referralEmailBody: `Check out Flowlie, it has greatly helped me prepare for fundraising and pitch investors. Join for free with my referral link! ${flowlink}`,
        referralSocialPost: `Check out Flowlie, it has greatly helped me prepare for fundraising and pitch investors. Join for free with my referral link!`,
      };
    default:
      return undefined;
  }
}

export function ReferralsOffer() {
  const [referralEmail, setReferralEmail] = useState('');
  const { linkShareToken, accountType } = useContext(AccountMetadataContext);
  const flowlink = buildLinkFromToken(linkShareToken ?? '');

  const offerCopy = getReferralOfferCopy(accountType, flowlink);
  if (!offerCopy) {
    return <></>;
  }

  const socialShareData = {
    title: offerCopy.referralEmailSubject,
    text: offerCopy.referralSocialPost,
    url: flowlink,
  };

  return (
    <main>
      <div className="relative isolate overflow-hidden rounded-lg bg-gray-50 py-16 shadow sm:py-24 lg:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
            <div className="max-w-xl lg:max-w-lg">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {offerCopy.title}
              </h2>
              <p className="mt-4 text-lg leading-8 text-gray-800">
                {offerCopy.subTitle}
              </p>
              <div className="mt-6 flex max-w-md gap-x-4">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="referral-email-address"
                  name="referralEmail"
                  type="email"
                  value={referralEmail}
                  onChange={(e) => setReferralEmail(e.target.value)}
                  required
                  className="min-w-0 flex-auto rounded-md border border-gray-200 bg-white px-3.5 py-2 text-gray-700 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                  placeholder="Your friend's email"
                />
                <MailToLink
                  linkText="Invite"
                  params={{
                    to: referralEmail,
                    subject: offerCopy.referralEmailSubject,
                    body: offerCopy.referralEmailBody,
                  }}
                  className="app-button--primary"
                />
              </div>

              <section className="mt-6 flex max-w-md">
                <ul className="flex items-center space-x-4">
                  {accountType === AccountTypes.Investor && (
                    <>
                      {shareOnSocial(
                        flowlink,
                        offerCopy.referralSocialPost,
                      ).map((social) => (
                        <li key={social.url}>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={social.url}
                            className="hyperlink"
                          >
                            {social.icon}
                          </a>
                        </li>
                      ))}
                      {canShare(socialShareData) === true && (
                        <li>
                          <button onClick={() => handleShare(socialShareData)}>
                            <ShareIcon className="hyperlink h-5 w-5" />
                          </button>
                        </li>
                      )}
                    </>
                  )}
                  <li>
                    <CopyTextButton
                      text={flowlink}
                      icon={
                        <span className="hyperlink px-1 py-0.5 hover:underline">
                          Referral Link
                          <LinkIcon className="ml-2 inline-flex h-5 w-5" />
                        </span>
                      }
                      iconAfterCopy={
                        <span className="hyperlink px-1 py-0.5 hover:underline">
                          Referral Link
                          <LinkIcon className="ml-2 inline-flex h-5 w-5" />
                        </span>
                      }
                    />
                  </li>
                </ul>
              </section>
            </div>
            <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-1 lg:pt-2">
              {offerCopy.benefits.map((benefit) => (
                <div key={benefit.name} className="flex flex-col items-start">
                  <div className="rounded-md bg-gray-900/10 p-2 text-gray-700 ring-1 ring-gray-900/10">
                    {benefit.icon}
                  </div>
                  <dt className="mt-4 font-semibold text-gray-800">
                    {benefit.name}
                  </dt>
                  <dd className="mt-2 leading-7 text-gray-700">
                    {benefit.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        <div
          className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6"
          aria-hidden="true"
        >
          <div
            className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#80ffd7] to-[#9089fc] opacity-30"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
      {accountType === AccountTypes.Startup && (
        <p className="mt-6 text-center text-sm text-gray-600">
          Remember to NOT SHARE your FlowLink publicly on any social media as
          that may constitute general solicitation if you are a US-based private
          company. Only share your FlowLink individually with investors via
          email or any direct messaging.
        </p>
      )}
    </main>
  );
}

export default ReferralsOffer;
