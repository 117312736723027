import {
  INVESTOR_FLOWLINK_MANAGER_ROUTE,
  ACTIVE_DEALFLOW_ROUTE,
  CONTACT_MANAGER_ROUTE,
  STARTUP_ROUND_MANAGER_ROUTE,
  STARTUP_FLOWLINK_MANAGER_ROUTE,
  STARTUP_ACCESS_MANAGER_ROUTE,
  START_ACTIVATION_TOUR_ROUTE,
  REFERRALS_ROUTE,
  STARTUP_PROFILE_ROUTE,
  STARTUP_INVESTOR_LEADS_ROUTE,
} from '../Routes';
import { ActivationTaskView } from '/../libs/shared-types/src/types/view/ActivationTaskView';
import { InvestorActivationTasks } from '/../libs/shared-types/src/constants/UserActivation/InvestorActivationTasks';
import { StartupActivationTasks } from '/../libs/shared-types/src/constants/UserActivation/StartupActivationTasks';
import React from 'react';
import { Task } from '/../libs/shared-types/src/types/model/Task';
import { MIN_DATE } from '/../libs/shared-types/src/constants/MinimumDate';
import { Link, useNavigate } from 'react-router-dom';
import ActivationTaskModal from './ActivationModal';
import { DealActionModals } from '/src/interfaces/ModalConfig';
import Logger from '/src/services/logger';
import { joinClassNames } from '/src/util/formatting/strings';
import { completeActivationTask } from '/src/services/CompleteActivationTask';

interface ActivationTaskMetadata {
  name: string;
  redirectLink: string;
  subtitle?: string;
}

export const MasterMap = new Map<string, ActivationTaskMetadata>();

MasterMap.set(InvestorActivationTasks.ReceiveStartupViaFlowLinkInvestor, {
  name: 'Receive a startup through your FlowLink intake form',
  redirectLink: INVESTOR_FLOWLINK_MANAGER_ROUTE,
});

MasterMap.set(InvestorActivationTasks.UpdateActiveStages, {
  name: 'Update your due diligence stages',
  redirectLink: ACTIVE_DEALFLOW_ROUTE,
});

MasterMap.set(InvestorActivationTasks.FiveDealsInDealflow, {
  name: 'Add 5 deals to your dealflow',
  redirectLink: INVESTOR_FLOWLINK_MANAGER_ROUTE,
});

MasterMap.set(InvestorActivationTasks.ConnectWithFiveInvestors, {
  name: 'Connect with 5 investors',
  redirectLink: CONTACT_MANAGER_ROUTE,
});

MasterMap.set(InvestorActivationTasks.ShareViaDealShareLink, {
  name: 'Share a deal with an investor via DealShare Link',
  redirectLink: ACTIVE_DEALFLOW_ROUTE,
});

MasterMap.set(InvestorActivationTasks.ShareViaDealShareInApp, {
  name: 'Share a deal with an investor in app',
  redirectLink: ACTIVE_DEALFLOW_ROUTE,
});

MasterMap.set(StartupActivationTasks.OpenRound, {
  name: 'Open a round',
  subtitle:
    'Head over to Round Manager to open your first round and start tracking your progress.',
  redirectLink: STARTUP_ROUND_MANAGER_ROUTE,
});

MasterMap.set(StartupActivationTasks.FirstDealViewSession, {
  name: 'Send your deck and one-pager to an investor',
  subtitle:
    'Send your FlowLink to an investor so they can view your deck and company one-pager.',
  redirectLink: STARTUP_FLOWLINK_MANAGER_ROUTE,
});

MasterMap.set(StartupActivationTasks.FiveUniqueDealViewSessions, {
  name: 'Get 5 people to view your deck and one-pager',
  subtitle:
    'Send your FlowLink to 5 people and check their view sessions in the View Tracker.',
  redirectLink: `${STARTUP_ACCESS_MANAGER_ROUTE}/view-sessions`,
});

MasterMap.set(StartupActivationTasks.ReferStartup, {
  name: 'Refer a startup to join Flowlie and earn referral credits',
  subtitle:
    'Know a founder who is actively raising? Help them by sharing the Flowlie Fundraising Hub.',
  redirectLink: REFERRALS_ROUTE,
});

MasterMap.set(StartupActivationTasks.PreviewCompanyOnePager, {
  name: 'Preview your new Company One-Pager',
  subtitle:
    'Explore your company one-pager and update it with the latest information.',
  redirectLink: STARTUP_PROFILE_ROUTE,
});

MasterMap.set(StartupActivationTasks.CompleteNewUserFirstLogInTour, {
  name: 'Take the 30-second activation tour',
  subtitle:
    'Learn the basics of the Flowlie Fundraising Hub by taking the guided activation tour.',
  redirectLink: START_ACTIVATION_TOUR_ROUTE,
});

MasterMap.set(StartupActivationTasks.ViewInvestorDb, {
  name: 'Explore our curated investor database',
  subtitle:
    'Get a head start on your investor research and easily find the best fit for you.',
  redirectLink: STARTUP_INVESTOR_LEADS_ROUTE,
});

MasterMap.set(StartupActivationTasks.FirstTargetList, {
  name: 'Create your first target list',
  subtitle:
    'Use target lists to group investors and begin planning your outreach.',
  redirectLink: `${STARTUP_INVESTOR_LEADS_ROUTE}/target-lists`,
});

MasterMap.set(InvestorActivationTasks.SubmitFeedback, {
  name: 'Tell us how we can do better',
  redirectLink: 'https://flowlie.sleekplan.app/',
});

export function taskToView(task: Task): ActivationTaskView {
  const taskMetadata = MasterMap.get(task.id);
  if (!taskMetadata) {
    throw Error('Cannot find task metadata');
  }
  return {
    id: task.id,
    name: taskMetadata.name,
    redirectLink: taskMetadata.redirectLink,
    subtitle: taskMetadata.subtitle,
    isCompleted: task.completedOn.getTime() !== MIN_DATE.getTime(),
    completedOn: task.completedOn,
  };
}

interface ActivationTaskAction {
  taskId: string;
  isCompleted: boolean;
}

export default function ActivationTaskAction({
  taskId,
  isCompleted,
}: ActivationTaskAction): JSX.Element {
  async function completeSleekPlanTaskOnClick() {
    try {
      await completeActivationTask(InvestorActivationTasks.SubmitFeedback);
      window.open('https://flowlie.sleekplan.app/', '_blank');
    } catch (error: any) {
      Logger.error(error);
    }
  }

  const navigate = useNavigate();

  const taskMetadata = MasterMap.get(taskId);

  if (!taskMetadata) {
    return <></>;
  }

  switch (taskId) {
    case InvestorActivationTasks.ShareViaDealShareLink:
      return (
        <ActivationTaskModal
          name={taskMetadata.name}
          iframeURL="https://scribehow.com/embed/15-second_guide_to_DealShare_Links__lA4-fzLyQWuI6SJdYV11Iw"
          isCompleted={isCompleted}
        />
      );
    case InvestorActivationTasks.ShareViaDealShareInApp:
      return (
        <ActivationTaskModal
          name={taskMetadata.name}
          iframeURL="https://scribehow.com/embed/15-second_guide_to_In-App_deal_sharing_with_Investors__JOlTzRbiT3aGE8_rwEBlhA"
          isCompleted={isCompleted}
        />
      );
    case InvestorActivationTasks.UpdateActiveStages:
      // remote open when clicked.
      return (
        <button
          className={joinClassNames(
            'ml-3 cursor-pointer text-left text-sm font-medium text-gray-700 dark:text-gray-200',
            isCompleted ? 'line-through' : '',
          )}
          onClick={() =>
            // Redirect to ActiveDealflow, and pass a state to toggle open the stage editor dialog
            navigate(ACTIVE_DEALFLOW_ROUTE, {
              state: {
                openDialog: DealActionModals.EditStages,
              },
            })
          }
        >
          {taskMetadata.name}
        </button>
      );

    case InvestorActivationTasks.SubmitFeedback:
      // Div on click completes task
      return (
        <button
          className={joinClassNames(
            'ml-3 cursor-pointer text-left text-sm font-medium text-gray-700 dark:text-gray-200',
            isCompleted ? 'line-through' : '',
          )}
          onClick={completeSleekPlanTaskOnClick}
        >
          {taskMetadata.name}
        </button>
      );

    default:
      return (
        <Link
          to={taskMetadata.redirectLink}
          className={joinClassNames(
            'ml-3 cursor-pointer text-left text-sm font-medium text-gray-700 dark:text-gray-200',
            isCompleted ? 'line-through' : '',
          )}
        >
          {taskMetadata.name}
        </Link>
      );
  }
}
