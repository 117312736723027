/**
 * Removes properties with `null` or `undefined` values from an object. It does
 *
 * This function iterates through the properties of the given object and filters out any properties where the value is either `null` or `undefined`.
 * It returns a new object that includes only those properties with defined values.
 *
 * @param obj - The input object of type `T` from which properties with `null` or `undefined` values will be removed.
 * @returns A new object with the same type as `T`, but with any properties that had `null` or `undefined` values removed.
 * The returned object is of type `Partial<T>`, meaning it includes only a subset of the properties of the original type `T`.
 *
 * @example
 * ```typescript
 * const input = {
 *   a: 1,
 *   b: null,
 *   c: 'text',
 *   d: undefined,
 *   e: ""
 * };
 *
 * const result = removeEmptyProperties(input);
 *
 * console.log(result); // Output: { a: 1, c: 'text', e: "" }
 * ```
 */
export function removeUndefinedOrNullProperties<T extends Record<string, any>>(
  obj: T,
): Partial<{
  [K in keyof T]: undefined | NonNullable<T[K]>;
}> {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value != null),
  ) as Partial<{
    [K in keyof T]: undefined | NonNullable<T[K]>;
  }>;
}
