import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';

import { capitalize, toDashCase } from '/src/util/formatting/strings';
import SecondaryTabsHeader from '/src/components/tabs/SecondaryTabsHeader';
import { NOT_FOUND_ROUTE } from '/src/constants/Routes';
import { ConnectionDataType } from '/../libs/shared-types/src/constants/ConnectionDataType';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import { TabHeader } from '/src/components/tabs/TabsHeader';
import ConnectionDetailIntroPaths from '../ConnectionDetailIntroPaths';
import { IntroPathDirection } from '/../libs/shared-types/src/constants/IntroPathDirection';
import { SubscriptionTiers } from '/../libs/shared-types/src/constants/SubscriptionTiers';
import { SubscriptionCTAPill } from '/src/components/SubscriptionCTA';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import { IntroPathPaginatedCollectionResponse } from '../../../../types/view/IntroPathView';

interface InvestorDetailSharingTabProps {
  isLoading: boolean;
  connectionDataType: ConnectionDataType;
  connectionId: string;
  connectionName: string;
  introPathsTowardThem?: IntroPathPaginatedCollectionResponse;
  introPathsFacilitatedByThem?: IntroPathPaginatedCollectionResponse;
  facilitatedByThemCount?: number;
  towardThemCount?: number;
  refetch: () => void;
}

function ConnectionDetailIntroPathsTab({
  isLoading,
  connectionDataType,
  connectionId,
  connectionName,
  introPathsTowardThem,
  introPathsFacilitatedByThem,
  facilitatedByThemCount,
  towardThemCount,
  refetch,
}: InvestorDetailSharingTabProps): JSX.Element {
  const capitalizedName = connectionName.split(' ').map(capitalize).join(' ');
  const towardTabName = `Toward ${capitalizedName}`;
  const facilitatedByTabName = `Facilitated By ${capitalizedName}`;
  const [tabs, setTabs] = useState<TabHeader[]>([
    { name: towardTabName, stat: 0 },
    { name: facilitatedByTabName, stat: 0 },
  ]);
  const { subscriptionTier } = useContext(AccountMetadataContext);

  useEffect(() => {
    setTabs(
      tabs.map((tab) => ({
        ...tab,
        stat:
          tab.name === towardTabName ? towardThemCount : facilitatedByThemCount,
      })),
    );
  }, [towardTabName, towardThemCount, facilitatedByThemCount]);

  if (subscriptionTier === SubscriptionTiers.StartupFree) {
    return (
      <div className="mt-6 w-full rounded-md bg-white p-8 shadow">
        <p className="font-medium">
          Map your connections to find the strongest intro path to this person
        </p>
        <ul className="mt-4 list-inside list-disc">
          <li>Import all your non-investor connections into Flowlie</li>
          <li>Define intro paths between your network and this investor</li>
        </ul>
        <p className="my-6">
          Try Flowlie Pro for free for 14 days to unlock mapping intro paths
        </p>
        <div className="w-max">
          <SubscriptionCTAPill
            id="cta_connection_detail_intro_paths_tab"
            text="Add Intro Paths"
          />
        </div>
      </div>
    );
  }

  if (isLoading) {
    return <PageLoadingSpinner />;
  }

  if (!introPathsFacilitatedByThem || !introPathsTowardThem) {
    return <>ERROR: No Intro Paths could be loaded</>;
  }

  return (
    <main className="mx-auto mt-8 max-w-full">
      <section className="flex items-center justify-between">
        <SecondaryTabsHeader tabs={tabs} />
      </section>
      <div className="mt-4">
        <Routes>
          {['', tabs[0].name].map((path) => (
            <Route
              key={path}
              path={toDashCase(path)}
              element={
                <ConnectionDetailIntroPaths
                  currentId={connectionId}
                  currentDataType={connectionDataType}
                  currentName={connectionName}
                  introPathDirection={IntroPathDirection.Toward}
                  refetch={refetch}
                  apiResponse={introPathsTowardThem}
                />
              }
            />
          ))}
          <Route
            path={toDashCase(tabs[1].name)}
            element={
              <ConnectionDetailIntroPaths
                currentId={connectionId}
                currentDataType={connectionDataType}
                currentName={connectionName}
                introPathDirection={IntroPathDirection.FacilitatedBy}
                refetch={refetch}
                apiResponse={introPathsFacilitatedByThem}
              />
            }
          />
          <Route path="*" element={<Navigate to={NOT_FOUND_ROUTE} replace />} />
        </Routes>
      </div>
    </main>
  );
}

export default ConnectionDetailIntroPathsTab;
