import React, { useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import { SelectOption } from '../../types/SelectOptions';
import { USER_INPUT_DEBOUNCE_DELAY_MS } from '/src/constants/UserInputDebounceDelay';
import { debounce } from '/src/util/debounce';
import FormikErrorMessage from './FormikErrorMessage';
import FormikDebugger from './FormikDebugger';
import { FieldProps } from 'formik';
import { FormatOptionLabelMeta, MultiValue, SingleValue } from 'react-select';
import Tooltip from '../utility/Tooltip';

interface CustomSelectAsyncProps extends FieldProps {
  name: string;
  label: string;
  secondaryLabel?: string;
  debounceDelayMs?: number;
  className?: string;
  classNamePrefix?: string;
  closeMenuOnSelect?: boolean;
  components?: any;
  defaultValue?: any;
  formatOptionLabel?:
    | ((
        data: any,
        formatOptionLabelMeta: FormatOptionLabelMeta<any>,
      ) => React.ReactNode)
    | undefined;
  tooltip?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  disabledOptions?: string[];
  placeholder?: string;
  fetchOptions: (inputValue: string) => Promise<
    {
      label: string;
      value: string;
      details?: unknown;
    }[]
  >;
}

const CustomSelectAsync = ({
  name,
  label,
  secondaryLabel,
  field,
  form,
  meta,
  tooltip,
  className,
  classNamePrefix,
  defaultValue,
  placeholder = 'Search...',
  formatOptionLabel = undefined,
  fetchOptions,
  debounceDelayMs = USER_INPUT_DEBOUNCE_DELAY_MS,
  closeMenuOnSelect = false,
  components = undefined,
  isClearable = false,
  isDisabled = false,
  disabledOptions = [],
}: CustomSelectAsyncProps) => {
  const onChange = (
    option:
      | SingleValue<SelectOption & { details: object }>
      | MultiValue<SelectOption & { details: object }>,
  ) => {
    if (!option) {
      form.setFieldValue(field.name, '');
      return;
    }

    form.setFieldValue(field.name, option);
  };

  const loadOptionsDebounced = useCallback(
    debounce(
      (inputValue: string, callback: (options: SelectOption[]) => void) => {
        fetchOptions(inputValue).then((options) => callback(options));
      },
      debounceDelayMs,
    ),
    [],
  );

  return (
    <section className="form-section">
      <label htmlFor={name} className="input-label">
        <span className="flex flex-row items-center">
          {label}
          {tooltip && <Tooltip tooltipText={tooltip} />}
        </span>
        {secondaryLabel && (
          <p className="text-xs text-gray-800">{secondaryLabel}</p>
        )}
        <AsyncSelect
          id={name}
          cacheOptions
          defaultOptions
          defaultValue={defaultValue}
          loadOptions={loadOptionsDebounced}
          onChange={onChange}
          formatOptionLabel={formatOptionLabel}
          onBlur={() => form.setFieldTouched(field.name, true)}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPlacement="auto"
          components={components}
          className={className}
          classNamePrefix={classNamePrefix}
          closeMenuOnSelect={closeMenuOnSelect}
          isClearable={isClearable}
          isDisabled={isDisabled}
          isOptionDisabled={(option) => disabledOptions.includes(option.value)}
          placeholder={placeholder}
        />
      </label>
      <FormikErrorMessage field={field} form={form} meta={meta} />
      <FormikDebugger field={field} form={form} meta={meta} />
    </section>
  );
};

export default CustomSelectAsync;
