import React, { useState } from 'react';
import EmptyState from '/src/components/notifications/EmptyState';
import { ArrowsRightLeftIcon } from '@heroicons/react/20/solid';
import {
  INTRO_PATH_BY_CONNECTION_ACTION,
  IntroPathByConnectionActionProps,
  IntroPathByConnectionMapper,
} from '../MyNetwork/IntroPathByConnectionMapper';
import BasePaginatedTable from '/src/components/table/baseTable/BasePaginatedTable';
import {
  IntroPathPaginatedCollectionResponse,
  IntroPathView,
} from '/../libs/shared-types/src/types/view/IntroPathView';
import { IntroPathDirection } from '/../libs/shared-types/src/constants/IntroPathDirection';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import AddOrUpdateIntroPathDialog from '../MyNetwork/AddOrUpdateIntroPathDialog';
import {
  ConnectionDataType,
  getConnectionDataType,
} from '../../../../../libs/shared-types/src/constants/ConnectionDataType';
import SimpleDialog from '/src/components/notifications/SimpleDialog';
import {
  addOrUpdateIntroPath,
  deleteIntroPathById,
} from '../../../../src/services/startupService';
import { INVESTOR_LIST_BY_ID_KEY } from '/src/hooks/useGetInvestorListById';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getConnectionDetailUrl } from '/src/util/navigation';
import { PersonDataType } from '/../libs/shared-types/src/constants/PersonDataType';
import Toast from '/src/components/notifications/Toast';
import { ToastConfiguration } from '/src/interfaces/ToastConfiguration';
import { ERROR, SUCCESS } from '/src/constants/SuccessMessages';
import { getConnectionPrefixByDirection } from '/src/util/introPath';

interface ConnectionDetailIntroPathsProps {
  introPathDirection: IntroPathDirection;
  apiResponse?: IntroPathPaginatedCollectionResponse;
  currentId: string;
  currentDataType: ConnectionDataType;
  currentName: string;
  refetch: () => void;
}

function ConnectionDetailIntroPaths({
  introPathDirection,
  apiResponse,
  currentId,
  currentDataType,
  currentName,
  refetch,
}: ConnectionDetailIntroPathsProps): JSX.Element {
  const [modalConfig, setModalConfig] = useState<{
    type: '' | 'addOrUpdateIntroPath' | 'delete';
    isModalOpen: boolean;
    introPath: IntroPathView | undefined;
  }>({
    type: '',
    isModalOpen: false,
    introPath: undefined,
  });
  const [toastConfig, setToastConfig] = useState<ToastConfiguration>();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const displayCurrentConnectionType =
    currentDataType === PersonDataType.Person ? 'person' : 'investor';

  if (!apiResponse) {
    return <></>;
  }

  const connectionPrefix = getConnectionPrefixByDirection(introPathDirection);

  function invalidateTargetLists() {
    queryClient.invalidateQueries({ queryKey: [INVESTOR_LIST_BY_ID_KEY] });
  }

  function closeModal() {
    setModalConfig({
      type: '',
      isModalOpen: false,
      introPath: undefined,
    });
  }

  function onRowClick(rowKey: string) {
    const introPath = apiResponse?.results.find((path) => path._id === rowKey);
    if (!introPath) {
      return;
    }

    const connection =
      introPathDirection === IntroPathDirection.Toward
        ? introPath.sourceConnection
        : introPath.targetConnection;

    const datatype = getConnectionDataType(connection);
    const detailUrl = getConnectionDetailUrl(datatype, connection._id);
    navigate(detailUrl);
  }

  const onAction = async ({
    type,
    payload,
  }: IntroPathByConnectionActionProps) => {
    if (type === INTRO_PATH_BY_CONNECTION_ACTION.DELETE_INTRO_PATH) {
      setModalConfig({
        type: 'delete',
        isModalOpen: true,
        introPath: payload.introPath,
      });
    }

    if (type === INTRO_PATH_BY_CONNECTION_ACTION.EDIT_INTRO_PATH) {
      setModalConfig({
        type: 'addOrUpdateIntroPath',
        isModalOpen: true,
        introPath: payload.introPath,
      });
    }

    if (type === INTRO_PATH_BY_CONNECTION_ACTION.EDIT_INTRO_PATH_WITHOUT_FORM) {
      const { introPath } = payload;
      if (introPath) {
        await addOrUpdateIntroPath(introPath);
        refetch();
      }
    }
  };

  const handleDeleteInvestor = async (introPathId?: string) => {
    if (introPathId) {
      const { isSuccess } = await deleteIntroPathById(introPathId);

      if (isSuccess) {
        invalidateTargetLists();
        closeModal();
        setToastConfig({
          isError: false,
          message: 'Intro path deleted',
        });
        refetch();
      }
    }
  };

  const rows = new IntroPathByConnectionMapper(
    introPathDirection,
    onAction,
  ).mapAllTo(apiResponse.results);

  return (
    <>
      {apiResponse.totalCount === 0 && apiResponse.filter === '' && (
        <EmptyState
          title={`You have not yet mapped any intro paths ${introPathDirection.toLowerCase()} this ${displayCurrentConnectionType}`}
          subTitle={`Create an intro path to begin mapping connections between ${introPathDirection === IntroPathDirection.Toward ? `your network and this ${displayCurrentConnectionType}` : `this ${displayCurrentConnectionType} and other target ${displayCurrentConnectionType}s`}`}
          icon={
            <ArrowsRightLeftIcon className="mx-auto h-12 w-12 text-gray-400" />
          }
          actionButton={
            <button
              type="button"
              className="app-button--primary"
              onClick={() =>
                setModalConfig({
                  type: 'addOrUpdateIntroPath',
                  isModalOpen: true,
                  introPath: undefined,
                })
              }
            >
              Add Intro Path
            </button>
          }
        />
      )}

      {(apiResponse.totalCount > 0 || apiResponse.filter !== '') && (
        <>
          <BasePaginatedTable
            tableName={`${introPathDirection}-intro-path-table`}
            headerClassName="top-[13.5rem]"
            onRowClick={onRowClick}
            enableSort
            headerStructure={[
              {
                headerKey: `${connectionPrefix}Name`,
                label: 'Name',
              },
              {
                headerKey: `${connectionPrefix}StartupRelationshipType`,
                label: 'Type',
              },
              {
                headerKey: `${connectionPrefix}StartupRelationshipStrength`,
                label: 'Relationship Strength',
              },
              {
                headerKey: `${connectionPrefix}Firm`,
                label: 'Firm & Role',
              },
              {
                headerKey: 'targetIntroRelationshipType',
                label: 'Intro Type',
              },
              {
                headerKey: 'targetIntroRelationshipStrength',
                label: 'Intro Strength',
              },
              {
                headerKey: 'status',
                label: 'Status',
              },
              {
                headerKey: 'impactScore',
                label: 'Path Impact',
                tooltip:
                  'Represents how likely the intro path will result in a high-quality introduction to the target investor',
              },
              {
                headerKey: 'action',
                label: 'Action',
                className: 'sr-only',
              },
            ]}
            rows={rows.map((row) => ({
              rowKey: row.id,
              data: row,
            }))}
            resultCount={apiResponse.totalCount}
            perPage={apiResponse.perPage}
            page={apiResponse.page}
            pageCount={apiResponse.totalPages}
            requestParams={{
              page: apiResponse.page,
              perPage: apiResponse.perPage,
              sortOrder: apiResponse.sortOrder,
              sortKey: apiResponse.sortKey,
              filter: apiResponse.filter,
            }}
          />
        </>
      )}

      <ModalWrapper open={modalConfig.isModalOpen} onClose={() => closeModal()}>
        {modalConfig.type === 'addOrUpdateIntroPath' && (
          <AddOrUpdateIntroPathDialog
            direction={introPathDirection}
            currentId={currentId}
            currentDataType={currentDataType}
            currentName={currentName}
            introPath={modalConfig.introPath}
            onCancel={closeModal}
            onSave={() => {
              closeModal();
              refetch();
            }}
          />
        )}

        {modalConfig.type === 'delete' && modalConfig?.introPath && (
          <SimpleDialog
            onCancel={closeModal}
            onPrimaryAction={() =>
              handleDeleteInvestor(modalConfig.introPath?._id)
            }
            title="Are you sure you want to delete this intro path?"
            text="This action cannot be undone."
            primaryAction="Delete Intro Path"
            color="red"
          />
        )}
      </ModalWrapper>

      {toastConfig && (
        <Toast
          isShown={toastConfig !== undefined}
          onClose={() => setToastConfig(undefined)}
          title={toastConfig.isError ? ERROR : SUCCESS}
          isError={toastConfig.isError}
          text={toastConfig.message}
        />
      )}
    </>
  );
}

export default ConnectionDetailIntroPaths;
