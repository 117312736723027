import React from 'react';
import { FieldProps } from 'formik';
import Select, {
  FormatOptionLabelMeta,
  GroupBase,
  MultiValue,
  OptionsOrGroups,
  SingleValue,
} from 'react-select';
import Tooltip from '/src/components/utility/Tooltip';
import { flattenGroupLabelsFromOptions } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';
import FormikDebugger from './FormikDebugger';
import FormikErrorMessage from './FormikErrorMessage';
import { SelectOption } from '/../libs/shared-types/src/types/SelectOptions';
import Logger from '/src/services/logger';
// import { SelectComponents } from 'react-select/dist/declarations/src/components';
// import { formatGroupLabel } from 'react-select/dist/declarations/src/builtins';

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
  > {
    maxCount?: number;
  }
}

interface CustomSelectProps extends FieldProps {
  className?: string;
  classNamePrefix?: string;
  closeMenuOnSelect?: boolean;
  components?: any;
  disabledOptions?: string[];
  groupLabelFormat?:
    | ((group: GroupBase<SelectOption>) => React.ReactNode)
    | undefined;
  filterOption?: any;
  formatOptionLabel?:
    | ((
        data: any,
        formatOptionLabelMeta: FormatOptionLabelMeta<any>,
      ) => React.ReactNode)
    | undefined;
  isClearable?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  label: string;
  maxCount?: number;
  options: OptionsOrGroups<SelectOption, GroupBase<SelectOption>>;
  placeholder?: string;
  secondaryLabel?: string;
  tooltip?: string;
}

const CustomSelect = ({
  className,
  classNamePrefix,
  closeMenuOnSelect = false,
  components = undefined,
  disabledOptions = [],
  field,
  filterOption,
  form,
  formatOptionLabel = undefined,
  groupLabelFormat = undefined,
  isClearable = false,
  isDisabled = false,
  isMulti = false,
  label,
  meta,
  options,
  maxCount = options.length,
  placeholder = 'Select...',
  secondaryLabel,
  tooltip,
}: CustomSelectProps): JSX.Element => {
  const onChange = (
    option: SingleValue<SelectOption> | MultiValue<SelectOption>,
  ) => {
    if (!option) {
      form.setFieldValue(field.name, '');
      return;
    }

    const value = isMulti
      ? (option as SelectOption[])?.map((item: SelectOption) => item.value)
      : (option as SelectOption)?.value;
    form.setFieldValue(field.name, value);
  };

  const getValue = () => {
    const flatOptions = flattenGroupLabelsFromOptions(options);

    if (!flatOptions) {
      // There are no options in this React Select?
      // We should never get here...
      Logger.warn('No flatOptions', field.name);
      return isMulti ? [] : null;
    }

    if (isMulti) {
      const displayValues = flatOptions.filter(
        (option: { value: any }) => field.value?.indexOf(option.value) >= 0,
      );
      // If no Options found that match the field value, return empty string
      return displayValues.length ? displayValues : '';
    }

    // If no Option found that matches the field value, return empty string
    return (
      flatOptions.find(
        (option: { value: any }) => option.value === field.value,
      ) ?? ''
    );
  };

  return (
    <section className="form-section">
      <label htmlFor={field.name} className="input-label">
        <span className="flex flex-row items-center">
          {label}
          {tooltip && <Tooltip tooltipText={tooltip} />}
        </span>
        {secondaryLabel && (
          <p className="text-xs text-gray-800">{secondaryLabel}</p>
        )}
        <Select
          components={components}
          className={className}
          classNamePrefix={classNamePrefix}
          filterOption={filterOption}
          formatGroupLabel={groupLabelFormat}
          formatOptionLabel={formatOptionLabel}
          id={field.name}
          isClearable={isClearable}
          isDisabled={isDisabled}
          isOptionDisabled={(option) => disabledOptions.includes(option.value)}
          isMulti={isMulti}
          name={field.name}
          maxCount={maxCount}
          closeMenuOnSelect={closeMenuOnSelect}
          onChange={onChange}
          menuPlacement="auto"
          onBlur={() => form.setFieldTouched(field.name, true)}
          options={options}
          placeholder={placeholder}
          value={getValue()}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
      </label>
      <FormikErrorMessage field={field} form={form} meta={meta} />
      <FormikDebugger field={field} form={form} meta={meta} />
    </section>
  );
};

export default CustomSelect;
