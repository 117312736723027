import React, { Fragment, useEffect, useState } from 'react';
import {
  InvestorDiscoveryView,
  InvestorDatabaseFitScoreStats,
} from '/../libs/shared-types/src/types/view/InvestorDiscoveryView';
import {
  PaginatedTableProps,
  isRowBlurred,
} from '/src/components/table/BackendPaginatedTable';
import { getPluralSuffix } from '/src/util/formatting/numbers';
import { SubscriptionCTAPill } from '/src/components/SubscriptionCTA';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { SlideUp } from '/src/components/animations/Slide';
import { InvestorList } from '../../../types/model/InvestorList';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import Logger from '/src/services/logger';
import API from '/src/middleware/API';
import { StartupAddInvestorToLists } from '/../libs/shared-types/src/constants/ApiRoutes';
import { InvestorDataType } from '/../libs/shared-types/src/constants/InvestorDataType';
import AddInvestorToListsDialog from '../../investor/ActiveDealflow/dialogs/AddInvestorToListsDialog';
import { useInvestorDatabaseContext } from './InvestorDatabaseContext';
import { SortOrder } from '/src/interfaces/Sortable';
import {
  INVESTOR_DETAIL,
  STARTUP_INVESTOR_FRAGMENT_DETAIL_ROUTE,
  UNVERIFIED_INVESTOR_DETAIL,
} from '/src/constants/Routes';
import { useNavigate } from 'react-router-dom';
import {
  INVESTOR_ACTION,
  InvestorActionProps,
  InvestorMapper,
} from './InvestorMapper';
import { cn } from '/src/util/cn';
import BasePaginatedTable from '/src/components/table/baseTable/BasePaginatedTable';
import { useQueryClient } from '@tanstack/react-query';
import { INVESTOR_LIST_BY_ID_KEY } from '/src/hooks/useGetInvestorListById';

interface InvestorDatabaseTableInterface extends PaginatedTableProps {
  investors: InvestorDiscoveryView[];
  fitScoreStats: InvestorDatabaseFitScoreStats;
  hasFitScoreAccess: boolean;
  resetSelectedTrigger?: boolean;
  selectedRows: string[];
  setSelectedRows: (rows: string[]) => void;
  investorLists: InvestorList[];
  fetchInvestorLists: () => Promise<void>;
}

function InvestorDatabaseTable({
  investors,
  fitScoreStats,
  parentPage,
  parentTotalCount,
  parentSortOrder,
  parentSortedColumn,
  parentFilter,
  parentPerPage,
  headers,
  parentTotalPages,
  hasFitScoreAccess,
  blurRowsAfterIndex,
  investorLists,
  resetSelectedTrigger,
  fetchInvestorLists,
  selectedRows,
  setSelectedRows,
}: InvestorDatabaseTableInterface): JSX.Element {
  const [isLoadingFitScores, setIsLoadingFitScores] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInvestorId, setSelectedInvestorId] = useState<string>('');
  const { addInvestorToPipeline } = useInvestorDatabaseContext();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const invalidateInvestorListData = (investorListIds: string[]) => {
    for (const investorListId of investorListIds) {
      queryClient.invalidateQueries({
        queryKey: [INVESTOR_LIST_BY_ID_KEY, investorListId],
      });
    }
  };

  const rowBlurClass = (index: number) =>
    isRowBlurred(parentPage, index, blurRowsAfterIndex)
      ? 'transform-gpu blur cursor-default select-none'
      : '';

  const joyrideClass = (index: number) => {
    return index === 0 && parentPage <= 1
      ? 'joyride-investorDatabaseFirstRow'
      : '';
  };

  async function updateListsWithInvestor(
    investorId: string,
    investorListIds: string[],
  ) {
    const investorDataType = investors.find((x) => x._id === investorId)
      ?.isVerified
      ? InvestorDataType.Verified
      : InvestorDataType.Unverified;
    try {
      await API.put(StartupAddInvestorToLists.buildEndpoint(), {
        investorId: investorId,
        investorDataType: investorDataType,
        investorListIds: investorListIds,
      });
      setIsModalOpen(false);
      setSelectedInvestorId('');
      invalidateInvestorListData(investorListIds);
      fetchInvestorLists();
    } catch (error) {
      Logger.error(error);
    }
  }

  useEffect(() => {
    setIsLoadingFitScores(true);
    setTimeout(() => setIsLoadingFitScores(false), 2000);
  }, [parentPage]);

  const onRowClick = (rowKey: string) => {
    if (!hasFitScoreAccess && parentPage > 1) return;
    const investor = investors.find((result) => result._id === rowKey)!;

    switch (investor.investorDataType) {
      case InvestorDataType.Verified:
        navigate(`${INVESTOR_DETAIL}/${investor._id}`);
        break;
      case InvestorDataType.Unverified:
        navigate(`${UNVERIFIED_INVESTOR_DETAIL}/${investor._id}`);
        break;
      case InvestorDataType.Fragment:
        navigate(`${STARTUP_INVESTOR_FRAGMENT_DETAIL_ROUTE}/${investor._id}`);
        break;
      default:
        break;
    }
  };

  const onSelectedChange = (rowsId: string[]) => {
    setSelectedRows(rowsId);
  };

  const onAction = async ({ type, payload }: InvestorActionProps) => {
    if (type === INVESTOR_ACTION.ADD_TO_PIPELINE) {
      const { investorId, investorDataType } = payload;
      await addInvestorToPipeline(investorId, investorDataType, undefined);
    }

    if (type === INVESTOR_ACTION.ADD_TO_TARGET_LIST) {
      const { investorId } = payload;
      setSelectedInvestorId(investorId);
      setIsModalOpen(true);
    }
  };

  // To map api data into cell components
  const rows = new InvestorMapper(
    hasFitScoreAccess,
    !selectedRows.length ? onAction : undefined,
  ).mapAllTo(investors);

  return (
    <>
      <div className="relative">
        {!hasFitScoreAccess && parentPage > 1 && (
          <SlideUp as={Fragment}>
            <div className="absolute left-4 top-[20%] z-30 w-[60%] space-y-4 rounded-lg border-2 border-gray-200 bg-white p-8 shadow-md sm:left-[20%]">
              <p className="font-medium">
                Research the right investors for your round using AI
              </p>
              <ul className="mt-4 list-inside list-disc">
                <li>
                  Discover the highest fit investors for your specific company
                </li>
                <li>Research hundreds of investors from across the world</li>
                <li>
                  Unlock the highest quality database on the market with
                  detailed investor theses and contact information
                </li>
              </ul>
              <p>
                On the free plan you can view only the top 10 highest-fit
                investors for your company. Upgrade to Pro to unlock the
                remaining {parentTotalCount - 10} investors.
              </p>
              <div>
                Flowlie&apos;s AI has found for you:
                {isLoadingFitScores && (
                  <div className="flex items-center space-x-3">
                    <LoadingSpinner color="blue" />
                    <span>
                      Calculating investors that are a good fit for you...
                    </span>
                  </div>
                )}
                {(fitScoreStats.MediumFitCount > 0 ||
                  fitScoreStats.HighFitCount > 0 ||
                  fitScoreStats.VeryHighFitCount > 0) &&
                  !isLoadingFitScores && (
                    <ol className="list-inside list-disc">
                      {fitScoreStats.VeryHighFitCount > 0 && (
                        <li>
                          {fitScoreStats.VeryHighFitCount} investor
                          {getPluralSuffix(fitScoreStats.VeryHighFitCount)} with
                          a Very High Fit
                        </li>
                      )}
                      {fitScoreStats.HighFitCount > 0 && (
                        <li>
                          {fitScoreStats.HighFitCount} investor
                          {getPluralSuffix(fitScoreStats.HighFitCount)} with a
                          High Fit
                        </li>
                      )}
                      {fitScoreStats.MediumFitCount > 0 && (
                        <li>
                          {fitScoreStats.MediumFitCount} investor
                          {getPluralSuffix(fitScoreStats.MediumFitCount)} with a
                          Medium Fit
                        </li>
                      )}
                    </ol>
                  )}
                {fitScoreStats.MediumFitCount < 2 &&
                  fitScoreStats.HighFitCount < 2 &&
                  fitScoreStats.VeryHighFitCount < 2 &&
                  !isLoadingFitScores && (
                    <div>
                      Complete your company one-pager to let Flowlie&apos;s AI
                      recommend you high fit investors. <br />
                      Then, come back here to watch the AI at work!
                    </div>
                  )}
              </div>
              <p className="my-6">
                Try Flowlie Pro for free for 14 days to unlock the full investor
                database, target lists, and the ability to manage your investor
                pipeline.
              </p>
              <div className="w-max">
                <SubscriptionCTAPill
                  id="cta_investor_database_results"
                  text="Discover the highest fit investors for your company"
                />
              </div>
            </div>
          </SlideUp>
        )}

        <BasePaginatedTable
          tableName="Investor list"
          resetSelectedTrigger={resetSelectedTrigger}
          onSelectedChange={onSelectedChange}
          onRowClick={onRowClick}
          enableSelect={hasFitScoreAccess}
          enableSort={hasFitScoreAccess}
          headerStructure={[
            {
              headerKey: 'name',
              label: 'Name',
            },
            {
              headerKey: 'type',
              label: 'Type',
            },
            {
              headerKey: 'firm',
              label: 'Firm & Role',
            },
            {
              headerKey: 'invAuthority',
              label: 'Authority',
              tooltip:
                'Represents the seniority of the investor at their current firm. More senior investors make decisions on investments.',
            },
            {
              headerKey: 'fitScore',
              label: 'Fit',
              tooltip:
                "Represents how well your company fits in the investor's thesis",
            },
            {
              headerKey: 'checkRangeMin',
              label: 'Check Size',
            },
            {
              headerKey: 'leadsRounds',
              label: 'Leads Rounds',
            },
            {
              headerKey: 'isOpenToColdInbound',
              label: 'Cold Inbound',
            },
            {
              headerKey: 'action',
              label: 'Action',
              className: 'sr-only',
            },
          ]}
          rows={rows.map((row, index) => ({
            rowKey: row.id,
            data: row,
            className: cn(rowBlurClass(index), joyrideClass(index)),
          }))}
          resultCount={parentTotalCount}
          perPage={parentPerPage}
          page={parentPage}
          pageCount={parentTotalPages}
          requestParams={{
            page: parentPage,
            perPage: parentPerPage,
            sortOrder: parentSortOrder,
            sortKey: parentSortedColumn,
            filter: parentFilter,
          }}
        />
      </div>

      <ModalWrapper open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <AddInvestorToListsDialog
          investorLists={investorLists}
          onSave={updateListsWithInvestor}
          investorId={selectedInvestorId}
          investorName={
            investors.find((x) => x._id === selectedInvestorId)?.name ?? ''
          }
          onCancel={() => setIsModalOpen(false)}
        />
      </ModalWrapper>
    </>
  );
}

export default InvestorDatabaseTable;
