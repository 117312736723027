import React from 'react';
import { useLocation } from 'react-router-dom';
import { toDashCase } from '/src/util/formatting/strings';
import CustomNavLink from '../CustomNavLink';
import { getRouteBasePath } from '/src/util/formatting/routes';
import { TabHeader } from './TabsHeader';

interface SecondaryTabsHeaderProps {
  tabs: TabHeader[];
}

function SecondaryTabsHeader({ tabs }: SecondaryTabsHeaderProps): JSX.Element {
  const { pathname } = useLocation();

  function generateTabs() {
    return (
      <nav className="flex items-center space-x-2" aria-label="Tabs">
        <p className="text-base font-medium text-gray-500 sm:text-sm">Show:</p>
        {tabs.map((tab) => (
          <CustomNavLink
            activeClassName="app-table-filter--active"
            className="cursor-pointer text-base sm:text-sm"
            fullPath={`${getRouteBasePath(
              pathname,
              tabs.map((x) => x.name),
            )}/${toDashCase(tab.name)}`}
            inactiveClassName="app-table-filter"
            isFirstTab={tabs.indexOf(tab) === 0}
            key={tab.name}
            stat={tab.stat}
            to={toDashCase(tab.name)}
          >
            {tab.name}
          </CustomNavLink>
        ))}
      </nav>
    );
  }

  return <div className="block">{generateTabs()}</div>;
}

export default SecondaryTabsHeader;
