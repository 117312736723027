import React, { useContext, useEffect, useRef, useState } from 'react';
import { IntroPathPaginatedCollectionResponse } from '../types/view/IntroPathView';
import { AccountMetadataContext } from '../contexts/AccountMetadataContext';
import useUrlParams from './useUrlParams';
import { SubscriptionTiers } from '../../../libs/shared-types/src/constants/SubscriptionTiers';
import { removeUndefinedOrNullProperties } from '../util/removeUndefinedOrNullProperties';
import { StartupGetIntroPathByConnection } from '../../../libs/shared-types/src/constants/ApiRoutes';
import { IntroPathDirection } from '../../../libs/shared-types/src/constants/IntroPathDirection';
import Logger from '../services/logger';
import API from '../middleware/API';
import { ConnectionDataType } from '../../../libs/shared-types/src/constants/ConnectionDataType';
import { SortOrder } from '../../../libs/shared-types/src/constants/SortOrder';

export interface UseGetInvestorIntroPathsByConnectionProps {
  connectionId?: string;
  connectionDataType: ConnectionDataType;
}

const useGetInvestorIntroPathsByConnection = ({
  connectionDataType,
  connectionId,
}: UseGetInvestorIntroPathsByConnectionProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [
    introPathsFacilitatedByConnection,
    setIntroPathsFacilitatedByConnection,
  ] = useState<IntroPathPaginatedCollectionResponse>();
  const [introPathsTowardConnection, setIntroPathsTowardConnection] =
    useState<IntroPathPaginatedCollectionResponse>();
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const isFirstLoading = useRef<boolean>(true);
  const [isRefetching, setIsRefetching] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [introPathsTowardConnectionCount, setIntroPathsTowardConnectionCount] =
    useState(0);
  const [
    introPathsFacilitatedByConnectionCount,
    setIntroPathsFacilitatedByConnectionCount,
  ] = useState(0);

  const { getParam } = useUrlParams();
  const page = getParam('page');
  const perPage = getParam('perPage');
  const sortKey = getParam('sortKey');
  const sortOrder = getParam('sortOrder');

  function refetch() {
    setIsRefetching((prev) => !prev);
  }

  useEffect(() => {
    async function fetchIntroPaths(args: Partial<any>): Promise<void> {
      if (subscriptionTier === SubscriptionTiers.StartupFree) {
        return;
      }
      if (isFirstLoading.current) {
        setIsLoading(true);
        isFirstLoading.current = false;
      }

      try {
        const cleanParam = removeUndefinedOrNullProperties(args);
        const towardConnectionResponse =
          await API.get<IntroPathPaginatedCollectionResponse>(
            StartupGetIntroPathByConnection.buildEndpoint('', {
              ...cleanParam,
              connectionId,
              connectionDataType,
              direction: IntroPathDirection.Toward,
            }),
          );

        const facilitatedByConnectionResponse =
          await API.get<IntroPathPaginatedCollectionResponse>(
            StartupGetIntroPathByConnection.buildEndpoint('', {
              ...cleanParam,
              connectionId,
              connectionDataType,
              direction: IntroPathDirection.FacilitatedBy,
            }),
          );

        setIntroPathsFacilitatedByConnection(facilitatedByConnectionResponse);
        setIntroPathsTowardConnection(towardConnectionResponse);

        setTotalCount(
          towardConnectionResponse.totalCount +
            facilitatedByConnectionResponse.totalCount,
        );

        setIntroPathsFacilitatedByConnectionCount(
          facilitatedByConnectionResponse.totalCount,
        );
        setIntroPathsTowardConnectionCount(towardConnectionResponse.totalCount);
      } catch (error: any) {
        Logger.error(error);
      } finally {
        setIsLoading(false);
      }
    }

    if (connectionId) {
      fetchIntroPaths({
        page,
        perPage,
        sortKey: sortKey ?? 'impactScore',
        sortOrder: sortOrder ?? SortOrder.Desc,
      });
    }
    return () => {
      isFirstLoading.current = false;
    };
  }, [
    page,
    perPage,
    sortKey,
    sortOrder,
    isRefetching,
    connectionId,
    connectionDataType,
  ]);
  return {
    isLoading,
    introPathsFacilitatedByConnection,
    introPathsTowardConnection,
    totalCount,
    introPathsFacilitatedByConnectionCount,
    introPathsTowardConnectionCount,
    refetch,
  };
};

export default useGetInvestorIntroPathsByConnection;
