import {
  ArrowTopRightOnSquareIcon,
  Bars4Icon,
  UserPlusIcon,
  ViewColumnsIcon,
} from '@heroicons/react/20/solid';
import React, { useContext, useEffect, useState } from 'react';
import PageHeader from '/src/components/PageHeader';
import SearchBar from '/src/components/inputs/SearchBar';
import Alert from '/src/components/notifications/Alert';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import SummaryPanel from '/src/routes/investor/ActiveDealflow/SummaryPanel';
import Logger from '/src/services/logger';
import {
  ComparablePipelineInvestorItemView,
  InvestorPipelineView,
  PipelineInvestorItemView,
} from '/../libs/shared-types/src/types/view/InvestorPipelineView';
import {
  InvestorPipelineSystemStages,
  InvestorPipelineSystemStagesColorsMap,
} from '/../libs/shared-types/src/constants/InvestorPipelineSystemStages';
import EditStagesDialog from '/src/routes/investor/ActiveDealflow/dialogs/EditStagesDialog';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import { Stage } from '/../libs/shared-types/src/types/model/Stage';
import { StageView } from '/../libs/shared-types/src/types/view/StageView';
import { enumToList } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';
import {
  StartupAddInvestorToLists,
  StartupGetCurrentRound,
  StartupGetInvestorLists,
  StartupInvestorPipeline,
  StartupInvestorPipelineAddInvestors,
  StartupInvestorPipelineRemoveInvestor,
  StartupInvestorPipelineStages,
  StartupInvestorPipelineUpdateInvestor,
  StartupRelationshipUpdate,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import API from '/src/middleware/API';
import AddInvestorDialog from './AddInvestorDialog';
import TableWithStages from '../../../../components/table/TableWithStages';
import { TableHeader } from '/src/interfaces/TableHeader';
import { InvestorDataType } from '/../libs/shared-types/src/constants/InvestorDataType';
import InvestorPipelineTableRow from './InvestorPipelineTableRow';
import AddInvestorToListsDialog from '/src/routes/investor/ActiveDealflow/dialogs/AddInvestorToListsDialog';
import { InvestorList } from '/../libs/shared-types/src/types/model/InvestorList';
import { Disclosure } from '@headlessui/react';
import { joinClassNames } from '/src/util/formatting/strings';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  INVESTOR_DETAIL,
  STARTUP_INVESTOR_FRAGMENT_DETAIL_ROUTE,
  UNVERIFIED_INVESTOR_DETAIL,
} from '/src/constants/Routes';
import SimpleDialog from '/src/components/notifications/SimpleDialog';
import UpdateInvestorStatusDialog from './UpdateInvestorStatusDialog';
import { InvestorPipelineStatusUpdate } from '/../libs/shared-types/src/types/model/InvestorPipeline';
import { SortOrder, Sortable } from '/src/interfaces/Sortable';
import { buildComparablePipelineInvestorItemView } from '/src/util/TableSorting';
import { SubscriptionTiers } from '/../libs/shared-types/src/constants/SubscriptionTiers';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import { SubscriptionCTAPill } from '/src/components/SubscriptionCTA';
import RoundView from '/../libs/shared-types/src/types/view/RoundView';
import { Priority } from '../../../../constants/Priority';
import { abbreviateNumber } from '/src/util/formatting/numbers';
import UpdateStartupRelationshipDialog from './UpdateStartupRelationshipDialog';
import { StartupRelationship } from '../../../../types/model/StartupRelationship';
import Tooltip from '/src/components/utility/Tooltip';
import InvestorPipelineKpiCards from './InvestorPipelineKpiCards';

function mapInvestorsToStages(
  stages: StageView[],
  investors: PipelineInvestorItemView[],
): Map<string, string[]> {
  const stageInvestorsMap = new Map<string, string[]>();

  stages.forEach((stage) => {
    const investorsInStage = investors
      .filter((investor) => investor.currentStage.name === stage.name)
      .map((investor) => investor.investorId);
    stageInvestorsMap.set(stage.name, investorsInStage);
  });

  return stageInvestorsMap;
}

const headers: TableHeader<any>[] = [
  { sortKey: 'name', element: <span>Name</span> },
  { sortKey: 'type', element: <span>Type</span> },
  { sortKey: 'firm', element: <span>Firm & Role</span> },
  {
    sortKey: 'fitScore',
    element: (
      <span className="flex flex-row">
        Fit
        <Tooltip
          tooltipText={
            "Represents how well your company fits in the investor's thesis"
          }
          position="left"
          width="w-64"
        />
      </span>
    ),
  },
  { sortKey: 'priority', element: <span>Priority</span> },
  { sortKey: 'lastContactedOn', element: <span>Contacted</span> },
  { sortKey: 'totalViewCount', element: <span>Views</span> },
  { sortKey: 'commitment', element: <span>Commitment</span> },
  { element: <span className="sr-only">Actions</span> },
];

export function toPipelineStageViews(stages: Stage[]): StageView[] {
  const defaultColor = {
    bgColor: 'bg-blue-300',
    textColor: 'text-blue-700',
    borderColor: 'border-blue-400',
  };

  const systemStages = enumToList(InvestorPipelineSystemStages);
  const endStages: string[] = [
    InvestorPipelineSystemStages.NotInvested,
    InvestorPipelineSystemStages.Wired,
  ];

  return stages.map((x) => ({
    ...x,
    ...(InvestorPipelineSystemStagesColorsMap.get(x.name) ?? defaultColor),
    isSystemStage: systemStages.includes(x.name),
    isDurationDisabled: endStages.includes(x.name),
  }));
}

function InvestorPipeline(): JSX.Element {
  const [currentRound, setCurrentRound] = useState<RoundView>();
  const [pipeline, setPipeline] = useState<InvestorPipelineView>();
  const [sortableInvestors, setSortableInvestors] =
    useState<
      Sortable<PipelineInvestorItemView, ComparablePipelineInvestorItemView>
    >();
  const [query, setQuery] = useState('');
  const [selectedStage, setSelectedStage] = useState<Stage | undefined>(
    undefined,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [stages, setStages] = useState<Stage[]>([]);

  const [investorLists, setInvestorLists] = useState<InvestorList[]>([]);
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const location = useLocation();

  // Keys are Stage Names, Values are Investor IDs in that stage
  const [stageInvestorsMap, setStageInvestorsMap] = useState<
    Map<string, string[]>
  >(new Map());

  const [modalConfig, setModalConfig] = useState<{
    type:
      | undefined
      | 'addInvestor'
      | 'assignInvestorToLists'
      | 'manageStages'
      | 'removeInvestor'
      | 'updateInvestorStatus'
      | 'updateStartupRelationship';
    isOpen: boolean;
    investor?: PipelineInvestorItemView;
  }>({
    type: undefined,
    isOpen: false,
    investor: undefined,
  });

  const boardStageViews = toPipelineStageViews(stages);

  function closeModal() {
    setModalConfig({ type: undefined, isOpen: false, investor: undefined });
  }

  const navigate = useNavigate();

  function onRowClick(investorItem: PipelineInvestorItemView) {
    switch (investorItem.investorDataType) {
      case InvestorDataType.Verified:
        navigate(`${INVESTOR_DETAIL}/${investorItem.investorId}/activity`);
        break;
      case InvestorDataType.Unverified:
        navigate(
          `${UNVERIFIED_INVESTOR_DETAIL}/${investorItem.investorId}/activity`,
        );
        break;
      case InvestorDataType.Fragment:
        navigate(
          `${STARTUP_INVESTOR_FRAGMENT_DETAIL_ROUTE}/${investorItem.investorId}/activity`,
        );
        break;
      default:
        break;
    }
  }

  async function onQueryChange(query: string) {
    setIsSearchLoading(true);
    setQuery(query);
    try {
      const data = await API.get<InvestorPipelineView>(
        StartupInvestorPipeline.buildEndpoint(undefined, {
          roundId: undefined,
          query,
        }),
      );
      setPipeline(data);
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      setIsSearchLoading(false);
    }
  }

  const handleSort = (sortKey: string, sortOrder: SortOrder) => {
    if (!pipeline) {
      return;
    }

    const sorted = sortableInvestors?.sort(
      sortKey as keyof ComparablePipelineInvestorItemView,
      sortOrder,
    );

    const sortedPipeline = { ...pipeline };
    sortedPipeline.investors = sorted ? [...sorted] : [];

    setPipeline(sortedPipeline);
  };

  async function updateStages(stages: Stage[]) {
    try {
      const newStages = await API.put<Stage[]>(
        StartupInvestorPipelineStages.buildEndpoint(),
        {
          boardStages: stages,
        },
      );
      setStages(newStages);
      fetchPipeline();
      closeModal();
    } catch (error: any) {
      Logger.error(error.message);
      throw error;
    }
  }

  async function fetchPipeline() {
    setIsLoading(true);

    try {
      const data = await API.get<InvestorPipelineView>(
        StartupInvestorPipeline.buildEndpoint(undefined, {
          query,
        }),
      );

      setPipeline(data);
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchStages() {
    try {
      const stages = await API.get<Stage[]>(
        StartupInvestorPipelineStages.buildEndpoint(),
      );

      setStages(stages);
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      // Check if we should open the Stage Editor
      if (
        !isLoading &&
        location.state &&
        (location.state as any).openDialog === 'manageStages'
      ) {
        setModalConfig({ type: 'manageStages', isOpen: true });
        // Clear the state so that it does not re-toggle the dialog on page refresh
        window.history.replaceState({}, document.title);
      }
    }
  }

  async function fetchInvestorLists() {
    try {
      const data = await API.get<InvestorList[]>(
        StartupGetInvestorLists.buildEndpoint(),
      );
      setInvestorLists(data);
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  async function updateStartupRelationship(
    investorId: string,
    investorDataType: InvestorDataType,
    update: Partial<StartupRelationship>,
  ) {
    try {
      await API.put(StartupRelationshipUpdate.buildEndpoint(), {
        investorId,
        investorDataType,
        update,
      });
      await fetchPipeline();
      closeModal();
    } catch (error: any) {
      Logger.error(error.message);
      throw error;
    }
  }

  async function updateInvestorStatus(
    investorId: string,
    investorDataType: InvestorDataType,
    update: InvestorPipelineStatusUpdate,
  ) {
    try {
      await API.put(StartupInvestorPipelineUpdateInvestor.buildEndpoint(), {
        investorId,
        investorDataType,
        update,
      });
      await fetchPipeline();
      closeModal();
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  async function updateInvestorPriority(
    investor: PipelineInvestorItemView,
    priority: Priority,
  ) {
    const update: InvestorPipelineStatusUpdate = {
      currentStageName: investor.currentStage.name,
      priority,
    };
    await updateInvestorStatus(
      investor.investorId,
      investor.investorDataType,
      update,
    );
  }

  async function addInvestorToPipeline(
    investorId: string,
    investorDataType: InvestorDataType,
    shouldCloseModal: boolean,
  ) {
    try {
      await API.put(StartupInvestorPipelineAddInvestors.buildEndpoint(), {
        investors: [
          {
            investorId: investorId,
            investorDataType: investorDataType,
          },
        ],
      });
      await fetchPipeline();
      if (shouldCloseModal) {
        closeModal();
      }
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  async function removeInvestorFromPipeline(
    investorItem: PipelineInvestorItemView,
  ) {
    try {
      await API.put(StartupInvestorPipelineRemoveInvestor.buildEndpoint(), {
        investorId: investorItem.investorId,
        investorDataType: investorItem.investorDataType,
      });
      await fetchPipeline();
      closeModal();
    } catch (error) {
      Logger.error(error);
    }
  }

  async function updateListsWithInvestor(
    investorItem: PipelineInvestorItemView,
    investorListIds: string[],
  ) {
    try {
      await API.put(StartupAddInvestorToLists.buildEndpoint(), {
        investorId: investorItem.investorId,
        investorDataType: investorItem.investorDataType,
        investorListIds: investorListIds,
      });
      closeModal();
      fetchInvestorLists();
    } catch (error) {
      Logger.error(error);
    }
  }

  const fetchActiveRound = async () => {
    try {
      const round: RoundView = await API.get(
        StartupGetCurrentRound.buildEndpoint(),
      );
      if (round === null) {
        return;
      }

      setCurrentRound(round);
    } catch (error: any) {
      Logger.error(error);
    }
  };

  useEffect(() => {
    if (subscriptionTier === SubscriptionTiers.StartupFree) {
      return;
    }

    fetchActiveRound();
    fetchPipeline();
    fetchStages();
    fetchInvestorLists();
  }, []);

  useEffect(() => {
    if (!pipeline || !stages) {
      return;
    }

    const sortableItems = new Sortable<
      PipelineInvestorItemView,
      ComparablePipelineInvestorItemView
    >(pipeline?.investors, buildComparablePipelineInvestorItemView);

    setSortableInvestors(sortableItems);
    setStageInvestorsMap(mapInvestorsToStages(stages, pipeline?.investors));
  }, [pipeline, pipeline?.investors, stages]);

  return (
    <section>
      {pipeline && (
        <InvestorPipelineKpiCards statsSummary={pipeline.statsSummary} />
      )}
      <div className="flex min-w-full flex-grow flex-col align-middle sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <header className="sticky top-0 z-40 justify-between bg-gray-100 lg:flex lg:flex-row">
          <PageHeader
            title="Investor Pipeline"
            itemsCount={pipeline?.investors.length}
            itemLabel="investor"
          />

          <aside className="inline-flex items-center space-x-4">
            <section className="flex flex-row items-center">
              {isSearchLoading && <LoadingSpinner color="blue" />}
              <SearchBar
                isDebounce
                placeholder={`Search ${selectedStage?.name ?? 'Investors'}`}
                onQueryChange={onQueryChange}
                isDisabled={subscriptionTier === SubscriptionTiers.StartupFree}
              />
            </section>

            <button
              type="button"
              className="app-button--neutral"
              onClick={() =>
                setModalConfig({ type: 'manageStages', isOpen: true })
              }
              disabled={
                subscriptionTier === SubscriptionTiers.StartupFree ||
                (currentRound && !currentRound.isOpen)
              }
            >
              Manage Stages
            </button>

            <button
              type="button"
              className="app-button--primary"
              onClick={() => {
                onQueryChange('');
                setModalConfig({ type: 'addInvestor', isOpen: true });
              }}
              disabled={
                subscriptionTier === SubscriptionTiers.StartupFree ||
                (currentRound && !currentRound.isOpen)
              }
            >
              <UserPlusIcon className="mr-2 h-5 w-5" />
              Add Investor
            </button>
          </aside>
        </header>
        {isLoading && (
          <PageLoadingSpinner message="Loading your round pipeline... 🚀" />
        )}

        {!isLoading && subscriptionTier === SubscriptionTiers.StartupFree && (
          <div className="mt-6 w-full rounded-md bg-white p-8 shadow">
            <p className="font-medium">
              Easily manage and track your investor outreach in real-time
            </p>
            <a
              href="https://www.loom.com/share/71f1b69d5f4a49bfb990fcf05f231c13?sid=78e95b35-66c3-4b08-a420-80c1a1c60fc4"
              target="_blank"
              rel="noopener noreferrer"
              className="hyperlink flex items-center space-x-2"
            >
              <span>Check out how Investor Pipeline works</span>
              <ArrowTopRightOnSquareIcon className="size-5" />
            </a>
            <ul className="mt-4 list-inside list-disc">
              <li>
                Customize the pipeline stages to fit your fundraising process
              </li>
              <li>
                Keep track of investor relationships, intros needed, and much
                more in one place
              </li>
              <li>
                Record investor commitments or reasons for passing to refine
                your strategy and improve future outreach
              </li>
              <li>
                Set a target duration for each stage to be reminded of
                follow-ups
              </li>
            </ul>
            <p className="my-6">
              Try Flowlie Pro for free for 14 days to unlock the full investor
              database, target lists, and the ability to manage your investor
              pipeline.
            </p>

            <div className="mt-6 w-max">
              <SubscriptionCTAPill
                id="cta_investor_pipeline"
                text="Organize and enhance your fundraising process"
              />
            </div>
          </div>
        )}

        {subscriptionTier !== SubscriptionTiers.StartupFree &&
          pipeline &&
          stageInvestorsMap.size > 0 && (
            <>
              {!isLoading && (
                <div className="sticky top-20 z-20 bg-gray-100 pb-4">
                  <SummaryPanel
                    onSelectStage={(stage: StageView | undefined) =>
                      setSelectedStage(stage)
                    }
                    stageStatuses={pipeline.investors.map(
                      (x) => x.currentStage,
                    )}
                    stages={boardStageViews}
                    selectedStage={selectedStage}
                  />
                </div>
              )}

              {!isLoading &&
                pipeline.investors.length === 0 &&
                query === '' && (
                  <Alert
                    alertType="Info"
                    canDismiss={false}
                    color="blue"
                    content={'No investors in pipeline'}
                    isShown={true}
                  />
                )}

              {(pipeline.investors.length > 0 || query !== '') && (
                <section>
                  <TableWithStages
                    boardStages={boardStageViews}
                    headers={headers}
                    isSearching={query !== ''}
                    onSort={handleSort}
                    selectedStage={selectedStage}
                    stageRowItemsMap={stageInvestorsMap}
                    itemsPerStageCountMap={
                      new Map(Object.entries(pipeline.investorCountPerStageId))
                    }
                    stageStats={(stage) => {
                      const stageStats = pipeline.statsPerStageId[stage._id];
                      const stagesWithCommitments: string[] = [
                        InvestorPipelineSystemStages.Committed,
                        InvestorPipelineSystemStages.Wired,
                      ];
                      if (!stagesWithCommitments.includes(stage.name)) {
                        return <></>;
                      }
                      return (
                        <span className="ml-auto mr-5 inline-flex">
                          <span className="text-sm">
                            ${abbreviateNumber(stageStats.committedAmount)}{' '}
                            {stage.name.toLowerCase()}
                          </span>
                        </span>
                      );
                    }}
                    rowComponent={(stage: StageView) => {
                      const investorIds = stageInvestorsMap.get(stage.name);
                      if (!investorIds) {
                        return <div>No investors for this stage</div>;
                      }

                      return investorIds.map((investorId) => {
                        const investor = pipeline.investors.find(
                          (x) => x.investorId === investorId,
                        );
                        if (!investor) {
                          return <div key={investorId}>Investor not found</div>;
                        }

                        return (
                          <Disclosure.Panel
                            as="tr"
                            key={investorId}
                            onClick={() => onRowClick(investor)}
                            className="cursor-pointer bg-white hover:bg-gray-100"
                          >
                            <InvestorPipelineTableRow
                              key={investor.investorId}
                              investorItem={investor}
                              onAssignInvestorToList={(
                                investorItem: PipelineInvestorItemView,
                              ) => {
                                setModalConfig({
                                  type: 'assignInvestorToLists',
                                  isOpen: true,
                                  investor: investorItem,
                                });
                              }}
                              onRemoveInvestor={(
                                investorItem: PipelineInvestorItemView,
                              ) => {
                                setModalConfig({
                                  type: 'removeInvestor',
                                  isOpen: true,
                                  investor: investorItem,
                                });
                              }}
                              onUpdateInvestor={(
                                investorItem: PipelineInvestorItemView,
                              ) => {
                                setModalConfig({
                                  type: 'updateInvestorStatus',
                                  isOpen: true,
                                  investor: investorItem,
                                });
                              }}
                              onUpdateRelationship={(
                                investorItem: PipelineInvestorItemView,
                              ) => {
                                setModalConfig({
                                  type: 'updateStartupRelationship',
                                  isOpen: true,
                                  investor: investorItem,
                                });
                              }}
                              onPrioritySelected={updateInvestorPriority}
                            />
                          </Disclosure.Panel>
                        );
                      });
                    }}
                  />
                </section>
              )}
            </>
          )}
      </div>

      <ModalWrapper open={modalConfig.isOpen} onClose={() => closeModal()}>
        {modalConfig.type === 'addInvestor' && (
          <AddInvestorDialog
            onAddInvestor={addInvestorToPipeline}
            addIn="pipeline"
          />
        )}

        {modalConfig.type === 'assignInvestorToLists' &&
          modalConfig.investor && (
            <AddInvestorToListsDialog
              investorLists={investorLists}
              onCancel={() => closeModal()}
              onSave={(_: string, investorListIds: string[]) => {
                if (!modalConfig.investor) {
                  return;
                }
                updateListsWithInvestor(modalConfig.investor, investorListIds);
              }}
              investorId={modalConfig.investor._id}
              investorName={modalConfig.investor.name}
            />
          )}

        {modalConfig.type === 'manageStages' && pipeline && (
          <EditStagesDialog
            boardStages={boardStageViews}
            onCancel={closeModal}
            onSave={updateStages}
            itemsPerStageCountMap={
              new Map(Object.entries(pipeline.investorCountPerStageId))
            }
            header="Manage Pipeline Stages"
            secondaryHeader="Update the stages to fit your process. You can set a target duration to indicate how long an investor should ideally stay in each stage. Flowlie will mark any investors that passed the target duration so you can follow up and ensure an efficient fundraising process."
          />
        )}

        {modalConfig.type === 'removeInvestor' && modalConfig.investor && (
          <SimpleDialog
            onCancel={closeModal}
            onPrimaryAction={() => {
              if (!modalConfig.investor) {
                return;
              }
              removeInvestorFromPipeline(modalConfig.investor);
            }}
            title="Are you sure you want to remove this investor from the current pipeline?"
            text="You will be able to add this investor back, but the history of stage changes will not be preserved."
            primaryAction="Remove from pipeline"
            color="red"
          />
        )}

        {modalConfig.type === 'updateInvestorStatus' &&
          modalConfig.investor &&
          currentRound && (
            <UpdateInvestorStatusDialog
              investor={modalConfig.investor}
              currentRound={currentRound}
              onCancel={closeModal}
              onSave={(update: InvestorPipelineStatusUpdate) => {
                if (!modalConfig.investor) {
                  return;
                }
                updateInvestorStatus(
                  modalConfig.investor.investorId,
                  modalConfig.investor.investorDataType,
                  update,
                );
              }}
              boardStages={boardStageViews}
            />
          )}

        {modalConfig.type === 'updateStartupRelationship' &&
          modalConfig.investor && (
            <UpdateStartupRelationshipDialog
              connectionName={modalConfig.investor.name}
              connectionDataType={modalConfig.investor.investorDataType}
              relationship={modalConfig.investor.relationship}
              onCancel={closeModal}
              onSave={(update: Partial<StartupRelationship>) => {
                if (!modalConfig.investor) {
                  return Promise.resolve();
                }

                return updateStartupRelationship(
                  modalConfig.investor.investorId,
                  modalConfig.investor.investorDataType,
                  update,
                );
              }}
            />
          )}
      </ModalWrapper>
    </section>
  );
}

export default InvestorPipeline;
