import { IntroPathDirection } from '../../../libs/shared-types/src/constants/IntroPathDirection';
import { IntroPathView } from '../../../libs/shared-types/src/types/view/IntroPathView';

/**
 * Given the direction of the intro path, which connection is the "start"?
 * @summary If direction is FacilitatedBy, return source connection.
 * Otherwise return target connection
 */
export const getIntroPathStartConnection = (
  introPath: IntroPathView,
  direction: IntroPathDirection,
) => {
  return direction === IntroPathDirection.FacilitatedBy
    ? introPath.sourceConnection
    : introPath.targetConnection;
};

/**
 * Given the direction of the intro path, which connection is the "end"?
 * @summary If direction is FacilitatedBy, return target connection.
 * Otherwise return source connection
 */
export const getIntroPathEndConnection = (
  introPath: IntroPathView,
  direction: IntroPathDirection,
) => {
  return direction === IntroPathDirection.FacilitatedBy
    ? introPath.targetConnection
    : introPath.sourceConnection;
};

/**
 * Given the direction of the intro path, return the prefix for the connection
 * @summary If direction is FacilitatedBy, return 'target'.
 * Otherwise return 'source'.
 */
export function getConnectionPrefixByDirection(direction: IntroPathDirection) {
  return direction === IntroPathDirection.FacilitatedBy ? 'target' : 'source';
}
