import React from 'react';
import { InvestorListItemView } from '../../../types/view/InvestorListView';
import { Priority } from '../../../constants/Priority';
import PrioritySelect, {
  PriorityLabel,
} from '/src/routes/investor/DealDetail/PrioritySelect';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import { StartupUpdateInvestorPriority } from '/../libs/shared-types/src/constants/ApiRoutes';
import { useQueryClient } from '@tanstack/react-query';
import { INVESTOR_LIST_BY_ID_KEY } from '/src/hooks/useGetInvestorListById';

function InvestorCellPriority({
  investor,
  listId,
  isReadOnly,
}: {
  investor: InvestorListItemView;
  listId: string;
  isReadOnly?: boolean;
}) {
  const queryClient = useQueryClient();

  async function onPrioritySelect(
    listId: string,
    investorId: string,
    newPriority: Priority,
  ) {
    try {
      await API.put(StartupUpdateInvestorPriority.buildEndpoint(), {
        listId: listId,
        investorId: investorId,
        priority: newPriority,
      });
      queryClient.invalidateQueries({ queryKey: [INVESTOR_LIST_BY_ID_KEY, listId] })
    } catch (error) {
      Logger.error(error);
    }
  }

  const updatePriority = (newPriority: Priority) => {
    onPrioritySelect(listId, investor.investorId, newPriority);
  };
  return (
    <div className="app-table-row-text w-fit">
      {isReadOnly ? (
        <PriorityLabel priority={investor.priority} />
      ) : (
        <PrioritySelect value={investor.priority} onSelect={updatePriority} />
      )}
    </div>
  );
}

export default InvestorCellPriority;
