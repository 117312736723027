import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { StartupGetInvestorFragments } from '../../../libs/shared-types/src/constants/ApiRoutes';
import API from '../middleware/API';
import Logger from '../services/logger';
import { HOUR_MS } from '../../../libs/shared-types/src/constants/time';
import { removeUndefinedOrNullProperties } from '../util/removeUndefinedOrNullProperties';
import { InvestorFragmentsPaginatedCollectionResponse } from '../types/view/InvestorFragmentView';
import { SubscriptionTiers } from '../../../libs/shared-types/src/constants/SubscriptionTiers';
import {
  hasFeatureAccess,
  StartupPaidFeatures,
} from '../../../libs/shared-types/src/extensions/SubscriptionAccess';

export const IMPORTED_INVESTOR_KEY = 'imported_investor';
// Cache time > Stale time
const STALE_TIME_IMPORTED_INVESTOR = HOUR_MS * 4;
const CACHE_TIME_IMPORTED_INVESTOR = STALE_TIME_IMPORTED_INVESTOR * 1.25;

export interface UseGetImportedInvestorProps {
  subscriptionTier: SubscriptionTiers | undefined;
  page: string | number | null;
  perPage: string | number | null;
  filter: string | null;
  sortKey: string | null;
  sortOrder: string | null;
}

const useGetImportedInvestor = (props: UseGetImportedInvestorProps) => {
  const { subscriptionTier, ...params } = props;
  const cleanParam = removeUndefinedOrNullProperties(params);
  return useQuery({
    queryKey: [IMPORTED_INVESTOR_KEY, cleanParam],
    queryFn: () => fetchImportedInvestors(cleanParam),
    staleTime: STALE_TIME_IMPORTED_INVESTOR,
    gcTime: CACHE_TIME_IMPORTED_INVESTOR,
    placeholderData: keepPreviousData,
    enabled: hasFeatureAccess(
      StartupPaidFeatures.InvestorFragments,
      subscriptionTier,
    ),
  });
};

export default useGetImportedInvestor;

async function fetchImportedInvestors(
  args: Exclude<
    Partial<{
      [K in keyof UseGetImportedInvestorProps]:
        | undefined
        | NonNullable<UseGetImportedInvestorProps[K]>;
    }>,
    'id'
  >,
) {
  try {
    const url = StartupGetInvestorFragments.buildEndpoint(undefined, args);
    const data =
      await API.get<InvestorFragmentsPaginatedCollectionResponse>(url);
    return data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      Logger.error(error.message);
    }
  }
}
