import React, { useEffect, useState } from 'react';
import TabsHeader, { TabHeader } from '/src/components/tabs/TabsHeader';
import { formatImageAddress } from '/src/util/formatting/strings';
import { DealDetailData } from '/../libs/shared-types/src/types/view/AggregatedDeals';
import { getCurrentBoardStageStatusFromDealDetailData } from '/src/util/deal';
import ActiveStageDurationLeft from './ActiveStageDurationLeft';
import DealDetailActiveStageSelector from './DealDetailActiveStageSelector';
import DealStatusLabel from './DealStatusLabel';
import { DealStatusTypes } from '/../libs/shared-types/src/constants/DealStatusTypes';
import DateString from '/src/components/utility/DateString';
import { ACTIVE_DEALFLOW_ROUTE } from '/src/constants/Routes';
import { DealActionModals } from '/src/interfaces/ModalConfig';
import { useNavigate } from 'react-router-dom';
import {
  Stage,
  StageStatus,
} from '/../libs/shared-types/src/types/model/Stage';
import { StageStatusView } from '/../libs/shared-types/src/types/view/StageView';
import Logger from '/src/services/logger';
import API from '/src/middleware/API';
import Toast, { ToastProps } from '/src/components/notifications/Toast';
import StarRatingSelect from './StarRatingSelect';
import PrioritySelect from './PrioritySelect';
import { Priority } from '/../libs/shared-types/src/constants/Priority';
import { StarRating } from '/../libs/shared-types/src/constants/StarRating';
import {
  updateDealPriority,
  updateDealRating,
} from '/src/services/DealRatingPriority';
import {
  HiddenFitScore,
  generateFitScoreTag,
} from '/src/util/generateTagCloud';
import { InvestorUpdateDealActiveStage } from '/../libs/shared-types/src/constants/ApiRoutes';
import NavigationBackButton from '/src/components/utility/NavigationBackButton';
import SquaredLogo from '/src/components/SquaredLogo';

async function handleUpdateDealPriority(
  deal: DealDetailData,
  newPriority: Priority,
) {
  if (newPriority === deal.priority.priority) {
    // Incoming Deal Priority is the same as the current priority, so nothing to do
    return;
  }

  try {
    await updateDealPriority(newPriority, deal.startup._id);
  } catch (error: any) {
    Logger.error(error.message);
  }
}

async function handleUpdateDealRating(
  deal: DealDetailData,
  newRating: StarRating,
) {
  if (newRating === deal.rating.rating) {
    // Incoming Deal Rating is the same as the current rating, so nothing to do
    return;
  }

  try {
    await updateDealRating(newRating, deal.startup._id);
  } catch (error: any) {
    Logger.error(error.message);
  }
}

interface DealDetailHeaderProps {
  dealDetailData: DealDetailData;
  tabs: TabHeader[];
  actionButton: JSX.Element;
  activeBoardStages: Stage[];
  hasFitScoreAccess?: boolean;
}

function DealDetailHeader({
  dealDetailData,
  tabs,
  actionButton,
  activeBoardStages,
  hasFitScoreAccess = false,
}: DealDetailHeaderProps): JSX.Element {
  const navigate = useNavigate();
  const startup = dealDetailData.startup;
  const [currentBoardStageStatus, setCurrentBoardStageStatus] = useState<
    StageStatusView | undefined
  >(getCurrentBoardStageStatusFromDealDetailData(dealDetailData));

  const [toast, setToast] = useState<ToastProps>({
    isShown: false,
    onClose: () => null,
    title: '',
    text: '',
    isError: false,
  });

  function dealPriorityOnSelect(newPriority: Priority) {
    handleUpdateDealPriority(dealDetailData, newPriority);
  }

  function dealRatingOnSelect(newRating: StarRating) {
    handleUpdateDealRating(dealDetailData, newRating);
  }

  const handleActiveBoardStageChange = async (stageName: string) => {
    if (stageName === currentBoardStageStatus?.name) {
      // Incoming Stage name is the same as the current stage, so nothing to do
      return;
    }
    try {
      const newBoardStage = await API.put(
        InvestorUpdateDealActiveStage.buildEndpoint(),
        {
          startupId: startup._id,
          stageName,
        },
      );

      if (newBoardStage) {
        setCurrentBoardStageStatus(newBoardStage);
      }
    } catch (error: any) {
      const errorToast = {
        isShown: true,
        onClose: () => setToast({ ...toast, isShown: false }),
        title:
          "We're sorry, there was an issue updating this deal's stage. Please try again or contact support!",
        text: error.message,
        isError: true,
      };
      setToast(errorToast);
      window.setTimeout(
        () => setToast({ ...errorToast, isShown: false }),
        3000,
      );

      Logger.error(error.message);
    }
  };

  useEffect(() => {
    setCurrentBoardStageStatus(
      getCurrentBoardStageStatusFromDealDetailData(dealDetailData),
    );
  }, [dealDetailData]);

  return (
    <header className="sticky top-0 z-40 bg-gray-100 sm:pt-6">
      <NavigationBackButton className="-mt-4 mb-2" />
      <div className="relative mx-auto max-w-full md:flex md:items-center md:justify-between md:space-x-5">
        <div className="flex items-center space-x-5">
          <div className="size-[5.5rem] shrink-0">
            <SquaredLogo
              containerClassName="size-[5.5rem]"
              src={formatImageAddress(startup.logoKey)}
              alt={`${startup.name} logo`}
            />
          </div>

          <div className="space-y-1">
            <div className="inline-flex items-center space-x-2">
              <h2 className="truncate text-xl font-bold text-gray-900">
                {startup.name}
              </h2>
            </div>

            <div className="flex flex-row items-center space-x-2">
              <span className="hyperlink text-sm">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={startup.website}
                >
                  {startup.website.split('//').length > 0
                    ? startup.website.split('//')[1]
                    : startup.website}
                </a>
              </span>
            </div>

            <div className="mt-1 flex flex-row flex-wrap items-center space-x-2">
              <div className="flex items-center space-x-2">
                {dealDetailData?.fitScore !== undefined &&
                  hasFitScoreAccess &&
                  generateFitScoreTag(dealDetailData.fitScore, true)}
                {dealDetailData?.fitScore !== undefined &&
                  !hasFitScoreAccess && <HiddenFitScore />}
              </div>
              {(dealDetailData?.currentDealStatus.status ===
                DealStatusTypes.Active ||
                dealDetailData?.currentDealStatus.status ===
                  DealStatusTypes.Screening) && (
                <div className="flex items-center space-x-2">
                  <StarRatingSelect
                    value={dealDetailData.rating.rating}
                    onSelect={dealRatingOnSelect}
                  />
                  <PrioritySelect
                    value={dealDetailData.priority.priority}
                    onSelect={dealPriorityOnSelect}
                  />
                </div>
              )}
              {dealDetailData?.currentDealStatus.status !==
                DealStatusTypes.Active && (
                <div className="flex items-center space-x-2 text-xs">
                  <DealStatusLabel
                    label={dealDetailData?.currentDealStatus.status}
                  />
                  <p>
                    Since&nbsp;
                    <DateString
                      date={dealDetailData.currentDealStatus.statusChangedOn}
                    />
                  </p>
                </div>
              )}
              {dealDetailData?.currentDealStatus.status ===
                DealStatusTypes.Active &&
                currentBoardStageStatus && (
                  <div className="flex items-center space-x-2">
                    <DealDetailActiveStageSelector
                      boardStages={activeBoardStages}
                      currentStageName={currentBoardStageStatus.name}
                      handleActiveBoardStageChange={
                        handleActiveBoardStageChange
                      }
                      showStageEditor={() =>
                        // Redirect to ActiveDealflow, and pass a state to toggle open the stage editor dialog
                        navigate(ACTIVE_DEALFLOW_ROUTE, {
                          state: {
                            openDialog: DealActionModals.EditStages,
                          },
                        })
                      }
                    />

                    <div className="flex flex-col items-center justify-start text-xs">
                      <p>
                        Since&nbsp;
                        <DateString date={currentBoardStageStatus.createdOn} />
                      </p>

                      <ActiveStageDurationLeft
                        durationLeft={currentBoardStageStatus.durationLeft}
                      />
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>

        <aside className="mt-5 flex flex-col justify-stretch space-x-0 space-y-2 md:mt-0 xl:flex-row xl:justify-end xl:space-x-2 xl:space-y-0">
          {actionButton}
        </aside>
      </div>

      <TabsHeader tabs={tabs} />

      <Toast
        isShown={toast.isShown}
        onClose={toast.onClose}
        title={toast.title}
        text={toast.text}
        isError={toast.isError}
      />
    </header>
  );
}

export default DealDetailHeader;
