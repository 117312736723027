/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from 'react';
import InvestorDetailRelationshipCard from '../cards/InvestorDetailRelationshipCard';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import UpdateStartupRelationshipDialog from '/src/routes/startup/RoundManager/InvestorPipeline/UpdateStartupRelationshipDialog';
import { StartupRelationship } from '../../../../types/model/StartupRelationship';
import { InvestorDataType } from '/../libs/shared-types/src/constants/InvestorDataType';
import API from '/src/middleware/API';
import {
  StartupInvestorPipelineUpdateInvestor,
  StartupRelationshipUpdate,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import Logger from '/src/services/logger';
import {
  PipelineHistoryDetail,
  InvestorActivityTabView,
} from '../../../../types/view/InvestorActivityTabView';
import InvestorDetailRoundEngagementCard from '../cards/InvestorDetailRoundEngagementCard';
import { toPipelineStageViews } from '/src/routes/startup/RoundManager/InvestorPipeline/InvestorPipeline';
import UpdateInvestorStatusDialog from '/src/routes/startup/RoundManager/InvestorPipeline/UpdateInvestorStatusDialog';
import { InvestorPipelineStatusUpdate } from '../../../../types/model/InvestorPipeline';
import InvestorPipelineActivityTimeline from '../cards/InvestorPipelineActivityTimeline';
import EmptyState from '/src/components/notifications/EmptyState';
import { AdjustmentsVerticalIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/20/solid';
import { useQueryClient } from '@tanstack/react-query';
import { INVESTOR_LIST_BY_ID_KEY } from '/src/hooks/useGetInvestorListById';
import { IMPORTED_INVESTOR_KEY } from '/src/hooks/useGetImportedInvestor';
import { SubscriptionTiers } from '/../libs/shared-types/src/constants/SubscriptionTiers';
import { SubscriptionCTAPill } from '/src/components/SubscriptionCTA';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';

interface InvestorActivityTabProps {
  aggregateInvestorActivity: InvestorActivityTabView;
  investorId: string;
  investorName: string;
  investorDataType: InvestorDataType;
  fetchInvestorData: () => Promise<void>;
}

function InvestorActivityTab({
  aggregateInvestorActivity,
  investorId,
  investorName,
  investorDataType,
  fetchInvestorData,
}: InvestorActivityTabProps): JSX.Element {
  const [modalConfig, setModalConfig] = useState<{
    type: undefined | 'updateStartupRelationship' | 'updateInvestorStatus';
    isOpen: boolean;
  }>({
    type: undefined,
    isOpen: false,
  });
  const queryClient = useQueryClient();
  const { subscriptionTier } = useContext(AccountMetadataContext);

  const isEmpty =
    !aggregateInvestorActivity?.currentPipeline?.stageStatusChanges?.length &&
    !aggregateInvestorActivity?.previousPipelines?.length &&
    !aggregateInvestorActivity?.relationship;

  const closeModal = () => {
    setModalConfig({ type: undefined, isOpen: false });
  };

  function invalidateTargetLists() {
    queryClient.invalidateQueries({ queryKey: [INVESTOR_LIST_BY_ID_KEY] });
  }

  function invalidateImportedInvestor() {
    queryClient.invalidateQueries({ queryKey: [IMPORTED_INVESTOR_KEY] });
  }

  const openUpdateStartupRelationshipModal = () => {
    setModalConfig({
      type: 'updateStartupRelationship',
      isOpen: true,
    });
  };

  const openUpdatePipelineModal = () => {
    setModalConfig({ type: 'updateInvestorStatus', isOpen: true });
  };

  const updateStartupRelationship = async (
    investorId: string,
    investorDataType: InvestorDataType,
    update: Partial<StartupRelationship>,
  ) => {
    try {
      await API.put(StartupRelationshipUpdate.buildEndpoint(), {
        investorId,
        investorDataType,
        update,
      });
      fetchInvestorData();
      closeModal();
      invalidateTargetLists();
      invalidateImportedInvestor();
    } catch (error: any) {
      Logger.error(error.message);
      throw error;
    }
  };

  const updateInvestorStatus = async (
    investorId: string,
    investorDataType: InvestorDataType,
    update: InvestorPipelineStatusUpdate,
  ) => {
    try {
      await API.put(StartupInvestorPipelineUpdateInvestor.buildEndpoint(), {
        investorId,
        investorDataType,
        update,
      });
      await fetchInvestorData();
      closeModal();
    } catch (error: any) {
      Logger.error(error.message);
    }
  };

  const currentPipelineInvestor =
    aggregateInvestorActivity.currentPipeline?.investors.find(
      (investor) => investor._id === investorId,
    );

  const currentBoardStageViews = toPipelineStageViews(
    aggregateInvestorActivity.currentPipeline?.stages || [],
  );
  const pipelineHistory: PipelineHistoryDetail[] = [];
  if (aggregateInvestorActivity?.currentPipeline) {
    pipelineHistory.push(aggregateInvestorActivity.currentPipeline);
  }
  if (aggregateInvestorActivity?.previousPipelines) {
    pipelineHistory.push(...aggregateInvestorActivity.previousPipelines);
  }

  if (subscriptionTier === SubscriptionTiers.StartupFree) {
    return (
      <div className="mt-6 w-full rounded-md bg-white p-8 shadow">
        <p className="font-medium">Manage your activity with this investor</p>
        <ul className="mt-4 list-inside list-disc">
          <li>Define information about your relationship with investor</li>
          <li>Track your one-pager and deck view for this investor</li>
        </ul>
        <p className="my-6">Try Flowlie Pro for free for 14 days</p>
        <div className="w-max">
          <SubscriptionCTAPill
            id="cta_investor_detail_activity_tab"
            text="View Investor Activity"
          />
        </div>
      </div>
    );
  }

  return (
    <>
      {isEmpty ? (
        <EmptyState
          title="There is no activity information for this investor"
          subTitle="Update the relationship or add the investor to an active pipeline to manage it here."
          icon={
            <AdjustmentsVerticalIcon className="mx-auto h-12 w-12 text-gray-400" />
          }
          actionButton={
            <button
              type="button"
              className="app-button--primary"
              onClick={openUpdateStartupRelationshipModal}
            >
              <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              Update relationship
            </button>
          }
        />
      ) : (
        <div className="mx-auto mt-8 grid max-w-full grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-3">
          <main className="space-y-6 lg:col-span-2 lg:col-start-1">
            <InvestorDetailRelationshipCard
              relationship={aggregateInvestorActivity.relationship}
              updateRelationship={openUpdateStartupRelationshipModal}
            />
            <InvestorDetailRoundEngagementCard
              pipeline={currentPipelineInvestor}
              updatePipeline={openUpdatePipelineModal}
              estimatedRoundCloseDate={
                aggregateInvestorActivity.currentRound?.estimatedCloseDate
              }
            />
            <PreviousRoundEngagementCards
              previous={aggregateInvestorActivity.previousPipelines}
              investorId={investorId}
            />
          </main>
          {!!pipelineHistory.length &&
            !!pipelineHistory.some(
              (pipelineItemHistory) =>
                pipelineItemHistory?.stageStatusChanges?.length,
            ) && (
              <aside
                aria-labelledby="timeline-title"
                className="flex flex-col gap-2 lg:col-span-1 lg:col-start-3"
              >
                <InvestorPipelineActivityTimeline
                  pipelineHistory={pipelineHistory}
                />
              </aside>
            )}
        </div>
      )}

      <ModalWrapper open={modalConfig.isOpen} onClose={() => closeModal()}>
        {modalConfig.type === 'updateStartupRelationship' && (
          <UpdateStartupRelationshipDialog
            connectionName={investorName}
            connectionDataType={investorDataType}
            relationship={aggregateInvestorActivity.relationship}
            onCancel={closeModal}
            onSave={(update: Partial<StartupRelationship>) => {
              return updateStartupRelationship(
                investorId,
                investorDataType,
                update,
              );
            }}
          />
        )}

        {modalConfig.type === 'updateInvestorStatus' &&
          aggregateInvestorActivity.currentRound &&
          currentPipelineInvestor && (
            <UpdateInvestorStatusDialog
              investor={currentPipelineInvestor}
              currentRound={aggregateInvestorActivity.currentRound}
              onCancel={closeModal}
              onSave={(update: InvestorPipelineStatusUpdate) => {
                updateInvestorStatus(investorId, investorDataType, update);
              }}
              boardStages={currentBoardStageViews}
            />
          )}
      </ModalWrapper>
    </>
  );
}

export default InvestorActivityTab;

interface PreviousRoundEngagementCardsProps {
  previous: InvestorActivityTabView['previousPipelines'];
  investorId: string;
}
function PreviousRoundEngagementCards({
  previous,
  investorId,
}: PreviousRoundEngagementCardsProps) {
  if (!previous || !previous.length) {
    return <></>;
  }

  return (
    <>
      {previous.map((pipeline) => {
        const currentPipelineInvestor = pipeline?.investors.find(
          (investor) => investor._id === investorId,
        );
        return (
          <InvestorDetailRoundEngagementCard
            key={pipeline._id}
            pipeline={currentPipelineInvestor}
            title={`${pipeline.round.roundDisplayName} Round`}
            estimatedRoundCloseDate={pipeline.round.estimatedCloseDate}
          />
        );
      })}
    </>
  );
}
